.title-modal{
    text-align: center;
    font-family: harmony-sans-regular;
    font-size: 20px;
    color: #7e8893;
    line-height: 25px; 
}
.title-modal-type2 {
    font-family: "harmony-sans-bold";
    color: #000;
    font-size: 16px;
}
.content-coa
{
    padding-left: 34px;
    padding-right: 20px;
}
.content-coa-view
{
 
    padding-left: 34px;
    padding-right: 34px;
}

.note-content
{
    padding-left: 11px;
    padding-right: 34px;
}
.required
{
    color: red;
}

.helper-text
{
  font-size: 12px;
  text-align: left;
  color: #7e8893;
  margin-top: 8px;
  font-family: harmony-sans-regular;
  font-weight: normal;
 }

.helper-text-modal
{
  height: 12px;
  font-size: 12px;
  text-align: left;
  color: #7e8893;
  //margin-bottom: 10px;
  margin-top: 8px;
  font-family: harmony-sans-regular;
  font-weight: normal;
 }

.font--weight--bold{
    font-weight: bold;
}
.helper-text-modal-delete
{
  height: 12px;
  font-size: 12px;
  text-align: left;
  color: red;
  //margin-bottom: 10px;
  margin-top: 8px;
  font-family: harmony-sans-regular;
  font-weight: normal;
 }
 .helper-text-modal-toggle
{
  height: 12px;
  font-size: 12px;
  text-align: left;
  color: #7e8893;
  margin-top: 3px;
  margin-left: 16px;
  font-family: harmony-sans-regular;
 }
 .helper-text-modal-status
{
  height: 12px;
  font-size: 12px;
  text-align: left;
  color: #7e8893;
  margin-bottom: 16px;
  font-family: harmony-sans-regular;
 }
.label-field 
{
  width: 206px;
  height: 16px;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #121212;
  margin-bottom: 15px;
  font-family: 'harmony-sans-bold';
}
.label-field2
{
  width: 206px;
  height: 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #121212;
  margin-bottom: 8px;
  margin-left: 16px;
  font-family: harmony-sans-regular;
}
.label-field3 
{
  width: 206px;
  height: 16px;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #121212;
  margin-bottom: 20px;
  font-family: 'harmony-sans-bold';
}
.label-field4 
{ 
  height: 16px;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #121212;
  margin-bottom: 20px;
  font-family: 'harmony-sans-bold';
}
.label-field5 
{
  height: 16px;
  font-size: 16px;
  font-weight: normal;
  color: #121212;
  margin-bottom: 20px;
  font-family: 'harmony-sans-bold';
}
.margin--bottom--0
{
  margin-bottom: 0px;
}
.isi-field{
  width: 460px;
  font-size: 16px;
  text-align: left;
  color: #121212;
  margin-bottom: 8px;
  font-family: harmony-sans-regular;
  word-wrap: break-word;
  font-weight: normal;
}
.isi-field-contact{
  width: 250px;
  font-size: 16px;
  text-align: left;
  color: #121212;
  margin-bottom: 8px;
  font-family: harmony-sans-regular;
  word-wrap: break-word;
  font-weight: normal;
}
.isi-field-asset{
    font-size: 16px;
    text-align: left;
    color: #121212;
    margin-bottom: 8px;
    font-family: harmony-sans-regular;
    word-wrap: break-word;
    font-weight: normal;
}

.value-field-asset{
    font-size: 16px;
    text-align: left;
    color: #121212;
    margin-bottom: 8px;
    font-family: harmony-sans-regular;
    word-wrap: break-word;
    font-weight: normal;
    min-height: 21px;
}
.row-field{
    padding-bottom: 20px;
}
.row-field-asset{
    margin-left: -15px;
    margin-right: -15px;
}
.row-field-profile{
    padding-top: 40px;
    padding-bottom: 20px;
}
.row-field-profile-button{
    padding-top: 51px;
    padding-bottom: 51px;
}
.row-picture{
    margin-top: 30px;
}
.row-field-view
{
    padding-bottom: 21px;
}
.row-footer{
    padding-bottom: 50px;
}
.row-button{
    padding-bottom: 32px;
}
.row-button-profile
{
    padding-top: 32px;
    padding-bottom: 32px;
}
.model-status > div{
    float: left;
}
.view > .ant-modal-content >.ant-modal-body
{padding: 0 !important;}
.model-status > .helper-text-modal
{
    padding-left: 13px;
}
.flooter-custome
{
    float:right;
    display: -webkit-inline-box;
    
}
.flooter-custome > button
{
    width: 93px;
}
.flooter-custome-profile
{
    float:right;
}
.flooter-custome-profile > button
{
    width: 180px;
}

.width-button
{
    width: 150px;
    margin-left: 10px;
    float: left;
}
.txt-underline{
text-decoration: underline;
}
.flooter-info
{
    //display: list-item;
    padding-top: 24px;
    height: 73px;
    padding-bottom: 10px;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1.3px;
    padding-left: 32px;
    padding-right: 17px;
}
.flooter-info--blue
{
background-color: #419aff;
}
.flooter-info--gray
{
    
  background-color: #b8c1cc;
}
.delete-account
{
  font-size: 14px;
  line-height: 0.86;
  color: #ff414a;
  font-family: harmony-sans-regular;
  margin-top: 42px;
  margin-bottom: 15px;
  cursor: pointer;
}

.col-edit-modal{
    font-size: 14px;
    line-height: 0.86;
    text-align: center;
    color: #419aff;
    margin-bottom: 22px;
    text-align: right;
    padding-right: 18px;
    padding-top: 27px;
    font-family: harmony-sans-regular;
}

.col-edit-modal2{
    font-size: 14px;
    line-height: 0.86;
    color: #419aff;
    margin-bottom: 22px;
    text-align: right;
    padding-top: 27px;
    font-family: harmony-sans-regular;
}

.col-edit-modal3{
    font-size: 14px;
    line-height: 0.86;
    text-align: center;
    color: #419aff;
    text-align: right;
    padding-right: 18px;
    padding-top: 27px;
    font-family: harmony-sans-regular; 
}

.dis-link
{
    cursor: not-allowed;
    pointer-events: none;
    color: #b8c1cc;
}
.dis-animation{
    transition: none !important;
    animation: none !important;
}

.dis-animation2{
    transition: none !important;
    animation: none !important;
}
.title-errorpopup{
  height: 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #121212;
  padding-left: 20px;
}
.modal-delet-coa
{
    margin-top: 15% !important;
}
.content-error{
    padding-left: 70px;
    padding-top: 20px;
}
.icon-error-popup
{
    width: 18px;
    height: 18px;
}
.div-error-popup
{
    padding-left: 30px;
    padding-top: 5px;
}
.flooter-button
{
    float: right;
}
.flooter-link
{
    float: left;
    padding-top: 10px;
}
.content-flooter
{
    
    padding-left: 70px;
    padding-top: 20px;
    padding-bottom: 35px;
}
.dis-delete
{
    font-size: 14px;
    // line-height: 0.86;
    text-align: left;
    color: #b8c1cc;
    cursor: pointer;
}
.ant-select-selection--single
{
    height: 32px !important;
}
textarea
{
    border: none;
    width: 450px;
}
.h--32
{
    height:  32px;
}
.h--30
{
    height:  32px !important;
}
.ant-alert-warning
{
    border: none !important;
    background-color: #ffe8e8 !important;
}

.ant-alert-error {
    border: none !important;
    // background-color: #ffe8e8 !important;
}

.ant-alert-success {
    border: none !important;
    // background-color: #ffe8e8 !important;
}

.ant-alert-info {
    border: none !important;
    // background-color: #ffe8e8 !important;
}

.saas-info-payment > .ant-alert-success > .ant-alert-icon {
    color: #50e3c2 !important;
}

.error-customer-bank > .ant-alert-warning > .ant-alert-icon {
    color: #ffe98c !important;
}

.error-customer-bank > .ant-alert-warning {
    border: none !important;
    background-color: #fffde8 !important;
}

.ant-alert-icon
{
    color: #de311b !important;
    // color: #de311b;
}

.ant-alert-info {
    border: none !important;
}

.ant-alert-info > .ant-alert-icon {
    color: #1890ff !important;
}

.anticon-exclamation-circle::before {
    background-image: url('/assets/images/error_ico_24.svg');
    background-size: 16px 16px;
    display: inline-block;
    width: 16px; 
    height: 16px;
    content:"";
    margin-top: -2px
}

.anticon-info-circle::before {
    background-image: url('/assets/images/info_ico_24.svg');
    background-size: 16px 16px;
    display: inline-block;
    width: 16px; 
    height: 16px;
    content:"";
    margin-top: -2px
}

.ant-modal-title
{
    line-height: 27px;
}
.message-box{
    margin-bottom: 30px;
}

.font--size--14{
    font-size: 14px !important;
}
.font--size--16{
    font-size: 16px !important;
}

.settings-desc{
    font-size: 14px !important;
    max-width: 600px;
}

.font--16{
    font-size: 16px !important;
}
.field-dispose-sales-account
{
    width: 180px;
}
.ant-modal-close{
    right: 4px !important;
    top: 5px !important;
}

.datepickerant-disabled {
    background-color: #e6ecf2 !important;
    color: #7e8893 !important;
}

.datepickerant-disabled:hover {
    border-color: #e6ecf2 !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1) !important;
    border: solid 0.5px #b8c1cc !important;
}

// css footer custom (footer background-color catskill white)

.modal-footer-custom {
    margin-right: -16px;
    margin-left: -16px;
    margin-bottom: -16px;
    min-height: 64px;
    padding: 16px;
    background-color: #f5f7fa;
}

.modal-footer-custom > .button--save {
    width: 100px;
    float: right;
}

.modal-footer-custom > .button-cancel-border {
    width: 100px;
    float: right; 
    margin-right: 8px;
}

.radio-btn-calculate{
    .oneradio--row{
        line-height: 1.2 !important;
        margin-bottom:0px !important;
        height:40px !important;
    }
}

// end of css footer custom