@import '../base/variables.scss';
.side-warpper::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  border-radius: 5px;
  padding-top: 10px;
}

body {
  scrollbar-base-color: #419AFF;
  scrollbar-base-color: #419AFF;
  scrollbar-3dlight-color: #419AFF;
  scrollbar-highlight-color: #419AFF;
  scrollbar-track-color: #419AFF;
  scrollbar-arrow-color: #419AFF;
  scrollbar-shadow-color: #419AFF;
  scrollbar-dark-shadow-color: #419AFF;
}

@-moz-document url-prefix() {
scrollbar {
  -moz-appearance: none !important;
  background: rgb(0, 255, 0) !important;
}

thumb, scrollbarbutton {
  -moz-appearance: none !important;
  background-color: rgb(0, 0, 255) !important;
}

thumb:hover, scrollbarbutton:hover {
  -moz-appearance: none !important;
  background-color: rgb(255, 0, 0) !important;
}

scrollbarbutton {
  display: none !important;
}

scrollbar[orient="vertical"] {
  min-width: 15px !important;
}


}
.side-warpper::-moz-scrollbar-track {
  background-color: #192B46;
  border-radius: 5px;
  background-clip: content-box;
}
scrollbar-track {
  background-color: #192B46;
  border-radius: 5px;
  background-clip: content-box;
}
.side-warpper::-moz-scrollbar-thumb {
  background-color: #419AFF;
  border-radius: 5px;
  height: 40px;
  margin-top: 30px;
}
.side-warpper::-webkit-scrollbar-track {
  background-color: #192B46;
  border-radius: 5px;
  background-clip: content-box;
}
.side-warpper::-webkit-scrollbar-thumb {
  background-color: #419AFF;
  border-radius: 5px;
  height: 40px;
  margin-top: 30px;
}
scrollbar-thumb {
  background-color: #419AFF;
  border-radius: 5px;
  height: 40px;
  margin-top: 30px;
}
.line-border {
  width: 195px;
  height: 0px;
  border: solid 1px #2e4059;
  opacity: 0.5;
  margin-top: 8px;
  margin-bottom: 0;
}
.bm-menu-wrap {
  width: 200px!important;
}
.wrapper {
  // the wrapper acts as the submenus' positioned parent
  position: fixed;
  width: 200px;
  display: block;
  max-height: 100vh;
  height: 100%;
  min-height: 100vh;
 // overflow: hidden; //background-color: #192b46;
  color: #fff;
  font-family: harmony-sans-regular;
  font-size: 14px;
  z-index: 3;
}
.left-animation {
  -webkit-animation-name: left-animate;
  /* Safari 4.0 - 8.0 */

  -webkit-animation-duration: 4s;
  /* Safari 4.0 - 8.0 */

  animation-name: left-animate;
  animation-duration: 1s;
  animation-delay: 1s;
  overflow: hidden;
  animation-fill-mode: forwards;
}
.right-animation {
  -webkit-animation-name: right-animate;
  /* Safari 4.0 - 8.0 */

  -webkit-animation-duration: 4s;
  /* Safari 4.0 - 8.0 */

  animation-name: right-animate;
  animation-duration: 1s;
  overflow: hidden;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
.hidden-submenu {
  // visibility: hidden;
  left: -240px;
}
@-webkit-keyframes right-animate {
  0% {
    left: -240px;
    top: 0px;
  }

  25% {
    left: -180px;
    top: 0px;
  }

  50% {
    left: -120px;
    top: 0px;
  }

  75% {
    left: -60px;
    top: 0px;
  }

  100% {
    left: 0px;
    top: 0px;
  }
}
.left-animation::after > .simple-submenu {
  display: inline-block;
}
@-webkit-keyframes left-animate {
  0% {
    left: 0px;
    top: 0px;
  }

  25% {
    left: -60px;
    top: 0px;
  }

  50% {
    left: -120px;
    top: 0px;
  }

  75% {
    left: -180px;
    top: 0px;
  }

  100% {
    left: -240px;
    top: 0px;
  }
}
.simple-menu-company {
  width: 44px;
  height: 52px;
  background-color: #1f324c;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.23);
  padding: 1px 1px 10px 10px;
}
.simple-menu {
  padding: 14px 10px 14px 10px;
  display: list-item;
  position: static;
  overflow: hidden;
}
.simple-submenu {
  position: absolute;
  display: none;
}
.display-none {
  display: inline-block !important;
  top: 0px;
  background-color: #192b46;
  width: 222px;
}
.icon-quickfind-menu {
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: 8px;
  margin-left: 10px;
  margin-bottom: 5px;
  margin-right: 15px
}
.simple-wrapper {
  // display: none;
  width: 44px;
  height: 100%;
  background-color: #192b46;
  position: fixed;
  z-index: 3;
}
.company-warpper {
  width: 200px;
  height: 52px;
  background-color: #2d3b57;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.23);
  position: relative;
  color: #fff;
  &:hover {
    background-color: #435476;
  }
}
.showcompany-warpper {
  background-color: #192b46;
}
.company-warpper > div {
  display: inline;
}
.title-menu {
  // font-weight: 400;
  font-size: 12px;
  color: #b8c1cc;
  padding-left: 10px;
  padding-top: 30px;
  padding-bottom: 15px;
}
.quickfind-warpper {
  position: relative;
  padding: 18px 10px 10px 10px;
}
.quickfind-field {
  color: #242b33;
  width: 173px;
  // padding: 12px 14px 11px 45px;
  border-radius: 3px;
  height: 35px;
  padding-left: 40px !important;
  font-size: 12px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.19);
  border: solid 0.6px #b8c1cc;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #b8c1cc;
    font-size: 12px;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #b8c1cc;
    font-size: 12px;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #b8c1cc;
    font-size: 12px;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #b8c1cc;
    font-size: 12px;
  }
}
.quickfind-content:hover {
  display: inline-block;
  visibility: visible;
}
.quickfind-field:focus {
  border-color: #419aff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6);
}
.quickfind-field:focus + .quickfind-content {
  visibility: visible;
}
.quickfind-field:focus + .quickfind-content2 {
  visibility: visible;
}
.quickfind-content2 {
  width: 213px;
  visibility: hidden;
  height: 128px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  margin-top: 6px;
  left: 10px; // overflow-y: auto;
  top: 55px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  div:first-child {
    border-radius: 5px;
  }

  div:last-child {
    border-radius: 5px;
  }
}
.quickfind-content {
  width: 183px;
  visibility: hidden;
  height: 128px;
  position: absolute;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  margin-top: 6px;
  left: 10px; // overflow-y: auto;
  top: 55px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  div:first-child {
    border-radius: 5px;
  }

  div:last-child {
    border-radius: 5px;
  }
}
.quickfind-content-field {
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: black;
  padding: 8px 10px 4px 10px;
  &:hover {
    background-color: #E6ECF2;
    color: black;
    cursor: pointer;
  }
}
.quickfind-content-field-empty {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  text-align: center;
  display: -moz-box;
  padding: 40px 41px 67px 41px;
}
.side-menu-icon {
  padding: 0;
  margin: 0;
}
.company-warpper > label {
  padding-top: 16px;
  padding-left: 21px;
  padding-right: 25px;
  font-size: 14px;
  width: 158px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 12px;
  font-weight: normal;
  cursor: pointer;
}
.icon-dropdown {
  width: 18px;
  height: 18px;
  margin-top: 16px;
  position: absolute;
}
.icon-mini-menu {
  width: 24px;
  height: 24px;
  position: absolute;
}
.btm-menu {
  padding-bottom: 200px !important;
}
.side-warpper {
  width: 200px;
  max-height: 100vh;
  height: 100%;
  min-height: 100vh; // overflow-x: hidden;
  padding-bottom: 90px;
  background-color: #192b46; // overflow-y: hidden;//auto;
}
.quickfind {
  overflow: hidden;
  position: relative;
  height: 128px;
  background-color: #ffffff;
  z-index: 10;
}
.display-none-hidden
{
  display: inline-block !important;
}
.-reactjs-scrollbar-area > .-reactjs-scrollbar-track:vertical {
 width: 10px;
 width: 100%;
  top: 0;
  right: 0;
}
.track-vertical
{
  position: absolute;
  width: 6px;
  transition: opacity 200ms;
  opacity: 0;
  right: 0;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  
}
.scroll-content{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;
  margin-right: -20px;
  margin-bottom: -20px;
}
.react-scrollbar-default {
  //position: static !important;
  background-color: #192b46; //color: #fff;
  height: 100vh;
  font-family: harmony-sans-regular;
  font-size: 16px;
  overflow: hidden;
  overflow-y: auto;
}
.react-scrollbar-default:visited + .-reactjs-scrollbar-track {
  display: block
}

.react-scrollbar-default:hover + .-reactjs-scrollbar-track {
  display: block
}
.-reactjs-scrollbar-track {
 // display: none;
}
.scrollbar-custome {
  background-color: #192B46; //z-index: 999999999;
  width: 7px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
}
.quickfind-scrollbar {
  position: static !important;
  background-color: #192b46;
  color: #fff;
  height: 50px;
  font-family: harmony-sans-semibold;
  font-size: 14px;
}
.react-scrollbar-default .-reactjs-scrollbar-track:vertical {
  width: 6px!important;
}
.-reactjs-scrollbar-thumb {
  background-color: #419AFF !important;
  width: 6px !important;
  border-radius: 5px;
}
.scrollbar-custome-thumb {
  background-color: #419AFF;
  height: 100px;
  top: 0px; //z-index: 10000000000000000000;
  width: 6px;
  padding: 0;
  border-radius: 5px;
  margin: 0;
}

.scrollbar-custome-notif {
  height: 100px;
  top: 0px; //z-index: 10000000000000000000;
  width: 6px;
  padding: 0;
  border-radius: 5px;
  margin: 0;
}

.top-bar-full {
  padding-left: 44px !important;
}
.content {
  position: fixed;
  top:52px;
  width: 100%;
  background-color: #192b46;
  height: 100vh;
  font-family: harmony-sans-regular;
  font-size: 16px;
}
.content-full-form--spacing {
  height: 75px
}
.content-full {
  padding-left: 44px !important;
}
.submenu > ul, .side-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.active {
  background-color: #419aff !important;
}
.active > .lbl-menu > img
{

  margin-right: 16px;
}
.submenu > ul > li {
  background-color: #192b46;
  margin: 0;
}

/*.simple-menu:hover > .submenu-mini {
  display: inline-block;
}*/
.side-menu > li {
  position: static;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; //border-radius: 3px;
  .submenu {
    //position: absolute; masih belom tau kenapa tiba2 kaga bisa
    position: fixed;
    z-index: 10;
    display: none;
    box-shadow: 0 2px 2px 0 rgba(7, 24, 50, 0.54);
    width: 180px;
  }

  &::before {
    //background-color: #0e203c;
  }

  &:hover {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin: 0px;
    opacity: 0, 7;
  }

  &::after {
    opacity: 1;
  }

  &:hover > .submenu {
   // display: block; //border-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0; //background-color: #0e203c;
  }
}
.side-menu > a > li {
  position: static;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; //border-radius: 3px;
  .submenu {
    //position: absolute; masih belom tau kenapa tiba2 kaga bisa
    position: fixed;
    z-index: 10;
    display: none;
    box-shadow: 0 2px 2px 0 rgba(7, 24, 50, 0.54);
    width: 180px;
  }

  &::before {
    //background-color: #0e203c;
  }

  &:hover {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin: 0px;
    opacity: 0, 7;
  }

  &::after {
    opacity: 1;
  }

  &:hover > .submenu {
    display: block; //border-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0; //background-color: #0e203c;
  }
}
.hover-li:hover {
  background-color: #0e203c;
}
.side-menu > li {
  font-size: 14px;
  padding: 5px 8px 5px 13px;
  background-color: #192b46;
  position: relative;
  ul {
    margin: 0;
  }
  ul > a > li{
    padding-bottom: 4px;
  }
  .parent {
    margin: 0 !important;
    
  }

  .submenu {
    cursor: auto;
    background-color: #192b46;
    /*   li {
      padding: 0.5em;
    }*/
    li:first-child {
      //border-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    li:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &.parent {
    cursor: pointer;
    width: 220px
    //margin-right: 20px;
  }
}
.side-menu > a > li {
  font-size: 14px;
  padding: 5px 8px 5px 13px;
  background-color: #192b46;
  ul {
    margin: 0;
  }

  .parent {
    margin: 0 !important;
  }

  .submenu {
    cursor: auto;
    background-color: #192b46;
    /*   li {
      padding: 0.5em;
    }*/
    li:first-child {
      //border-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    li:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &.parent {
    cursor: pointer;
   // margin-right: 20px;
  }
}
.icon-menu-span {
  float: right;
  position: absolute;
  right: 28px;
}
.icon-forward {
  width: 10px;
  height: 10px;
}
.lbl-menu {
  color: #fff;
  font-weight: normal;
  cursor: pointer;
  font-family: harmony-sans-regular;
  padding-right: 20px;
}
.icon-menu {
  margin-right: 20px;
  height: 18px;
  width: 18px;
}
.icon-title-ext {
  margin-right: 20px;
  height: 19px;
  width: 68px;
}
.icon-menu-myharmony{
  padding-right: 20px;
  width: 36px;
  height: 22px;
}
.bm-burger-button > button {
  display: none;
}
.chose-company-sidebar {
  width: 200px;
  height: auto;
  border-radius: 3px;
  background-color: #2a3650;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 10;
  display: none;
  top: 53px;
}
.list-company-sidebar {
  padding: 0px;
  margin: 0px;
}
.bm-cross-button > button {
  display: none;
}
.list-company-sidebar > li {
  display: inline-block;
  margin-left: 15px;
  width: 100%;
  height: 50.7px;
  color: #fff;
}
.profile-circle {
  border-radius: 40px;
  width: 30px;
  height: 30px;
  background-color: #526be6;
  text-align: center; //display: inline-block;  
  float: left;
}
.profile-circle-blue {
  background-color: #526be6;
}
.profile-circle-red {
  background-color: #ff7a7a;
}
.profile-circle-yellow {
  background-color: #ffdf7a;
}
.profile-circle-green {
  background-color: #7affab;
}
.profile-circle-purpel {
  background-color: #8d2aa5;
}
/* 
.lbl-company-circle {
  width: 13px;
  height: 18px;
  font-family: harmony-sans-semibold;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  padding: 5px 12px 7px 1px;
  margin-top: -3px;
}
 */
.lbl-company-circle {
  width: 30px;
  height: 30px;
  font-family: harmony-sans-semibold;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}
.companylist-sidebar{
  cursor: pointer;
}
.lbl-companyname-sidebar {
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
  max-width: 139px;
  float: left;
  margin-top: 5px;
}
.chose-company-sidebar-mini >h2 {
  margin: 0;
  padding-top: 2px;
  width: 100%;
  height: 12px;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #b8c1cc;
  margin-bottom: 30px;
  padding-top: 14px;
  padding-left: 22px;
}
.chose-company-sidebar >h2 {
  margin: 0;
  padding-top: 2px;
  width: 100%;
  height: 12px;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #b8c1cc;
  margin-bottom: 30px;
  padding-top: 14px;
  padding-left: 22px;
}
.bm-cross-button {
  display: none;
}
.submenu-mini {
  width: 182px;
  display: none;
  position: fixed;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #192b46;
  box-shadow: 0 2px 2px 0 rgba(7, 24, 50, 0.54);
}
.submenu-mini-bar {
  width: 182px;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
}
.side-menu > li:hover {
  background-color: #0e203c;
}
.side-menu > a > li:hover {
  background-color: #0e203c;
}
.submenu-mini-bar >a >li:hover {
  background-color: #0e203c;
}
.submenu-mini-bar >a >li {
  color: #fff;
  width: inherit;
  font-size: 14px;
  padding: 11px 11px 16px 16px;
  font-weight: normal;
}
.title-submenu {
  color: #b8c1cc!important;
  padding: 11px 11px 16px 16px;
}
.line-menu {
  width: 42px;
  height: 2px;
  border-color: #33445f;
}
.color-white {
  color: #fff !important;
}
.simple-menu-active {
  background-color: #419aff;
}

/**/
.simple-menu:hover {
  background-color: #0e203c;
}
.chose-company-sidebar-mini {
  width: 240px;
  height: auto;
  border-radius: 3px;
  background-color: #2a3650;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 10;
  top: 52px;
  display: none;
  left: 0;
}
.empty-menu {
  padding-top: 1px;
  padding-bottom: 1px;
  height: 25px;
  background-color: #192b46 !important;
  cursor: default !important;
}
.simple-menu-company:hover { 
  background-color: #435476;
}
.li-addnew-company{
  margin-left: 0 !important;
}
.div-add-company
{
  margin-top: -7px;
  border-top: solid 1px #192b46;
  font-size:14px;
} 
.lbl-add-company
{
  color: #ffffff;
  // text-decoration: underline;
}
.logo{
  margin-top: 10px !important;
  margin-bottom:  10px !important;
  margin-right: 10px !important;
}
.company{
  // padding: 12px 8px 11px 21px;
  padding-top: 12px;
  padding-right: 8px;
  padding-bottom: 11px;
  padding-left: 0px
}

.ml-10{
  margin-left: 10px;
}