.card-container p {
    margin: 0;
}

.card-container > .ant-tabs-vertical > .ant-tabs-left-content {
    padding-left: 0px !important;
}

.card-container > .ant-tabs-vertical > .ant-tabs-left-bar {
    padding: 24px 0px 24px 0px;
}