.div-1 {
    padding: 11px 18px 27px 18px;
    > div {
        margin-right: 44px
    }
}

.div-2 {
    padding: 22px 18px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    div .adj-inline-block {
        margin-right: 15px;
        font-weight: bold;
        input {
            width: 150px
        }
    }
    div .last {
        margin-right: 0px !important
    }
    label {
        margin-bottom: 35px
    }
}

.div-3 {
    padding: 27px 18px 31px 18px;
    .spacing {
        height: 30px
    }
}
 
.div{
    padding-top: 22px;
    border-top: 1px solid #ddd;
}

.div-modal-asset{
    padding-top: 20px;
    padding-left: 20px;
}

.label--group{
    font-size: 18px;
    color: #000;
    padding-left: 0px;
    padding-bottom: 2px;
}
.adj-label {
    font-weight: bold;
    font-size: 16px;
    color: #000
}

.adj-inline-block {
    display: inline-block
}

.adj-icon-button {
    margin-left: 10px;
    width: 15px;
    height: 15px;
}

.value-adjustment {
    color: #7e8893;
    font-size: 14px;
    font-weight: bold;
    margin-top: 50px
}

.adj-text-area {
    width: 400px;
    font-weight: normal
}

.adj-select-account {
    width: 250px
}
.adj-import-account {
    width: 187px
}

.adj-btn-continue {
    margin: 3px;
    width: 200px;
    float: right
}

.adj-type {
    width:189px
}

.adj-date{
    width: 163px
}

.review-spacing{
    width: 100%;
    height: 58px
}

.review-remarks{
    width: 394px
}

.btn-post-adjustment {
    width: 214px;
    float: right;
    margin-right: -16px;
}

.btn-back {
    width: 75px;
    padding: 0 !important;
    margin-left: -10px;
    margin-top: 10px
}
.btn-back-ownership {
    width: 75px;
    padding: 0 !important;
    margin-left: -10px;
    margin-right:5px;
    margin-top: 11px
}

.review-body{
    padding: 12px 18px 0px 18px;
    font-size: 14px;
    color: #000;
    label{
        font-size: 16px;
        padding: 5px 0px
    }
    th{
        font-size: 16px;
        padding: 8px 0px 8px 0px !important
    }
    td{
        padding: 8px 0px 8px 0px !important;
    }
    td.review-number{
            text-align: right;
            font-family: "harmony-mono-regular" !important
        }
}