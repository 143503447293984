.form-email-setup{
    padding: 30px 20px !important;
}

.icon-default{
    width: 14px;
    height: 16px;
    margin-top: 7px;
}

.radio-position{
    margin-bottom: 7px !important;
    margin-left: 9px !important;
    padding-left: 0px !important;
}

.div-icons {
    width: 26px;
    height: 24px;
    margin-bottom: 7px;
    margin-left: 10px;
}

.form-parent-email{
    min-width: 1024px !important;
    max-width: 1024px !important;
}

.label-email-setup{
    color: #7e8893 !important;
    float: left;
    font-size: 12px;
    font-family: "Harmony-sans-regular";
    margin-left: 2px;
}

.content-modal-email{
    padding: 10px 20px 20px 20px;
}

.footer-modal-email{
    height: 35px;
}

.label-modal-email{
    font-size: 14px;
    font-family: "Harmony-sans-bold";
    color: #000;
}

.label-modal-email2{
    font-size: 16px;
    font-family: "Harmony-sans-bold";
    color: #000;
}

.icon-check-email{
  width: 46px;
  height: 46px;
}

.title-modal-confirm{
    font-size: 16px;
    font-family: "Harmony-sans-bold";
    color: #121212;
}

.content-text-email{
    font-size: 14px;
    margin-left: 11px; 
    margin-bottom: 40px;
    font-family: "Harmony-sans-regular";
}

//buat component baru
.ant-message-notice-content2{
    padding: 10px 16px;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 12px rgba(0,0,0,.15);
    box-shadow: 0 4px 12px rgba(0,0,0,.15);
    background: #e0ffea;
    color: #4ddb7c;
    border: 1px solid #4ddb7c;
    display: inline-block;
    pointer-events: all;
}


.icon-tooltip-email{
    width: 12px;
    height: 12px;
    margin-top:9px;
    margin-left:5px;
}

.content-tooltip{
    float: right;
    width: 238px !important;
}

.icon-email-default{
    width: 18px;
    height: 18px;
    margin-top: 7px;
    margin-left: -2px;
}

.textarea-modal-email{
    height: 300px !important;
    width: 100% !important;
}

.textarea-send-email{
    height: 210px !important;
    width: 100% !important;
}

.harmony-font-14{
    font-size: 14px;
    font-family: "Harmony-sans-regular";
    color: #000;
}

.email-add-placeholder{
    width: 220px;
    font-size: 14px;
    font-family: "Harmony-sans-regular";
    padding: 0px 2px 0px 30px;
}

.icon-flash{
    background-image: url('/assets/images/flash-icon.svg');
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
}

.email-tooltip-text{
    font-size: 12px;
    font-family: "Harmony-sans-regular";
}

.email-icon-button{
    margin-top: -3px;
    margin-left: -13px;
}

.email-title-delete{
    float: left;
    width: 90%;
}

.confirm-send-email {
    float: left;
    width: 80%;
    margin-top: 4px;
}

.content-send-email {
    padding: 0px 20px 0px 32px;
    font-size: 14px;
    color: #7e8893;
}

.header-misc{
    height:50px;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.header-misc-logo{
    width: 110px;
    margin: 0px auto;
    padding-top: 12px;
    font-family: "Harmony-sans-bold";
}

.content-misc {
    width: 360px;
    height: auto;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    position: relative;
    padding: 50px 140px 30px 140px;
    top: 50px;
}

.content-title {
    font-size: 20px;
    font-family: "Harmony-sans-bold";
    color: #000;
}

.content-subtitle{
    font-size: 16px;
    font-family: "Harmony-sans-regular";
}

.confirmation-hr{
    width: 250px;
    border-color: #e7ecf3;
    text-align: center;
    margin: 30px auto;
}

.confirmation-button{
    width: 140px;
    margin-top: 5px;
    margin-bottom: 20px;
}

#Email-setup-table > tbody > tr {
    border-bottom: 1px solid #ddd;
}

.header-logo-text{
    color: #000;
    font-family: "Harmony-sans-bold"
}

.header-logo-text:hover{
    color: #000;
}

.ant-menu-item-selected {
    color: none;
}