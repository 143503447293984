@import '../base/variables.scss';

.button {
    border: none;
    border-radius: 3px;
    font: 400 20px $font-primary;
    color: $white;
    padding: 12px 16px;

    &:hover {
        color: $white;
    }
}

.flat-button {
    border: none;
    border-radius: 0;
    color: $white;
    font: 400 14px $font-primary;
    text-align: center;
    padding: 16px 24px;

    &:hover {
        color: $white;
    }
}

.mini-button {
    border: none;
    border-radius: 5px;
    color: $white;
    font: 400 16px $font-primary;
    text-align: center;
    padding: 8px 12px;

    &:hover {
        color: $white;
    }
}

.button--create {
    background-color: $colorBlue;
    min-width: 100px;

    &:hover {
        background-color: lighten($colorBlue, 10%);
    }

    &:disabled {
        background-color: $lightGrey;
        color: $darkerWhite;
    }
}

.button--save {
    background-color: $colorGreen;
    min-width: 92px;

    &:hover {
        background-color: lighten($colorGreen, 10%);
    }
}

.button-save {
    background-color: $colorGreen !important;
    min-width: 92px;

    &:hover {
        background-color: lighten($colorGreen, 10%);
    }
}

.button--save-xl {
    min-width: 200px;
}

.button--neutral {
    background-color: $lightGrey;
    width: 92px;

    &:hover {
        background-color: lighten($lightGrey, 10%);
    }
}

.button--row-delete {
    background-color: transparent !important;
    padding: 0px !important;
}

.button--delete {
    background-color: $colorRed !important;
    //box-shadow: inset 0 -1px 0 0 #3D80CC;
    width: 90px;

    &:hover {
        background-color: lighten($colorRed, 10%);
    }
}

.button--cancel {
    background-color: $blueDarken;
    box-shadow: inset 0 -1px 0 0 #3D80CC;
    width: 95px;

    &:hover {
        background-color: lighten($blueDarken, 10%);
    }
}

.button--general {
    display: block;
    text-align: center;
    background-color: $darkWhite;
    border-radius: 5px;
    padding: 16px 0;
    font: 500 20px $font-primary;
    color: $darkGrey;

    &:hover {
        color: $darkGrey;
        background-color: lighten($darkWhite, 5%);
    }
}
.button-update-asset
{
    height: 35px;
    font-size: 20px;
    padding: 0px;
}
.button-mg-right-30
{
    margin-right: 30px;
}
.button-creditnote
{
    width: 150px !important;
}
.btn-registerd
{
    width: 100px !important;
}
.button--cancel-message{
 //   background: url("../../../assets/images/close_btn.png");
    position: absolute;
    right: 25px;
    top: -20px;
    height: 50px;
    width: 50px;
}
.menu--item-img-diplay{
    width: inherit;
    padding: 0px;
    margin: 0px;
    top: 0px;
}
.modal-delet-coa > .ant-modal-content > .ant-modal-body > .ant-confirm-body-wrapper > .ant-confirm-btns > .ant-btn-primary
{

    background-color: #ff414a;
    border-color: #ff414a;
    --box-shadow-color: #ff8b90;
}
.modal-delet-coa > .ant-modal-content > .ant-modal-body > .ant-confirm-body-wrapper > .ant-confirm-btns > .ant-btn-lg:first-child
{
    border: none;
    color: #242b33;
}
.modal-delet-coa > .ant-modal-body
{
    padding: 30px 30px;
}
