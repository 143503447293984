.repcmp-report-header {
    text-align: center;
    width:100%;
    margin: 44px 0px

}

.repcmp-report-name {
    font-size: 18px
}

.repcmp-company-name {
    font-size: 20px;
    font-family: "harmony-sans-bold" !important;
}

.repcmp-remarks-date {
    font-size: 14px;
}