.ownshp-table{
    td {
        padding: 8px 30px 8px 30px !important;
    }
    th {
        padding: 8px 30px 8px 30px !important;
    }
    tr td:nth-child(4n){
        text-transform: capitalize
    }
}

.ownshp-table-container{
    border-bottom: 1px solid #ddd;
    background-color: #f5f8fa;
    padding:10px 0px;
}

.ownshp-label{
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 8px 30px 8px 30px;
}

.container-modal-company {
    margin-top: 15px;
}