.trans-number-subtitle {
    font-family: harmony-sans-bold;
    font-size: 18px;
    text-align: left;
}

.trans-number-tabs{
    width: 31% !important;
    max-width: 31% !important;
}

.trans-number-wd-100{
    width: 100%;
}

.coamapping-subtitle {
    font-family: harmony-sans-bold;
    font-size: 14px;
    text-align: left;
}