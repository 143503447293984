.table-inventory-detail {
    width: 100%;
    tbody tr td {
        width: 25%;
        padding: 10px;
        padding-top: 0px;
        font-size: 14px;
    }
    thead th {
        width: 25%;
        padding: 10px;
        font-size: 14px
    }
}

.table-inventory-detail-group {
    width: 100%;
    tbody tr td {
        width: 25%;
        padding: 10px;
        font-size: 14px;
    }
    thead th {
        width: 25%;
        padding: 10px;
        font-size: 14px
    }
}

.table-inventory-price {
    width: 100%;
    tbody tr td {
        width: 25%;
        padding: 10px;
        font-size: 14px;
        vertical-align: top
    }
    thead th {
        width: 25%;
        padding: 10px;
        font-size: 14px
    }
    .price_desc {
        width: 40%
    }
}

.spacing {
    height: 57px;
    width: 100%
}

.spacing-adj {
    height: 65px;
    width: 100%
}

.spacing-bottom {
    height: 111px;
    width: 100%;
}

.clear-both {
    clear: both
}

.inventory-form {
    width: 77%;
    float: left;
    padding: 20px  30px;
}

.inventory-form-view {
    width: 95%;
    margin:26px auto;
    padding: 20px  30px;
}

.div-stock-container {
    padding-right: 20px;
    span {
        color: #419aff;
        font-weight: bold;
        font-size: 20px;
    }
}

.div-parent-qty {
    min-width: 900px;
}

.div-parent-qty > div:nth-child(2) > .div-stock-top {
    margin-left:30px;
}

.div-stock-top {
    width:calc(50% - 20px);
    float: left;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top: solid 0.5px #ddd;
    border-right: solid 0.5px #ddd;
    border-left: solid 0.5px #ddd;
    background-color: white;
    padding: 13px 0px;
    text-align: center;
    margin: 26px 3px 30px 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.div-stock-top-group {
    width:calc(50% - 20px);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top: solid 0.5px #ddd;
    border-right: solid 0.5px #ddd;
    border-left: solid 0.5px #ddd;
    background-color: white;
    padding: 13px 0px;
    text-align: center;
    margin: 10px auto 30px auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.div-head {
    border-bottom: solid 0.5px #ddd;
}
.div-qty-total{
    padding: 30px 0px;
    font-size: 20px;
    font-family: harmony-mono-regular;
}

.div-stock-middle {
    border-right: solid 0.5px #ddd;
    border-left: solid 0.5px #ddd;
    border-top: solid 0.5px #ddd;
    background-color: white;
    padding: 28px;
    margin: 0px 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14)
}

.div-stock-bottom {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: solid 0.5px #ddd;
    background-color: white;
    padding: 28px;
    margin: 0px 3px 26px 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14)
}

.hr {
    margin-bottom: 28px;
    border-top-color: #d7dce3
}

.inv-label {
    padding-top: 20px;
    padding-bottom: 10px;   
}

.button-stock-adjustment {
    width:184px;
    padding: 8px 10px 8px 10px;
}

.icon-button {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    margin-top: -4px
}

.button-edit-inventory{
    width:100px;
    margin-left: 13px;
    padding: 8px 10px 8px 10px
}

.button-edit-inventory-group{
    width:203px;
    margin-left: 13px;
    margin-right: -2px;
    padding: 8px 5px 8px 5px
}


.thead-static {
    display: table-row;
}
.inventory-minwidth
{
    min-width: 1112px;
}
.tbody-dynamic{
    overflow-x: hidden;
    display: block;
    height: 325px;
}

.col-header-note-inventory{
    padding-top: 4px;
}