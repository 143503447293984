@import '../base/variables.scss';

input:focus {
    outline: none;
}

input[type=text], input[type=email] {
    -webkit-appearance: none;
    border-radius: 3px;
    background-color: #FFF;
    font: 400 14px $font-primary;
    color: $colorBlack;
}

input[type=text]:disabled, textarea:disabled, select:disabled, input[type=number]:disabled, input[type=email]:disabled {
    background-color: $darkerWhite;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    padding: 6px;
    border-radius: 3px;
    background-color: #FFF;
    font: 400 14px $font-primary;
    color: $colorBlack;
}

//5b6066
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #c0c0c0;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #c0c0c0;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #c0c0c0;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #c0c0c0;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #c0c0c0;
}

input[type=number] {
        -moz-appearance: textfield;
}

::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder {
    color: $darkerGrey;
}

select {
    border: none;
    padding-left: 0;
}

select:focus {
    outline: none;
}

textarea {
    resize: none;
    -webkit-appearance: none;
    padding: 6px;
    border-radius: 3px;
    background-color: #FFF;
    border: none;
    font: 400 14px $font-primary;
    color: $colorBlack;
}

textarea:focus {
    outline: none;
}

.text-input-w-50
{
    width: 50px;
}