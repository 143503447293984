.autocomplete-col > .ant-select-selection > .ant-select-selection__rendered
{
padding: 0;
}
.autocomplete-col > .ant-select-selection > .ant-select-selection__rendered > .ant-select-selection__placeholder
{
    padding: 1px 2px 4px 0 !important;
}
.autocomplete-col > .ant-select-selection
{
  border:none !important;
}
.ant-select-search__field
{
   // line-height: 19 !important;
}
.ant-select-dropdown-menu-item {
    white-space: normal !important
}
.ant-select-auto-complete.ant-select .ant-input {
    box-shadow : 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-width: 1px;
    border: solid 0.5px #b8c1cc !important;
}
.ant-select-auto-complete.ant-select .ant-input:disabled {
    border: none !important;
}
.ant-select-auto-complete.ant-select .ant-input:hover{
    // box-shadow : 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    // border-width: 1px;
    // border: none !important
}
.addNew-autocomplete
{
  height: 32px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #d9d9d9;
  padding: 5px 8px 8px 8px;
  position: absolute;
  width: 100%;
  z-index: 5;
}
.display-visible
{
  display: -webkit-box;
}
.add-new
{
  height: 32px;
  width: 200px; 
}
.ant-selected
{
  
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6) !important;
    border: solid 0.5px #419aff !important;
}
.input-error-autocomplete-new > .ant-select-selector
{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23) !important;
    border: solid 0.5px #f02d37 !important;
}
.input-error-autocomplete > .ant-select-selection--single > .ant-select-selection__rendered  > ul > .ant-select-search--inline >  .ant-select-search__field__wrap > input
{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23) !important;
    border: solid 0.5px #f02d37 !important;
}
  
.ant-input-disabled 
{
    background-color : #e7ecf3 !important;
    color : #7e8893 !important;
    border : none !important;
    height : 32 px !important;
    box-shadow : 0 1px 1px 0 rgba(0, 0, 0, 0.1) !important;
}
.cursor-not-allowed {
    cursor: not-allowed !important
}

.pointer-tagging-important {
    .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
       cursor: default !important;
    }

    .ant-select-selection {
        cursor: default !important
    }
}

.ant-input-disabled:hover{
    box-shadow:none !important;
}

.autocomplete-col-add
{
    font-size: 14px; 
    width: 100%;
}

.ant-input:hover
{
    
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6);
    border: solid 0.5px #419aff;
}
.ant-input:focus
{
    
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6) !important;
    border: solid 0.5px #419aff !important;
}
.long-text--150
{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
    font-size: 14px;
}
.ant-select-search__field{
    font-size: 14px !important;
}
.autocomplete-col , .ant-select-focused
{
    border-color: none !important;
}
.autocomplete-col> .ant-select-selection >.ant-select-selection__rendered > ul > li >.ant-select-search >.ant-select-search__field__wrap >.ant-input:focus
{
    
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6) !important;
    border: solid 0.5px #419aff !important;
    border-radius: 3px !important;
}
.autocomplete-col> .ant-select-selection >.ant-select-selection__rendered > ul > li >.ant-select-search >.ant-select-search__field__wrap
{
    
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6) !important;
    border: solid 0.5px #419aff !important;
    border-radius: 3px !important;
}
.autocomplete-col> .ant-select-selection >.ant-select-selection__rendered > ul > li >.ant-select-search
{
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6) !important;
    border: solid 0.5px #419aff !important;
    border-radius: 3px !important;
}
.ant-select-flat-box > .ant-select-selector {
    border-radius: 0px !important;
}
.ant-select-no-border > .ant-select-selector {
    border: none !important;
}