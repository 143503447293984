.selected{
    height: 32px;
    background-color: #ffffff;
    color: #000000;
    width: 100%;
    font-family: "harmony-sans-regular";
    font-size: 14px;
    font-weight: normal;
    border-radius: 3px;
    padding: 1px 20px 1px 7px;
    border: solid 1px #b8c1cc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:disabled{
        background-color: #e6ecf2;
        color:#7e8893;
    }
    &:hover{ 
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #b8c1cc;
    }
    &:focus{
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6);
        border: solid 0.5px #419aff;
    }
}
.disabled-placeholder{
    color:#7e8893;
    background-color: #e6ecf2;
}
.selected > option {
   padding-top: 5px;
   font-weight: normal;
   //  font-size: 12px;
   border: solid 1px #419aff;
}
.selected > optgroup {
   //  font-size: 12px;
   padding-top: 5px;
   font-weight: normal;
   font-family: "harmony-sans-bold";
   border: solid 1px #419aff;
   option{
      font-family: "harmony-sans-regular";
      font-weight: normal;
   }
}
.selected-error
{
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23) !important;
    border: solid 0.5px #f02d37 !important;
}







.list-seleted{
    :first-child{
       border-top-left-radius: 5px;
       border-top-right-radius: 5px;
   }
   :last-child
   {
       border-bottom-left-radius: 5px;
       border-bottom-right-radius: 5px;
   }
}
.list-seleted > li
{
  
   &:hover{
       background-color: #419aff;
   } 
   &:focus{
       background-color: #419aff;
   }
}
.ant-select-selection-selected-value
{
   margin-top: -2px;
}

/* The CSS */
.cursor-selected > select {
   padding:3px;
   margin: 0;
   width: 100%;
   -webkit-border-radius:4px;
   -moz-border-radius:4px;
   border-radius:4px;
   border-color: #fff;
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
   // -webkit-box-shadow: 0 3px 0 #ccc, 0 -1px #fff inset;
   // -moz-box-shadow: 0 3px 0 #ccc, 0 -1px #fff inset;
   // box-shadow: 0 3px 0 #ccc, 0 -1px #fff inset;
   background: #f8f8f8;
   color:#888;
   outline:none;
   display: inline-block;
   -webkit-appearance:none;
   -moz-appearance:none;
   appearance:none;
   cursor:pointer;
}

/* Targetting Webkit browsers only. FF will show the dropdown arrow with so much padding. */
@media screen and (-webkit-min-device-pixel-ratio:0) {
   select {padding-right:18px}
}

.cursor-selected {position:relative;width: 300px;}
.cursor-selected:after {
   content:'< >';
   font:11px "Consolas", monospace;
   color:#aaa;
   -webkit-transform:rotate(90deg);
   -moz-transform:rotate(90deg);
   -ms-transform:rotate(90deg);
   transform:rotate(90deg);
   right:1px; top:5px;
   padding:0 0 0;
   position:absolute;
   pointer-events:none;
}
.cursor-selected:before {
   content:'';
   right:1px; top:2px;
   width:20px; height:20px;
   background:#f8f8f8;
   position:absolute;
   pointer-events:none;
   display:block;
}
.selected-custome > option
{
   border: none;
   border-radius: 5px;
}
.selected-item{
   width: 100%;
   // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
   &:focus
   {
       // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6);
       border: solid 0.5px #419aff;
   }
}

.ant-select-dropdown-menu-item:hover
{
 background-color: #419aff !important;
} 

.ant-select-disabled > .ant-select-selection
{
   background-color: #e7ecf3!important;  
   border: none !important; 
}
.ant-select-disabled > .ant-select-selection > .ant-select-selection__rendered
{

 color: #7e8893 !important;   
}
.error-selected > .ant-select-selection
{
   border: solid 0.5px #f02d37 !important;
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23);
}

.ant-select-selection
{
   border: 0.5px solid #b8c1cc!important;
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}
// .ant-select:hover > .ant-select-selection--single
// {
//       box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1);
// }
.ant-select:hover > .ant-select-selection--single > .ant-select-selection__rendered > .ant-select-selection__placeholder
{
   color: #5b6066;
}
.ant-select-dropdown-menu-item:hover 
{
   background-color: #e3f3fd!important;
}

.ant-select-selection--multiple .ant-select-selection__rendered .ant-select-search--inline .ant-select-search__field__wrap {
   display: none;
}

.ant-select-open, .ant-select-focused {
   .ant-select-selection--multiple .ant-select-selection__rendered {
      ul {
         .ant-select-search--inline .ant-select-search__field__wrap {
            display: inline-block;
         }
      }
   }
}
.ant-select-open , .ant-select-focused
{
   border-color: #419aff !important;
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6) !important;  
   border-radius: 3px;
}
.ant-select-selection__placeholder, .ant-select-search__field__placeholder
{
   padding: 9px 9px 11px 14px;
   font-size: 14px;
}
.ant-select-selection__rendered
{
   height: 100%;
   padding: 4px 9px 9px 4px;
   font-size: 14px;
   font-weight: normal !important;
   color: #000;
}
.ant-select-selection__placeholder
{
   top:0 !important;
   height: 100% !important;
   padding: 1px 9px 2px 1px !important;
   margin-top: 4px !important;
}
.ant-select-open > .ant-select-selection
{
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.71) !important;
   border: solid 0.5px #419aff !important;
}

.lh-30{
   padding-top: 3px !important;
}

/* Untuk perbaiki style tertimpa antd terbaru */
.ant-select-search {
   display: contents !important;
}

.ant-select-selection.ant-select-selection--single {
   display: grid !important;
}

.ant-select-selection--multiple .ant-select-selection__rendered{
   margin-left: 5px;
   margin-bottom: -10px;
   height: auto;
}
.ant-select-selection.ant-select-selection--multiple {
   dl, ol, ul {
      margin: 0px !important;
   }
   // margin-top: 2px;
   // padding-top: 2px;
   .ant-select-selection__rendered {
      min-height: 38px !important;
      padding: 0px !important;
      display: flex !important;
   }
}

.pointer-tagging-important {
   .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
      cursor: default !important;
   }

   .ant-select-selection {
       cursor: default !important
   }
}

// .ant-select-auto-complete {
//    width: 100% !important;
//    display: inline-block !important;
// }

// .ant-select {
//    display: grid !important;
// }

// .ant-select-selection__rendered {
//    display: contents !important;
// }

.ant-select-search__field__wrap {
   margin-bottom: 10px;
   height: auto !important;
}

// .ant-select-sm.ant-select {
//    margin-top: 2px !important;
//    @-moz-document url-prefix() {
//       top: 1px !important;
//    }
// }

.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
   height: auto;
   padding-top: 5px;
   .ant-select-selection__choice__remove {
      top: 4px;
   }
   @-moz-document url-prefix() {
      padding-top: 3px;
      padding-bottom: 3px;
      .ant-select-selection__choice__remove {
         top: auto;
      }
   }
}