.lbl-cash-coding-title
{
  font-family: harmony-sans-regular;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  max-width: 300px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  color: #242b33;
  &:hover{
    color: #747b84;
    cursor: pointer;
  }
}

.lbl-smartrule-title
{
  font-family: harmony-sans-regular;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #242b33;
  &:hover{
    color: #747b84;
    cursor: pointer;
  }
}
.btn-back-cashcoding{
    width: 100%;
    padding: 0 !important;
    margin-left: -10px;
    margin-top: 10px;

}
.overflow-hidden
{
    overflow: hidden
}
#cash-coding
{
    overflow: hidden;
}
.btn-back
{
    padding: 0;
    margin-top:3px !important;
}

.icon-left-cashcoding {
    width: 20px;
    height: 17px;
    position: absolute;
    margin-left: -75px;
    margin-top: -4px;
}
.icon-right-cashcoding2
{
    width: 10px;
    height: 10px;
    position: absolute;
    margin-left: 66px;
    margin-top: -9px;  
}
.icon-left-cashcoding3 {
    width: 20px;
    height: 17px;
    position: absolute;
    margin-left: -56px;
    margin-top: -4px;
}
.icon-right-cashcoding
{
    width: 10px;
    height: 10px;
    position: absolute;
    margin-left: 60px;
    margin-top: -9px;
}
.icon-right-coa
{
    width: 10px;
    height: 10px;
    position: absolute;
    margin-left: 30px;
    margin-top: -9px;
}
.icon-right-cashcoding3
{
    width: 10px;
    height: 10px;
    position: absolute;
    margin-left: 46px;
    margin-top: -9px;
}
.btn-cash-coding-manage
{
    width: 180px !important;
    margin-right: 20px;
}
.btn-cash-coding-manage2
{
    width: 150px;
    padding-left: 0;
}
.btn-cash-coding-manage3
{
    width: 145px !important;
}
.btn-rule
{
    width: 112px !important;
    margin-right: 20px;
}
.btn-rule-2
{
    width: 122px !important;
    margin-right: 20px;
}
.icon-right-rule
{
    width: 10px;
    height: 10px;
    position: absolute;
    margin-left: 40px;
    margin-top: -9px;
}

.col-menu-cashcoding
{
    width: 100%;

}

.col-menu-cashcoding2
{
    //width: 100%;

}
.col-menu-cashcoding > .ant-menu
{
    z-index: 10;
    display: none;
}
.ant-menu{
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2) !important;
}
.ant-menu > li
{
    
    &:hover
    {
        background-color: #e3f3fd !important;
    }
   
    a:hover > div
    {
        color: #49a9ee !important;
    }
    a:hover
    {
        color: #49a9ee !important;
    }
    > div:hover {
        color: #49a9ee !important;
    }
}
.col-menu-cashcoding > .ant-menu > li
{
    &:hover
    {
        background-color: #e3f3fd !important;
    }
   
    a:hover > div
    {
        color: #49a9ee !important;
    }
    a:hover
    {
        color: #49a9ee !important;
    }
    
}

.listcashcoding-tab > li > a
{
    font-size: 14px;
}
.lbl-info-checked-cashcoding
{
padding: 9px 10px;
  font-family: harmony-sans-regular;
  font-size: 14px;
  text-align: left;
  color: #7e8893;
}
.transaction-checked
{
  padding: 0px 0px;
  font-family: harmony-sans-regular;
  font-size: 14px;
  text-align: left;
  color: #7e8893;
}

.lbl-info-checked-smartrule
{
  font-family: harmony-sans-regular;
  font-size: 14px;
  text-align: left;
}
.height-64 {
    height: 64px;
}
.div-confirm-caschcoding
{
    padding: 10px;
    padding-bottom: 0;
}
.tbl-cash-coding > tbody > tr > td{
padding: 4px;
}
.div-tbl-curr
{
    width: 100px;
}
.div-tbl-check
{
    width: 50px;
}
.div-tbl-date
{
    width: 110px;
}
.div-tbl-ref
{
    width: 118px;
}
.div-tbl-desc
{
    min-width: 150px;
}
.div-tbl-payee
{
    width: 120px;
}
.div-tbl-coa
{
    width: 220px;
}
.div-tbl-tax
{
    width: 140px;
}
.div-info-checked-confrim{

    font-family: harmony-sans-regular;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #121212;
}
.div-info-confrim{
    font-family: harmony-sans-regular;
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 60px;
    color: #121212;
}
#cash-coding >.dynamic-header-table > tr > th
{
    padding : 1px;
}
#cash-coding >tbody > tr > td
{
    padding : 1px;
}

.btn-delete-smartrule {
    width: 37px;
    height: 32px;
}

.img-delete-smartrule {
    width: 24px;
    height: 24px;
    margin-left: -13px;
    margin-top: -7px;
}

.img-delete-smartrule2 {
    width: 18px;
    height: 18px;
    margin-left: -9px;
    margin-top: -3px;
}

.btn-order {
    width: 28px;
    height: 28px;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 0.5px #b8c1cc;
}

.selected-all{
    margin: 0px 10px;
    display: inline;
}

.div-smartrule{
    background-color: #f5f7fa;
    height: auto;
    margin: 0px 46px;
}
.div-smartrule > td{
    padding:16px;
}

.btn-add-condition {
    width: 145px;
    margin-left: 46px;
    margin-top:31px;
}

.cancel-delete {
    padding-left: 0px;
    float: left !important;
    font-family: harmony-sans-bold;
    text-align: left;
}

.total-percentage {
    float: right;
    font-size: 20px;
    text-align: right;
    margin-top: 20px;
    width: 170px;
    margin-right: 36px;
}