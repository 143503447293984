.ownshp-modal-container{
    font-size: 15px;
    font-family: "harmony-sans-regular";
    color: #000;
    > div {
        margin: 10px
    }
    .spacing {
        height: 20px
    }
}