.saas-form{
    min-width: 1000px !important;
}

.saas-form-id{
    min-width: 1250px !important;
}

.saas-form-child{
    min-height: 200px;
}

.saas-subtitle{
    font-size: 16px;
    font-family: "harmony-sans-regular";
    padding-top: 10px;
    margin: 5px 0px 5px 0px;
}
.saas-subtitle-current{
    font-size: 16px;
    font-family: "harmony-sans-regular";
    padding-top: 10px;
    margin: 5px 0px 5px 0px;
}
.saas-subtitle-cc{
    font-size: 16px;
    font-family: "harmony-sans-regular";
    margin-bottom: 20px;
}

.saas-method-tf{
    font-size: 14px;
    font-family: "harmony-sans-regular";
    color: #7e8893;
    margin-top: -10px;
    margin-bottom: 20px;
}

.saas-container{
    padding: 0px 20px 20px 0px;
}

.saas-cancel-billing{
    padding: 20px;  
    margin-bottom: 30px;
    border: 1px solid #f5a623;
    background-color: #fffbec;
    width: 100%;
    font-size: 14px;
}

.saas-cancel-link{
    color: #ff414a;
    font-size: 14px;
    float: right;
    text-decoration: underline;
    cursor: pointer;
}

.saas-cancel-link:hover {
    text-decoration: none;
}

.saas-billing-link{
    color: #36414d;
    font-size: 14px;
    float: right;
    margin-right: 35px;
    text-decoration: underline;
    cursor: pointer;
}

.saas-billing-link:hover{
    text-decoration: none;
}

.saas-current-pack{
    padding-left: 15px;
}

.saas-div-logo{
    margin-top: 1px;
}

.saas-pack-name{
    font-size: 20px;
    font-family: "harmony-sans-bold";
    margin-bottom: -8px;
}

.saas-renew-subs {
    font-family: "harmony-sans-regular";
    font-size: 14px;
    text-align: center;
    color: #7e8893;
    margin-top: 10px;
}

.saas-logo-harmony{
    width: 35px;
    height: 35px;
}

.saas-count-pack{
    font-size: 14px;
    font-family: "harmony-sans-regular";
}

.saas-div-pack{
    margin-bottom: 20px;
}

.saas-div-payment{
    float: left;
    width: 70%;
    margin-bottom: 35px;
}

.saas-container-cc{
    width: 300px;
    border: 1px solid #e6ecf2;
    padding: 10px 20px 10px 20px; 
}

.saas-exp-slash{
    width: 14px;
    height: 32px;
    float: left;
    font-size: 20px;
}

.cc-name{
    font-size: 14px;
}

.cc-exp{
    font-size: 12px;
    color: #b8c1cc;
}

.cc-action{
    font-size: 14px;
    text-align: right;
    margin-top: 15px;
}

.header-content > .list-tab > .saas-comp-name {
    font-size: 16px !important;
    font-family: "harmony-sans-bold";
    padding-top: 12px;
    height: 100%;
}

.saas-btn-text{
    font-size: 16px !important;
    font-family: "harmony-sans-bold";
}

.saas-div-add{
    margin-top: 30px;
    margin-bottom: 50px;
}

.saas-link{
    padding: 0px 0px 0px 0px;
    color: #7e8893;
}

.saas-add-card{
    text-decoration: underline;
    font-size: 14px;
}

.saas-add-card:hover {
    text-decoration: none;
}

.saas-link-cancel{
    color: #7e8893;
    // font-size: 12px;
    text-decoration: underline;
}

.cc-action > a:hover{
    color: #7e8893;
}

//credit card
.saas-edit-title{
    font-size: 20px;
    font-family: "harmony-sans-regular";
    text-align: center;
    color: #7e8893;
}

.saas-remove-cc{
    font-family: "harmony-sans-bold";
    font-size: 16px;
    text-align: left;
    padding-left: 15px;
    color: #121212;
}

.saas-text-delete{
    font-family: "harmony-sans-regular";;
    font-size: 14px;
    margin-bottom: 35px;
    color: #121212 !important;
}

.modal-delete-cancel{
    width: 115px !important;
    font-family: "harmony-sans-bold";
    color: #242b33;
    float: left !important;
    padding: 10px 0px 13px 0px;
}

.modal-delete-cancel-card{
    width: 175px !important;
    font-family: "harmony-sans-bold";
    color: #242b33;
    float: left !important;
    padding: 10px 0px 13px 0px;
}

.modal-downgrade{
    width: 85px !important;
    font-family: "harmony-sans-bold";
    color: #242b33;
    margin-right: 10px;
    padding: 10px 0px 10px 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
}
//end of credit card

.saas-icon-helper{
    width: 13px;
    height: 13px;
    margin-left: 5px;
}

.saas-billing-history {
    padding: 0px;
}

.saas-container-plan{
    margin-bottom: 20px;
}

.saas-modal-cancel{
    width:  370px !important;
}

.saas-div{
    margin: -5px 15px 0px 15px;
}

.saas-cancel-title{
    text-align: left;
    font-family: "harmony-sans-bold";
    padding-left: 15px;
    font-size: 16px;
}

.saas-cancel-text{
    font-family: "harmony-sans-regular";
    margin: 0px 0px 25px 0px;
    font-size: 14px;
    color: #121212;
}

.saas-button-act{
    margin-top: 55px;
}

.saas-cancel-subs{
    font-family: "harmony-sans-regular";
    font-size: 14px;
    padding: 5px 20px 7px 20px;
    text-decoration: none;
    float: right;
    color: #000000;
}

.saas-button-cancel{
    width: 70px;
}

.saas-button-back{
    width: 100px;
}

.saas-cancel-pass > label {
    font-family: "harmony-sans-bold";
    margin-bottom: 8px;
    font-size: 14px;
    color: #121212;
}

.saas-label-text {
    font-family: "harmony-sans-bold";
    margin-bottom: 8px;
    font-size: 16px;
    color: #121212;
}

.saas-input-pas{
    width: 190px;
    float: left;
}

.saas-wd-60 {
    width: 100%;
    display: inline;
}


.saas-icon-matches{
    width: 18px;
    height: 18px;
    float: left;
    margin-top: 7px;
    margin-left: 10px;    height: 100%;
    width: 100%;
}

.ant-modal > .ant-modal-content > .ant-modal-body > .saas-div > .saas-cancel-pass > .col-input-type {
    width: 190px;
}

.full-screen {
    position: unset;
    padding-bottom: 0px;

    .ant-modal-content {
        height: 100%;
        width: 100%;
    }
}

.form-allocate {
    width: 850px !important;
    margin: 12px auto !important;
}

.saas-error-charge {
    margin-bottom: 15px;
    max-width: 700px;
    background-color: #ffe3e3;
    padding: 8px 8px 8px 15px;
    border-radius: 4px;
    color: #000;
}

.content-icons {
    margin-right: -12px;
    margin-bottom: -2px;
}

.info-empty-card{
    width: 288px;
    margin: auto;
    text-align: center;
    font-size: 12px;
    color: #7e8893;
}

.saas-container-cc > .cc-action > .container-icons {
    display: inline-block;
}

#saas-download-file {
    text-decoration: underline;
}

#saas-download-file:hover {
    text-decoration: none;
}
/*  
@media (max-width: 768px) {
    .ant-modal {
        width: 300px !important;
        margin: auto !important;
    }
}
 */

 .mysub-table {
    width: 80%;
    font-size:16px;
    td{
        width: 30%
    }
    margin-bottom: 50px !important
}

.mysub-cancel-subs {
    margin-top: 12px;
    font-size: 14px;
    font-family: "harmony-sans-regular";
    color: #7e8893;
    margin-top: 12px;
}

.pricing-checkmark {
    width: 14px;
    height: 14px;
    color: blue;
}