.aging-static {
    float: left;
    width: 55%;
    th, td {
        height: 39px;
    }
}

.aging-dynamic {
    float: left;
    width: 45%;
    overflow-y: auto;
    th, td {
        min-width: 200px !important;
        height: 39px
    }
}