.bill-add-row-1{
    tr > td:nth-child(6n-3),
    tr > td:nth-child(6n-4){
        width: 18%;
    }
    tr > td:nth-child(6n-1),
    tr > td:nth-child(6n-2),
    tr > td:nth-child(6n-5){
        width: 18%
    }
}

.bill-add-row-2{
    margin-bottom: 12px !important;
    tr > td:nth-child(3n-1),
    tr > td:nth-child(3n-2){
        width: 18%
    }
}

// purchase order
.po-address-content {
    margin-bottom: 0px;
}

.container-modal-content > .po-address-content > .po-address-radio {
    float: left;
    width: 5%;
    margin-top: -13px;
}

.container-modal-content > .po-address-content  > .po-address-text {
    float: left;
    width: 90%;
    margin-left: 3%;
    margin-bottom: 10px;
}

.container-modal-content > .po-address-content  > .po-address-text > label {
    font-size: 14px;
    font-family: "Harmony-sans-regular";
    color: #7e8893;
    cursor: pointer;
}

.container-modal-content > .po-address-content  > .po-address-text > p {
    font-size: 12px;
    font-family: "Harmony-sans-regular";
    color: #7e8893;
    cursor: pointer;
}

.po-view-address > .po-view-content > .po-view-text > label {
    font-size: 14px;
    font-family: "harmony-sans-regular";
    color: #36414d;
    margin-bottom: 5px;
}

.po-view-address > .po-view-content > .po-view-text > p {
    font-size: 12px;
    font-family: "harmony-sans-regular";
    color: #7e8893;
    margin-bottom: 5px;
}

.add-new-address {
    margin-bottom: 30px;
}