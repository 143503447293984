.ant-carousel .slick-dots li {
    width: 18px;
}

.ant-carousel .slick-dots li button {
    // background: rgb(220, 237, 255);
    background: transparent;
    opacity: 0.4;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: solid 2px #FFF;
}

.ant-carousel .slick-dots li.slick-active {
    width: 18px;
}

.ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: rgb(255, 255, 255);
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-top: -1px;
    margin-left: -1px;
}