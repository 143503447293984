.popover-relative {
    position: relative;
} 

.popover-absolute {
    z-index: 10;
    position: absolute;
    top: -15px;
    right: 13px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2) !important;
}

.popover-absolute-cashbank {
    z-index: 10;
    position: absolute;
    top: -15px;
    right: 0px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2) !important;
}

.popover-absolute-cashbank-top {
    z-index: 10;
    position: absolute;
    bottom: 20px;
    right: 0px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2) !important;
}
// #company-table tr:nth-child(8n), tr:nth-child(9n), tr:nth-child(10n){
//     .popover-absolute{
//         top: -200px;
//         right: 13px;
//     }
// } 
.icon-dropdown2
{
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0
}
.popover-relative > .ant-menu > li > div
{
    color: black;
    &:hover
    {
        color: #49a9ee !important;
    }
}
.popover-relative > .ant-menu > li
{
    background-color: #fff !important;
    &:hover
    {
        background-color: #E3EEF5 !important;
    }

    a:hover
    {
        color: #49a9ee !important;
    }
}

.popover-relative > ul
{
    right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
}