.div-calendar
{
     border-radius: 4px!important;
     border: 1px solid #B8C1CC !important;
     left : 2px;
     
     
}
.form-control
{
    height: 42px;
}
.div-number
{
    width:  200px;
    overflow-wrap: break-word;
}