.default{
   width: 100%;
    height: 32px;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 0.75;
    text-align: center;
    padding: 10px 19px 11px 19px;
    //font-family: harmony-sans-regular;
    font-family: "harmony-sans-bold";
}
.button-loading
{
    display: flex;
   // width: auto !important;
}
.button-loading-submit
{
    display: flex;
    width: auto ;
    margin-left: "40%";
}
.button-loading-icon{
    //margin-right: 10px;
    margin:auto;
}
.button-loading-icon-submit{
    margin-left: 40%;
}
.default[disabled]
{
  border: none;
  border-radius: 3px;
  background-color: #e7ecf3!important;
  color: #b8c1cc;
  box-shadow:none !important;
  &:hover
  {
  background-color: #e7ecf3!important;
  box-shadow:none !important;
  }
}
.button--primary
{
    background-color: #419aff;
    box-shadow: 0 2px 4px 0 rgba(65, 154, 255, 0.5);
    &:focus{
            background-color: #1d7eec;
            box-shadow: 0 2px 4px 0 rgba(65, 154, 255, 0.62)
        }
    &:hover
    {
        background-color:#5aa6fe;
    }
}

.button--save{
    background-color: #4ddc7c;
    box-shadow: 0 2px 4px 0 rgba(77, 219, 124, 0.54);

    &:focus{
        background-color: #3edf74;
    }
    &:hover
    {
        background-color: #5ff190;
        box-shadow: 0 3px 4px 0 rgba(77, 219, 124, 0.57);
    }
}

.wd--24{
    width: 24px !important;
    height: 24px !important;
  }

.button--secondary {
    width: 100%;
    //font-family: harmony-sans-regular;
    font-family: "harmony-sans-bold";
    height: 32px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(126, 136, 147, 0.19);
    border: solid 0.5px #b8c1cc;
    font-size: 14px;
    line-height: 0.75;
    text-align: center;
    padding: 10px 18px 13px 18px;
    color: #242b33;
        &:focus{
            
            background-color: #e7ecf3;
            border: solid 0.5px #b8c1cc;
        }
        &:hover{
        box-shadow: 0 3px 2px 0 rgba(126, 136, 147, 0.2);
        border: solid 0.5px #b8c1cc;
        color: #747b84;
        }
        > label
        {
            font-weight: normal;
        }
} 


.button-cancel {
    width: 100%;
    border: none;
    font-family: harmony-sans-regular;
    background-color: #fff;
    padding: 10px 20px 13px 20px;
    font-size: 14px;
    line-height: 0.75;
    text-align: center;
    color: #121212;
    &:hover{
    color: #747b84;
    }

}
.button-cancel:disabled , .button:disabled , .button--secondary:disabled
{
    border: none;
    
  border-radius: 3px;
  background-color: #e7ecf3;
  color: #b8c1cc;
}
.button-cancel-border {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 5px;
    font-family: harmony-sans-regular;
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    line-height: 0.75;
    text-align: center;
    color: #121212;
    &:hover {
        color: #747b84;
    }
}

.button-cancel-border:disabled , .button:disabled , .button--secondary:disabled
{
    border: none;
    
  border-radius: 3px;
  background-color: #e7ecf3;
  color: #b8c1cc;
}
.button-delete
{
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: #ff7a7a;
    box-shadow: 0 1px 3px 0 #ff8b90;
    color: #fff;
    font-size: 14px;
    line-height: 0.75;
    text-align: center;
    padding: 10px 19px 11px 19px;
    //font-family: harmony-sans-regular;
    font-family: "harmony-sans-bold";
    border: solid 0.5px #ff7a7a;
    &:hover
    {
    background-color: #ff9999;
    }
}
.button-delete[disabled]
{
  border: none;
  border-radius: 3px;
  background-color: #e7ecf3;
  color: #b8c1cc;
  box-shadow:none;
  &:hover
  {
  background-color: #e7ecf3;
  box-shadow:none;
  }
}