.div-action-bank-statment
{
    //display: flex;
}
.div-action-bank-statment > button
{
    margin-right: 10px;
    margin-top:28px;
}
.width-auto
{
    width: auto!important
}
.color-recon
{
    color: #4DDB7C;
}
.color-unrec
{
    color: #ff7a7a
}
.color-delete
{
    color: gray;
}
.cur-default
{
    cursor: default;
    &:hover
    {
        color: #ff7a7a;
    }
    
}
.tbl-card-bank-reconcile > tr
{
    //border-top:solid 1px #e7ecf3;
}
.tbl-card-bank-reconcile > .nonborder
{
    border-top:none;
}
.cur-default-rec
{
    cursor: default;
    &:hover
    {
        color:  #4DDB7C;
    }
    
}