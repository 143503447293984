.warpper-myharmony
{
    color: #ffffff;
    font-size: 16px;
}
.back-page-app
{
    padding: 0;
    :hover{
        background-color: #435476;
    }
}
.back-page-app:disabled
{
    :hover{
        background-color: #192b46;
        cursor: not-allowed;
    }
}
.disabled-cursor
{
    cursor: not-allowed;
}