.top-side-cashbank
{
    display: inline-flex;
}
.top-side-cashbank > button
{
    &:first-child
    {
        margin-right: 16px;
    }
    
    width: auto;
}
.lbl-menu-cashbank
{
    color:#b8c1cc;
}
.footer-card-bank
{
    display: -webkit-inline-box;
    float: right;
}
.div-line
{
    border-right: solid 0.5px #e7ecf3;
}
.div-line > a:hover > label,
.div-line2 > a:hover > label
{
    color: black;    
}
.disabled-lbl{
    color: #b8c1cc !important;
    cursor: default ;
    text-decoration: unset;
}
.footer-card-bank > div
{
    padding-left: 11px;
     width: auto;
    padding-right: 11px;
    text-align: right;
    
}
.click-link{
    position: absolute;
    top: 0;
    left: 21px;
  height: 20px;
  font-size: 14px;
  color: #419AFF;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  
}
.icon-sync-error{
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10px;
    left: 110px;
    padding: 0;
    margin: 0;
}
.icon-success-onefeed{
    width: 100px;
    height: 100px;
}
.icon-sync{
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0;
    margin-top:2px;
}
.linked{
    color:#50e3c2;
    &:hover {
        color:#50e3c2;
    }
}
.unlinked
{
    color: #7e8893;
    &:hover {
        color: #7e8893;
    }
}
.error-sync{
    color:#ff414a;
    &:hover {
        color:#ff414a;
    }
}
.last-sync {
    min-width: 160px;
    height: 20px;
    position: absolute;
    bottom: -14px;
    padding-left: 20px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #7e8893;
  }
.lbl-info-curr{
    // width: 19px;
    height: 20px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #7e8893;
}

.rectangle-reconcile-cash-bank{
    font-size: 12px;
    height: 25px;
    border-radius: 10px;
    border: none;
    background-color: #419aff;
    color: white;
    font-family: "harmony-sans-bold";

    &:hover{
        background-color:#192b46;
    }
}
.footer-card-bank > div:last-child
{
    padding-left: 11px;
    padding-right: 0;
    text-align: right;
    
}
.icon-cashbank
{
    width: 10px;
    height: 10px;
    margin-left: 5px;
    margin-bottom: 4px;
}
.lbl-cashbank-menu
{
    font-size: 12px;
    font-family: harmony-sans-regular;
    cursor: pointer;
}
.icon-bank1
{
    width: 24px;
    height: 24px;
    position: absolute;
    margin-left: -58px;
    margin-top: -7px;
}

.icon-bank2
{
    width: 20px;
    height: 28px;
    position: absolute;
    margin-left: -66px;
    margin-top: -9px;
}
.btn-save-cash-coding
{
    width: 195px;
    margin-top: 13px;
    margin-right: 10px;
    margin-bottom: 13px;
}
.error-message-cash-coding
{
    width: 70%;
    margin-left: 15%;
    padding-top: 10px;
}
.cn--subtitle{
    font-family: harmony-sans-regular;
    padding-left: 20px;
    padding-bottom: 10px;
    font-size: 16px;
    text-align: left;
    color: #121212;
}
.cn--link{
    // text-decoration: underline;
    text-decoration-color: #419AFF;
}

.cn--textinfo{
    font-family: harmony-sans-regular;
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: #000000;
}
.content-cn{
    min-height: 300px;
}
.cashbank-add-row-1{
    tr > td:nth-child(4n-1){
        width: 250px;
        min-width: 250px;
    }
    tr > td:nth-child(4n-2){
        width: 200px;
        min-width: 200px;
    }
    tr > td:nth-child(4n-3){
        width: 17%;
        min-width: 220px;
    }
    tr > td:nth-child(4n){
        min-width: 220px;
    }
}
.form-cashbank-view{
    padding-left: 25px;
}
.sub-title-cashbank{
    color:#121212;
    font-size: 16px;
}
.info-onefeed-cashbank-view{
    color: #7e8893;
    font-size: 12px;
}
.btn-resume-onefeed{
    margin-left: 25px;
    width: 170px;
}
.unlinked-cashbank-view{
    color: #7e8893;
    // text-decoration: underline;
}
.icon-cashbank-view{
    width: 11px;
    height: 11px;
    margin-right: 5px;
}
.disabled-div{
    background-color:  #e7ecf3;
    //height: 100px;
    //padding-left: 10px;
    font-size: 14;
    color: #7e8893;
    font-weight: normal;
    font-family: harmony-sans-regular;
}