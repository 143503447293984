
.conbalance-static {
    float: left;
    width: 100%;
    min-width: 180px;
    margin-top:0px;
    height: 39px;
    th, td {
        height: 39px
    }
}

.total-static{
    width: 100%;
    min-width: 180px;
    float: none;
    height: 92px;
    background-color: #ffffff;
}

.conbalance-dynamic {
    width: 100%;
    overflow-x: auto;
    height: 600px;
    tr, td {
        min-width: 180px !important;
        height: 39px !important;
    }
}

.consbal-lebelsearch{
    // width: 1071px;
    height: 32px;
    border: solid 0.5px #b8c1cc;
    background-color: #f8f8f8;
    text-align: center;
    font-family: "harmony-sans-regular";
    font-size: 14px;
    padding-top: 4px;
}

.row-disable{
    background-color: #e6ecf2 !important;
    color:  #7e8893 !important;
    border: none !important;
    cursor: not-allowed !important;
}
