.cards {
    padding: 20px;
}

.card {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid rgba(50,70,90,.5);
    border-radius: 3px;
    box-shadow: 1px 1px 3px #DDD;
    box-sizing: border-box;
    
    .card-fix {
        height: 120px;
    }

    h1, h4 {
        margin-top: 0;
        text-transform: uppercase;
    }

    h1 {
        font-size: 18px;
        span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    h4 {
        font-size: 14px;
        margin-bottom: 10px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.card-child {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    border: 1px solid rgba(50,70,90,.5);
    border-radius: 3px;
    box-shadow: 1px 1px 3px #DDD;
    // height: 120px;
    box-sizing: border-box;

    h1, h4 {
        margin-top: 0;
    }

    h1 {
        font-size: 18px;
        text-transform: uppercase;
    }

    h4 {
        font-size: 14px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
}

.card-bg {
    background-color: #f8f8f8;
}

.form--input {
    &.form--input-file-single {
        border: 1px solid rgba(50,70,90,.5);
        box-shadow: 1px 1px 3px #DDD;
        border-radius: 3px;
        height: initial;
        padding: 10px;
        width: 100%;
    }
}

.fix-h {
    height: 80px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-30 {
    margin-bottom: 30px;
}
.mb-20 {
    margin-bottom: 20px;
}

.bdr {
    border-right: 1px solid #d6d6d6;
}

.wd-5 {
    width: 5px;
    padding: 0px;
    margin: 0px;
}
.wd-10 {
    width: 110px;
    margin: 0px;
    padding-left: 5
}
.wd-160 {
    width: 160px;
    margin: 0px;
    padding-left: 5
}
.mt--1{
    margin-top: 1px !important;
}
.mt-10 {
    margin-bottom: 10px;
}

.color-tab {
    background-color: #fff;
    opacity: 0
}
.pd-20 {
    margin-left: 10px
}
.hr {
	border-top: 3px double black;
}
.msg-red {
    color: red
}
.bg-black {
    background: black;
    color: white
}
.p-custom {
    font-size: 16px;
    margin-bottom: 10px;
}

.pd {
    height: 42px;
}