.lbl-forgot
{
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #242b33;
    font-family: harmony-sans-regular;
}
.lbl-info-forgot
{
    font-family: harmony-sans-regular;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    padding: 30px 40px 0 40px;
    color: #242b33;
}