.paymethod-td-trf{
    vertical-align: top;
    padding-top: 8px
}

.paymethod-tr{  
    height: 40px
}

.paymethod-title{
    margin-top: 10px;
    margin-left: 18px;
    font-size: 20px;
    font-family: harmony-sans-bold
}