.title-modal-solution{
    font-family: "harmony-sans-bold";
    font-size: 16px;
    text-align: left;
}
.div-info-solution
{
    background-color: #f5f7fa;
    border-radius: 3px;
    border: solid 1px #419aff;
    margin-bottom: 20px;
}
.tbl-info-solution > tr > td
{
    padding: 10px;
}