.cs--subtitle {
    font-family: harmony-sans-regular;
    font-size: 16px;
    text-align: left;
    color: #121212;
}

.comset-icon{
    width: 14px;
    height: 14px;
    margin-bottom: 4px;
    object-fit: contain;
}

.not-found-icon{
    margin-top: 30px;
    width: 54px;
    height: 54px;
    object-fit: contain;
}

.address-icon{
    width: 20px;
    height: 20px;
    padding: 5px;
    object-fit: contain;
    border: 1px solid #b8c1cc;
}

.cs--icon{
    width: 14px;
    height: 14px;
    object-fit: contain;
    padding-top: 50%;
}

.star-icon {
    width: 20px;
    height: 20px;
}

.star-icon-on {
    color: gold;
}

.star-icon-off {
    color: darkgray;
}

.cs-address-text{
    width: 299px;
    height: 64px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    border: solid 0.5px #b8c1cc;
}

.cs-label{
    width: 138px;
    height: 14px;
    font-family: harmony-sans-regular;
    font-size: 14px;
    text-align: left;
    padding-left: 10px;
    color: #000000;
}

.cs-zipcode{
    width: 86px;
    height: 32px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    border: solid 0.5px #b8c1cc;
}

.cs-attention{
    width: 171px;
    height: 32px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    border: solid 0.5px #b8c1cc;
}

.imgPreview{
    margin-top: -9px;  
    margin-bottom: 20px;
    width: 215px;
}

.previewImg {
  width: 100%;
  height: 100%;
}

.previewText {
    width: 100%;
    margin-top: 20px;
}

.centerText {
    text-align: center;
    width: 500px;
}

//card company setting
.list-company-setting{
    display: inline-block;
    width: 100%;
}

.cs-data-content{
    width: 49%;
    height:200px;
    min-width: 565px;
    min-height: 200px;
    padding: 1px 0px 5px 30px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 1px #e6ecf2;
    display: inline-block;
}

.card-item {
    float: left !important;
    width: 33% !important;
    min-width: 160px !important;
    padding-right: 10px;
}

.cs-data-content:nth-child(even){
    float: right;
}

.wd-30{
    width: 30% !important;
}

.wd-50{
    width: 50% !important;
}

.icon-dismiss{
    width: 15px;
    height: 15px;
    margin-right: -7px;
    margin-bottom: -7px;
    float: right;
    position: relative;
    z-index: 999;
    cursor: pointer;
}

.menu-icon{
    width: 18px;
    height: 18px;
}

.menu-icon2{
    width: 23px;
    height: 23px;
    margin-top: -1px;
}

.menu-icon-coa{
    width: 18px;
    height: 18px;
}

.btn-modal-disabled{
    cursor: not-allowed; 
}

.menu-icons{
    width: 18px;
    height: 18px;
    padding-bottom: 3px;
}
.clear{
    clear: both
}

.default-text{
    text-align: right; 
    // margin-top: 60px; 
    padding-right: 10px;
    display: inline-block;
    position: relative;
    bottom: -85px
}

.def-text{
    font-size: 14px;
    color: #419aff;
}

.compset-link{
    // text-decoration: underline;
}

.dis-delete2
{
    font-size: 14px;
    line-height: 0.86;
    text-align: left;
    color: #b8c1cc;
}

.min-width-md{
    min-width: 1024px !important;
}

.upload-disabled {
    cursor: not-allowed !important;
    color: #7e8893 !important;
}

.pop-disabled{
    color: #b8c1cc !important;
    background-color:#f5f7fa !important;
}

.cursor-point{
    cursor: pointer;
}

.compset-delete-content{
    text-align: center;    
    font-size: 16px;
    font-family: "harmony-sans-regular";
    color: #000;
    margin: 0px 0px 50px 0px;
}

.ant-select-disabled:hover > .ant-select-selection--single > .ant-select-selection__rendered > .ant-select-selection__placeholder {
    color: transparent;
}

.ant-select-disabled > .ant-select-selection--single > .ant-select-selection__rendered > .ant-select-selection__placeholder {
    color: transparent;
}

input:disabled::-webkit-input-placeholder { /* WebKit browsers */
    color:    transparent;
}
input:disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    transparent;
}
input:disabled::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    transparent;
}
input:disabled:-ms-input-placeholder { /* Internet Explorer 10+ */
    color:    transparent;
}