.div-card
{
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    border: solid 0.5px #b8c1cc;
    width: 476px;
    height: 220px;
    float: left;
    margin-right: 30px;
    margin-bottom: 50px;
}
.card-title
{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #192b46;
  color: #ffffff;
  width: inherit;
  font-family: harmony-sans-regular;
  padding: 18px 20px;
  padding-top: 10px;
  width: 474px;
  height: 74px;
  display: inline-flex;
}
.card-title-left
{
    text-align: left;
    background-color: #192b46;
    width: 80%;
   /// float: left;
   // border-bottom-left-radius: 3px;

}
.lbl-bank-name-card-link{
    
    font-size: 14px;
    text-decoration: underline;
    font-family: harmony-sans-bold;
    text-align: left;
    color: #ffffff;
    
}
.lbl-bank-name-card-link[disabled]
{
    color: #ffffff !important;
    text-decoration: unset;
}
.color-lbl-bank-name{
    color: #419aff !important;
}
.lbl-bank-name-card
{
    font-size: 20px;
  text-align: left;
}



.lbl-bank-number-card
{
  font-size: 12px;
  text-align: left;
  font-weight: normal;
}

.color-lbl{
    color: #292929 !important;
}

.card-title-right
{
    background-color: #192b46;
    //float: left;
    width: 20%;
    //border-bottom-right-radius: 3px;
    padding-top: 10px;
    padding-bottom: 14px;
}
.recon-list-card
{
    width: 140px;
  height: 30px;
  border-radius: 100px;
  background-color: #ff7a7a;
  float: right;
  text-align: center;
}
.lbl-recon-card
{
    font-family: harmony-sans-regular;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
} 
.content-card
{
    padding: 18px 42px;
}
.content-card-detail-left
{
    float: left;
}
.content-card-detail-right
{
    float: right;
}
.lbl-statment
{
    font-size: 14px;
    font-weight: normal;
    font-family: "harmony-sans-bold";
}
.lbl-info-statment
{
    font-size: 11px;
    font-weight: normal;
}
.bank-card
{
    font-family: "harmony-mono-regular";
  font-size: 14px;
  line-height: 1.75;
  text-align: right;
  color: #50e3c2;
  text-decoration: underline;
}
.bank-card[disabled]{
    color: #121212;
}
.harmony-card
{
    font-family: "harmony-mono-regular";
  font-size: 14px;
  line-height: 1.75;
  text-align: right;
  color: #419aff;
  text-decoration: underline;
}
.harmony-card[disabled]
{
    color: #121212;
}
.footer-card
{
    clear: both;
    text-align: right;
    a{

    font-family: harmony-sans-regular;
  font-size: 12px;
  text-align: right;
  color: #7e8893;
  position: relative;
    }
}
.icon-see-card-bank
{
    width: 11px;
    height: 11px;
    position: absolute;
    //top:-15px;
    top: 8px;
   // margin-left: 15px;
    right: -20px;
}
.active-dashboard
{
    color:black !important;
}

.bg-nonactive{
    background-color:#e6ecf2 !important;
}

.color-nonactive{
    color:#b8c1cc !important;
}


