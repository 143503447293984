.btn-add-ppn {
    width:230px;
    padding:0px;
}

.nodata-img {
    padding: 0px 50px 50px 50px;
}

.table-jenis-pajak {
    width: 350px;
    margin-bottom: 20px;
}

.border-top-none {
    border-top:none !important;
}

.model-status-ppn {
    float: left !important;
    text-align: left !important;
    display: inline-flex !important;
}

.detail-form {
    border: 1px solid #b8c1cc;
    padding:20px;
    margin:0px 20px;
}

.detil-tbl-onegate {
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 0.5px #ddd;
    overflow: hidden;
    min-height: 240px
}

.div-empty-detail{
    text-align: center;
    margin-top: 75px;
}

.img-add {
    width: 16px;
    margin-right: 7px;
    margin-top: -3px;
}

.col-header-calculate-onegate {
    background-color: #f5faff;
    padding: 20px;
    min-height: 176px;
}
.col-header-cal-onegate {
    background-color: #f5faff;
    padding: 20px;
}
.calculate-onegate{
    text-align: left;
    width:50%;
}

.table-modal-onegate{
    width: 100%;
    background-color: #f5faff;
}

.margin-tbl-onegate{
    margin:0px 30px 20px 30px;
}

.label-tbl-onegate{
    padding:30px 30px 0px 30px;
    font-size: 16px;
    font-family: harmony-sans-bold;
    color:black;
}

.btn-edit-ppn {
    margin-right: 6px;
    margin-top: -5px;
    height: 16px;
    width: 16px;
}

.btn-export-csv{
    display: flex;
}

.btn-onegate-manage {
    width: 130px !important;
}