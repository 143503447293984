.proloss-picker{
    width: 167px;
    margin-right: 10px;
    margin-top: 4px 
}

.proloss-select-type{
    width: 120px;
    margin-right: 10px;
    margin-top: 4px
}

.proloss-static {
    float: left;
    width: 30%;
    max-width: 475px;
    th, td {
        height: 39px;
    }
}

.asset-static {
    float: left;
    width: 32%;
    max-width: 400px;
    th, td {
        height: 39px;
    }
}

.asset-dynamic {
    float: left;
    width: 68%;
    overflow-y: auto;
    th, td {
        min-width: 250px !important;
        height: 39px
    }
}

.proloss-static2 {
    float: left;
    width: 50%;
    max-width: 475px;
    th, td {
        height: 39px;
    }
}

.profit-view{
    min-width: 900px;
    width:auto;
    display: inline-block
}

.profit-view2{
    min-width: 1350px;
    width:auto;
    display: inline-block
}
.profit-view3{
    min-width: 600px;
    width:auto;
    display: inline-block
}

.proloss-dynamic {
    float: left;
    width: 70%;
    overflow-y: auto;
    th, td {
        min-width: 300px !important;
        height: 39px
    }
}

.proloss-dynamic2 {
    float: left;
    width: 50%;
    overflow-y: auto;
    th, td {
        // min-width: 100px !important;
        height: 39px
    }
}

.shadow{
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.buss-act-picker{
    float: left;
    .ant-input{
        height:34px !important
    }
}

.icon-file-manage{
    margin-right: 7px;
    margin-top:-3px;
}

.btn-add-file{
    width:100px;
    padding:10px;
    font-size: 14px;
    font-weight: normal;
    font-family: harmony-sans-regular;
}

.btn-transaction-file {
    width:130px;
    padding: 10px 0px !important;
}

.btn-transaction-ppn {
    width:140px;
    padding: 10px 10px !important;
}

.ul-transaction-file {
    line-height: 32px !important;
    height:32px !important;
}