.mysub-table {
    width: 80%;
    font-size:16px;
    td{
        width: 30%
    }
    margin-bottom: 50px !important
}

.mysub-cancel-subs {
    // float:left;
    // width: 30%;
    margin-top: 12px;
    font-size: 14px;
    font-family: "harmony-sans-regular";
    color: #7e8893;
    margin-top: 12px;
}
