@import '../../base/variables.scss';

$smooth: all 250ms ease;

.notification-wrap {
  position: relative;

  .overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
  }

  .notif-icon {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    color: $muteGrey;
    margin-left: auto;
    z-index: 9;
    &:hover {
      cursor: pointer;
    }
    > .icon, >.icon > svg {
      display: block;
      width: inherit;
      height: inherit;
    }
  }

  .notif-dropdown {
    width: 280px;
    height: 400px;
    color: $white;
    background-color: $color-dark-ebony-clay;
    border-radius: 5px;
    transition: $smooth;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    z-index: 10;
    &.active-notif {
      display: block;
      top: calc(100% + 5px);
      visibility: visible;
      opacity: 1;
    }

    .nd-head {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding: 15px;
      .title {
        font-size: 16px;
        font-weight: 500;
        user-select: none;
      }
    }
    .nd-body {
      display: block;
      height: calc(100% - 60px);
      overflow-y: auto;
      ul {
        // display: flex;
        // flex-wrap: wrap;
        // align-items: flex-start;
        width: 100%;
        height: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          position: relative;
          display: block;
          width: 100%;
          background-color: $color-dark-ebony-clay;
          border-bottom: 1px solid rgba(255,255,255,0.2);
          transition: $smooth;
          &:hover {
            cursor: pointer;
            background-color: lighten($color-dark-ebony-clay, 5%);
          }
          &:nth-last-child(2) {
            border-bottom: 0;
          }
          &:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            border-top: 1px solid rgba(255,255,255,0.2);
            margin-top:auto;
          }

          .card-notif {
            padding: 15px 24px;
            &:before {
              content: '';
              display: none;
              width: 8px;
              height: 8px;
              background-color: $colorBlue;
              border-radius: 50%;
              position: absolute;
              top: 17px;
              left: 8px;
            }
            &.unread {
              &:before {
                display: block;
              }
            }

            .time {
              display: block;
              font-size: 12px;
              color: $lightGrey;
              user-select: none;
              margin-bottom: 2px;
            }
            .text {
              display: block;
              font-size: 14px;
              color: $white;
              line-height: 1.3;
              user-select: none;
            }
          }

          .view-all {
            font-size: 14px;
            font-weight: bold;
            color: $colorBlue;
            user-select: none;
          }
        }
      }
    }

    .nd-options {
      display: block;
      position: relative;
      .option-icon {
        &:hover {
          cursor: pointer;
        }
      }
      .option-dropdown {
        width: 160px;
        transition: $smooth;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        z-index: 1;
        &.active-option {
          display: block;
          top: calc(100% + 5px);
          visibility: visible;
          opacity: 1;
        }
        
        ul {
          display: block;
          list-style: none;
          border-radius: 5px;
          overflow: hidden;
          padding: 0;
          margin: 0;
          li {
            position: relative;
            display: block;
            text-decoration: none;
            background-color: $white;
            user-select: none;
            padding: 10px;
            &:hover {
              cursor: pointer;
              background-color: $color-hawkes-blue;
            }

            .text {
              position: relative;
              display: block;
              color: black;
              font-size: 12px;
              z-index: 1;
            }
          }
        }
      }
    }

    .loading-box, .empty-box {
      color: $lightGrey;
      letter-spacing: 0.5px;
      line-height: 1.4em;
      text-align: center;
      user-select: none;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.checkbox-list {
  float: left;
  padding-left: 20px;
  padding-top:9px;
}

.setting-list{
  padding: 10px 0px;
}

.no-notification-list {
  margin:auto;
  text-align: center;
  padding-top: 130px;
  height:400px;
}