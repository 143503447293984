.lblname {
    font-size: 14px;
    color: #7e8893;
    font-weight: normal;
    font-family: "harmony-sans-regular";
}

.font-usermanagement {
    font-family: "harmony-sans-regular";
    font-size: 16px;
}

.active-status-management {
    color: #4ddb7c;
}

.rejected-status-management {
    color: #ff414a;
}
.icon-action-management
{   
    padding: 20px;
}