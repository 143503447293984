.payconf-table{
    
    td{
        padding: 5px
    }
    tr td:first-child{
        font-weight: bold;
    }
    tr td:nth-child(3n-1){
        width: 15px
    }
    tr td:nth-child(3n){
        width: 245px;
        max-width: 245px
    }
}