.header-reconcile {
    padding: 1px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: #ffffff;
    border-top: solid 0.5px #b8c1cc;
    border-bottom: solid 0.5px #b8c1cc;
    width: 100%;
    display: -webkit-inline-box;
}
.header-reconcile2 {
    background-color: #ffffff;
    padding: 1px;
    width: 200%;
    display: -webkit-inline-box;
    //border: solid 0.5px #b8c1cc;
}

.header-reconcile > div {
    //width: 50%;
    border-top-right-radius: 5px;
    //float: left;
    max-height: 60px;
    text-align: center;    
    font-family: harmony-sans-regular;
    padding: 23px;
    font-size: 14px;
    color: #36404c;
    background-color: #ffffff;
    
}
.header-reconcile > div:first-child
{
    border-top-left-radius: 5px;
    //border-right: solid 0.5px #e7ecf3;
    border-top-right-radius: 0;
}
.body-reconcile
{   
    border-radius: 5px;
    background-color: #f5f8fa;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
   // margin-bottom: 100px;
   // min-width: 1605px
}
.body-bank-statment{
    min-width: 1605px
}
.content-card-reconcile
{
    padding: 13px;
    padding-top: 23px;
    display: -webkit-inline-box;
    width: 100%;
    border-bottom: solid 1px #e7ecf3;
}
.title-header-reconcile
{
    width: 43%;
    height: auto;
    //display: inline-table;
    min-width: 480px;
}
.title-header-unreconcile
{
    width: 30%;
    height: auto;
    //display: inline-table;
    min-width: 480px;
}
.title-header-unreconcile2
{
    width: 30%;
    height: auto;
    //display: inline-table;
    min-width: 480px;
}
.title-header-action-reconcile
{

    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    padding-top: 5%;
    width: 14%;
    min-width: 130px;
}
.card-bank-unreconcile
{
    width: 30%;
    //height: auto;
    height: 100%;
    min-height: 200px;
    //display: inline-table;
    min-width: 480px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    border: solid 0.5px #b8c1cc;
}
.card-bank-reconcile
{
    width: 42%;
    //height: auto;
    height: 100%;
    min-height: 200px;
    //display: inline-table;
    min-width: 480px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    border: solid 0.5px #b8c1cc;
}
.bnt-action-cashbank{
    width: 74px;
    min-width: unset;
}
.bnt-save-reconcile{
    text-align: right;
    //padding-left: 10px;
    padding-right: 25px;
    position: relative;
    //top: 38px;
    //height: 20px;
    width: 100%;
    min-width: 110px;
}
.action-btn-reconcile
{

    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    padding-top: 75px;
    //top: 38px;
    height: 20px;
    width: 15%;
    min-width: 110px;
}
.tbl-card-bank-reconcile
{
    width: 100%;
    // min-height: 159px; nanti dibenerin lagi
    //height: 100%;
}
.tbl-card-bank-reconcile > tr > td,
.tbl-card-bank-reconcile > tbody >  tr > td
{
    padding: 13px 28px 4px 15px;
    font-size: 12px;
    font-family: harmony-sans-regular;
}
.tbl-card-bank-reconcile > tr:first-child > td
{
    font-family: harmony-sans-bold !important;
    color: #121212;
    font-size: 14px;
    //border-bottom: solid 1px #e7ecf3;
}

.tbl-card-bank-reconcile-last-child
{
    
    font-weight: bold;
    color: #121212;
   // border-top: solid 1px #e7ecf3;
    td{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}
.delete-card-bank-reconcile
{
  font-size: 12px;
  text-decoration: underline;
  color: #121212;
}
.tbl-number
{
    font-family:  harmony-mono-regular;
}

.list-tab-reconcile
{
    display: inline-block !important;
    overflow: hidden;
    white-space: nowrap;
    font-weight: normal;
    //text-overflow: ellipsis;
    //height: 100%;
}

.tab-content-reconcile
{
    list-style-type: none;
    display: inline-block;
    padding: 0;
    margin: 0;
}

.active-tab-reconcile
{
    color:#419aff !important;
    border-top: solid 2.6px #419aff;
    padding-top: 3px;
}
.tab-content-reconcile > li:hover > a
{
    color: #000;
}
.tab-content-reconcile > li{
    display: inline-block;
    padding-right: 10px;
    font-size: 14px;
    text-align: left;
    color: #b8c1cc;
    font-weight: bold;
    padding-right: 20px;
    padding-top: 4px;
    position: relative;
    //padding:20px 8px 10px 28px;
}
.tab-content-reconcile > li > a{
    color: #b8c1cc;
    font-weight: normal;
}
.icon-find-reconcile{
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top:9px;

}
.coa-reconcile
{
    min-width: 211px;
}
.contact-reconcile{
    min-width: 211px;
}
.tag-reconcile{
    min-width: 211px;
    width: 100%;
    height: 32px;
}
.desc-reconcile{
    min-width: 211px;
    
}
.padding-reconcile-content-bottom > td{
    padding-bottom:6px !important; 
}

.padding-reconcile-content-top > td{
    padding-top:0px !important; 
}
.v-top
{
    vertical-align:  top;
}
.pad-reconcile
{
    padding-left: 0;
    padding-right: 0;
}
.match-color{
    background-color: #d6f2ff
}
.match-line > tr:first-child > td
{

    //border-bottom: solid 1px #bddbe9;
}
.selected-row-partial-payment
{
    background-color: #ddd;
}

.table-rule1{
    width: 33%;
    font-size:14px;
    font-weight: normal;
    font-family: harmony-sans-bold;
    color:black;
}

.head-rule{
    font-family: harmony-sans-bold;
    font-size: 14px;
    color: black;
    font-weight: normal;
}
.text-code{
    text-align: right;
}