.thead-list {
    font-family: harmony-sans-bold;
    font-weight: normal;
    // min-width: 120px;
    padding:0px !important;
}

.thead-price {
    font-family: harmony-sans-bold;
    font-weight: normal;
    min-width: 165px;
    text-align: right;
    padding:0px !important;
}

.ellipsis--186{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 186px;
    display: inline-block;
}

.sorting-img {
    width: 8px;
    height: 8px;
    margin-bottom: 3px;
    margin-left: 4px;
}

.sorting-default {
    width: 15px;
    height: 15px;
    margin-bottom: 3px;
    margin-left: 4px;
}

.button-name {
    padding-left: 20px;
    text-align: left;
}
.type-nonsort {
    padding-top: 9px !important;
    padding-bottom: 8px !important;
    padding-left: 8px !important;
}
.name-nonsort {
    padding-top: 9px !important;
    padding-bottom: 8px !important;
    padding-left: 20px !important;
}
.status-nonsort {
    padding-top: 9px !important;
    padding-bottom: 8px !important;
    padding-left: 25px !important;
}

.button-thead {
    width: 100%;
    font-family: "harmony-sans-bold";
    height: 37px;
    border:none;
    box-shadow: none;
    border-radius:0px !important;
    padding: 0px !important;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 0.75;
    color: #242b33;
        &:focus{
            background-color: #e6ecf2;
            box-shadow: none;
        }
        &:hover{
            box-shadow: none;
            background-color: #f5f7fa;
        }
        > label
        {
            font-weight: normal;
        }
} 