.oneradio--parent{
  position: relative;
  label{
    font-size: 14px;
    color: #242c33;
    padding-left: 32px;
    cursor: pointer;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border: 1px solid #b8c3cc ;
      background-color: white;
      left: 0;
      top: 0px;
      border-radius: 50%;
      transition: 0.3s ease;
    }
    &:before{
      content: '';
      position: absolute;
      left: 3px;
      top: 3px;
      width: 10px;
      height: 10px;
      background-color: white ;
      border-radius: 50%;
      transition: 0.3s ease;
      z-index: 2;
    }
    &:hover{
      &:after{
        border: 1px solid #419aff;
      }
    }
  }
  input{
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  .oneradio--row
  {
    margin-bottom: 12px;
    height: 24px;
    line-height: 24px;
  }
  input:checked{
    & + label{
      &:after{
        border: 1px solid #419aff;
      }
      &:before{
        background-color: #419aff ;
      }
    }
    &:disabled{
      & + label{
        cursor: default;
        &:after{
          background-color: #e7ecf3;
          
          border-color: #b8c3cc;
        }
      }
    }
  }
  input:disabled{
    
    & + label{
      cursor: default;
      color: #b8c1cc;
      &:hover{
        &:after{
          border-color: inherit;
      

        }
      }
    }
  }
}
