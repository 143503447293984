@import './variables.scss';
// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */

html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $font-primary;
}

body {
   // overflow: hidden;
    font-family: $font-primary;
}


/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

*,
*::before,
*::after {
    box-sizing: inherit;
}


/**
 * Basic styles for links
 */

a {
    text-decoration: none;
    color: $colorBlue;
    font-family: $font-primary;
    &:hover,
    &:focus,
    &:active,
    &:visited {
        text-decoration: none;
    }
}

label {
    margin-bottom: 0;
    font-family: inherit;
}

button {
    font-family: $font-primary;
    &:hover,
    &:focus,
    &:active,
    &:visited {
        outline: none;
    }
}

h2 {
    font: 500 24px $font-primary;
}

h4 {
    font: 500 20px $font-primary;
}

h5 {
    font: 500 14px $font-primary;
}

p {
    font: 400 16px $font-primary;
    line-height: 20px;
}

.number {
    font: $font-monospace;
}

//additional
option {
    font-weight: 700;
}

//additional
.div-calendar .form-control {
    width: calc(100% - 1%);
    margin-left: 2px;
}

.list-menu-find {
    text-decoration: none;
    /* display: inline-block; */
    background-color: white;
    width: 230px;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    height: 200px;
}

.list-menu-find>li {
    padding: 8px;
}

.list-menu-find li>a {
    font: 400 16px "Harmonia Sans W01", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
    color: black;
    display: block;
    padding: 0;
    margin: 0;
    line-height: 30px;
}

.list-menu-find li>a:hover {
    background-color: #419AFF;
    border-radius: 3px;
    color: #FFF;
}

.hidden-list-menu-find {
    display: none;
}

.show-list-menu-find {
    display: inline-block;
}

.bm-burger-button>span {
    display: none;
}
.wd--375{
    width: 357px;
}
.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.long-text-250 {
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.long-text-300 {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.long-text-230 {
    width: 230px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.long-text-200 {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.long-text-100 {
    width: 100px;
    overflow: hidden;
    height: 50px;
    line-height: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.long-text-50 {
    max-width: 100px;
    overflow: hidden;
    height: 50px;
    line-height: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.long-text {
    //width: 100%!important;
    // display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
}

.long-text-195 {
    //width: 100%!important;
    display: inline-block;
    max-width: 195px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.long-text-153 {
    display: inline-block;
    max-width: 153px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.long-text-130 {
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.long-text-float-left {
    float: left;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.icon-100x100 {
    width: 100px;
    height: 100px
}
.icon-50x50 {
    width: 50px;
    height: 50px
}
.icon-40x40 {
    width: 40px;
    height: 40px
}

.icon-30x30 {
    width: 30px;
    height: 30px
}

.icon-20x20 {
    width: 20px;
    height: 20px;
    cursor:pointer
}

.icon-15x15 {
    width: 15px;
    height: 15px
}
.icon-14x14 {
    width: 14px;
    height: 14px
}

.icon-10x10 {
    width: 10px;
    height: 10px
}
.icon-10x9 {
    width: 10px;
    height: 8px;
}

.icon-25x25 {
    width: 25px;
    height: 25px
}
.icon-28x28 {
    width: 28px;
    height: 28px
}
.icon-24x24 {
    width: 24px;
    height: 24px;
    cursor:pointer
}
.icon-56x56 {
    width: 56px;
    height: 56px;
}

.icon-16x16 {
    width: 16px;
    height: 16px;
    cursor:pointer
}

.icon-18x18 {
    width: 18px;
    height: 18px;
    cursor:pointer
}

.icon-12x12-disable {
    width: 12px;
    height: 12px;
}

.icon-18x18-disable{
    width: 18px;
    height: 18px;
}

.pointer {
    cursor: pointer
}

.bold {
    font-weight:bold
}

.gray {
    color:#888888
}

.green {
    color:#4ddc7c
}

.normal-text {
    font-family: "Harmony-sans-regular";
    color: rgba(0, 0, 0, 0.65) !important;
}

.font-12-base {
    font-size: 12px;
    font-family: "harmony-sans-regular";
}

.font-12-bold {
    font-size: 12px;
    font-family: "harmony-sans-bold"
}

.font-14-base {
    font-size: 14px;
    font-family: "harmony-sans-regular";
}

.font-14-bold {
    font-size: 14px;
    font-family: "harmony-sans-bold"
}

.font-15-base {
    font-size: 15px;
    font-family: "harmony-sans-regular";
}

.font-15-bold {
    font-size: 15px;
    font-family: "harmony-sans-bold"
}

.font-16-base {
    font-size: 16px;
    font-family: "harmony-sans-regular";
}

.font-16-bold {
    font-size: 16px;
    font-family: "harmony-sans-bold" !important
}

.font-18-base {
    font-size: 18px;
    font-family: "harmony-sans-regular";
}

.font-18-bold {
    font-size: 18px;
    font-family: "harmony-sans-bold" !important
}
.font-20-base {
    font-size: 20px;
    font-family: "harmony-sans-regular";
}

.font-20-bold {
    font-size: 20px;
    font-family: "harmony-sans-bold" !important;
}

.color-black {
    color: #000;
}

.color-light-black{
    color: #242b33;
}

.color-gray {
    color: #121212;
}

.color-light-grey {
    color: #646464;
}

.color-grey-65 {
    color: #656565;
}

.color-oslo-grey {
    color: #7e8893;
}

.color-catskill-white {
    color: #f5f7fa;
}

.color-coral-red {
    color: #ff414a !important;
}

.ellipsis-text {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-nowrap {
    white-space: nowrap
}

.footer-copyright-app {
    font-size: 14px;
    width: 100%;
    position: fixed;
    bottom: 0px !important;
    background-color: #ffffff;
    font-family: harmony-sans-regular;
    height: 40px;
    text-align: right;
    z-index: 2;
    box-shadow: 0px 0px 10px -4px #888888;
}
