.flooter-selected{ 
  width: 60px;
  height: 35px;
  margin-left: 10px;
  margin-right: 10px;
 }
 .flooter-selected > .selected-item > .ant-select-selection--single
 {
     height: 30px;
 }
.flooter-inline
{
    display: inline-flex;
}
.right
{
    float: right;
}

.flooter--pagination > .ant-pagination-item, .flooter--pagination > .ant-pagination-prev, .flooter--pagination > .ant-pagination-next
 {
    min-width: 30px;
    height: 30px;
}
.flooter--pagination > .ant-pagination-item-active
{
    background-color: #419aff;
    border-color: #419aff;
}
.flooter--pagination > .ant-pagination-item-active:hover
{
    background-color: #2088ff;
    border-color: #2088ff;
}
.ant-pagination-item-active a {
    color: #f5f7fa;
    font-weight: bold;
}
.ant-pagination-item-active a:hover {
    color: #f5f7fa;
    font-weight: bold;
}
.pb--10
{
    padding-bottom: 10px;
}
.pb--12
{
    padding-bottom: 12px;
}

.pb--8
{
    padding-bottom: 8px;
}

.totalRow--number > .ant-select-selection > .ant-select-selection__rendered
{
    padding:  2px 9px 9px 1px;
}