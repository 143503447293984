@import '../base/variables.scss';
#app
{
    height: inherit;
    > div
    {
        height: 100%;
    }
    
}

.login--container {
    width: 100%;
    height: 100%;
    text-align: center;
    //height: -webkit-fill-available;
    font-family: "harmony-sans-regular";
    font-size: 16px;
    //min-height: 100vh;
    background-image: url("/assets/images/base.svg") 
}
.lbl-info
{
    position: sticky;
    margin-top: 140px;
     
    font-family: harmony-sans-regular;
    font-size: 14px;
    text-align: left;
    color: #242b33;
}
.content-login
{
    width: 360px;
    height: auto;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    position: relative;
    padding: 10px;
    top: 120px;
}
.content-connect-smartpoz
{
    width: 360px;
    height: auto;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    position: relative;
    padding: 10px;
    top: 10px;
}
.background-color-w
{
    background-color: #ffffff;
}
.main-content-login
{
    height: 100%;
    overflow: auto;
}
.icon-logo
{
    width: 40px;
    height: 40px;
}
.lbl-login
{
padding-top: 10px;
  height: 20px;
  font-family: harmony-sans-regular;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #419aff;
}
.lbl-hidden-pass
{
    font-size: 10px;
    color: #242b33;
    position: absolute;
    padding-top: 7px;
    right: 10px;
}

.footer-copyright{
    font-size: 14px;
    bottom: 20px;
    width: 100%;
    text-align: center;
}

.font-login{
    font-family: harmony-sans-bold;
    font-weight: normal;
}