.invc-add-row-1{
    tr > td:nth-child(6n-4){
        width: 18%;
    }
    tr > td:nth-child(6n-3){
        width: 18%;
    }
    tr > td:nth-child(6n-1),
    tr > td:nth-child(6n-2),
    tr > td:nth-child(6n-5){
        width: 18%
    }
}

.invc-add-row-2{

    margin-bottom: 12px !important;
    tr > td:nth-child(3n-1),
    tr > td:nth-child(3n-2){
        width: 18%
    }
}

.invc-repeat-label{
    padding: 6px 10px 0px 15px;
    font-size: 14px;
    font-family: "harmony-sans-regular";
}

.invc-repeat-field{
    padding: 6px 10px 0px 0px;
    font-size: 14px;
    font-family: "harmony-sans-regular";    
}

.invc-modal-label{
    height: 16px;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: #121212;
    margin-bottom: 8px;
    font-family: 'harmony-sans-bold';
}

.invc-modal-content{
    padding: 10px 23px 15px 17px;
}

.repeatinv-td-select{
    width: 196px;
    vertical-align: top !important;
}

.inc-tab-row{
    margin-left: 0px;
}

.share-inv{
    background-color: #e6ecf2;
    padding:40px;
    min-width: 1045px;
}

.icon-download{
    width: 26px;
    height: 26px;
    position: relative;
    margin-left: -11px;
    margin-top: -2px;
}

.span-download {
    margin-top: 10px;
    margin-left: 23px;
    display: inline-table;
}

.share-inv-tab{
    height: 80px;
    margin:0px auto;
    width:1045px;
}
.share-content{
    min-height:600px;
    height:1040px;
    width:780px;
    margin:auto;
    padding: 56px 26px 45px 26px;
    background-color: white;
    border-radius:5px;
}
.load-share{
    height:600px !important;
}
.share-container{
    height:100%;
    overflow: auto;
    // min-width: 1000px;;
}
.middle{
    vertical-align: middle;
}

.share-amount{
    font-size:20px;
    color:#419aff;
    font-family: harmony-sans-bold;
}

.share-title{
    font-size: 20px;
    font-family: harmony-sans-bold;
}

.share-tab {
    width:30%;
    padding-top: 15px;
}

.share-span {
    line-height: 2;
}

.btn-download{
    padding-top: 4px !important;
}

.loader-share{
    text-align: center;
    padding-top: 30%;
}

.frame-share{
    width: 100%;
    height: 1040px;
    border:none;
}

.share-template-type{
    margin-top: 15px; 
    margin-left: 30px;
}

.share-template-select{
    width: 120px;
    margin-top: 5px;
}

.icon-send-email{
    margin-top: -2px;
    margin-right:10px;
    margin-left: -15px;
}

.header-calculate-allocate {
    border: 1.5px dashed #b8c1cc;
    background-color: #f5faff;
    border-radius: 5px;
    display: grid;
    width: 400px;
    padding: 20px 40px 20px 40px;
}

.header-calculate-allocate-id {
    border: 1.5px dashed #b8c1cc;
    background-color: #f5faff;
    border-radius: 5px;
    display: grid;
    width: 500px;
    padding: 20px 40px 20px 40px;
}


.text-total-allocate {
  font-size: 14px;
  color: #419aff;
  font-family: "harmony-sans-regular"; 
}

.button-transfer {
    background-color: #2196F3;
    outline: none;

    height: 32px;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 6px 19px 11px 19px;
    font-family: "harmony-sans-bold";
}


.button-mandiri {
    background-color: #d8d8d8;
    outline: none;

    height: 32px;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 6px 19px 11px 19px;
    font-family: "harmony-sans-bold";
}

.dropdown {
    display: inline-block;
}

.dropdown-content {
    border-radius: 4px;
    display: none;
    position: absolute;
    background-color: #fff;
    font-weight: normal;
    min-width: 160px;
    z-index: 1;
    margin-top: 1px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-top: 5px;
}

.dropdown-content a {
    padding: 7px 8px;
    text-decoration: none;
    display: block;
    height: 35px;
    font-size: 14px;
    font-family: "harmony-sans-regular";
    text-align: left;
    white-space: nowrap;
    width: 100%;
}

.dropdown-content #mandiri-regis-link {
    padding: 7px 8px;
    text-decoration: none;
    display: block;
    font-size: 14px;
    font-family: "harmony-sans-regular";
    text-align: left;
    white-space: nowrap;
    width: 100%;
    height: auto;
    padding: 7px 20px;
}

.dropdown-content a:hover {
    background-color: #ecf6fd;
    color: #108ee9 !important;
}

.dropdown-content .container-dropdown:hover {
    background-color: #ecf6fd;
    color: #108ee9 !important;
}

// .dropdown:hover .dropdown-content {
//     display: block;
// }

.button-transfer:hover, .dropdown:hover .button-transfer {
    background-color: #0b7dda;
}

.button-mandiri:hover, .dropdown:hover .button-mandiri {
    background-color: #c9c9c9;
}

.show { 
    display: block; 
}