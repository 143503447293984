.icon-connect {
    width: 16px;
    height: 16px;
    margin-top :-5px;
    margin-left: 4px;
}

.icon-button-connect {
    width: 13px;
    margin-right: 10px;
    margin-bottom: 4px;
}

.warning-coa {
    background-color: #fffde8;
    padding: 15px;
}

.button-connect{
    width: auto;
    float: right;
    margin-top:20px;
    font-family: harmony-sans-regular;
}

.warning-coa{
    background-color: #e6ecf2;
    padding: 10px;
    padding-left:34px;
}

.icon-lock-warn {
    width: 9px;
    margin-right: 7px;
    margin-top: -3px;
}

.coa-child-list {
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.child-show-more{
    color: #419AFF;
}