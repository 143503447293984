// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;






/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;



/// Colors variables
/// Base colors that will be used
$colorBlack: #121212 !default;
$darkerGrey: #36414D;
$darkGrey: #242B33;
$muteGrey: #7E8893;
$lightGrey: #B8C1CC;
$color-hawkes-blue: #E3F3FD;
$color-dark-ebony-clay: #192B46;

$white: #FFF;
$darkWhite: #F5f7FA;
$darkerWhite: #E6ECF2;

$colorGreen: #4DDB7C;

$colorBlue: #419AFF;
$blueDarken: #4188D9;
$darkBlue: #204080;

$colorRed: #FF414A;

$colorYellow: #FFDB41;

@font-face{
    font-family: harmony-sans-semibold;
    src:url(/assets/fonts/harmony-sanssemibold.ttf);
}
@font-face{
    font-family: harmony-sans-bold;
    src:url(/assets/fonts/Harmony-Regular-Bold.ttf);
}

@font-face{
    font-family: harmony-sans-regular;
    src:url(/assets/fonts/harmony-sans-regular.ttf);
}

@font-face{
    font-family: harmony-mono-regular;
    src:url(/assets/fonts/HarmoniaSans-Mono-Regular.ttf);
}



/// font-family variables
/// base font style to be use
$font-primary: 'harmony-sans-regular';
$font-monospace: 'harmony-sans-semibold', 'Courier New', monospace !default;
