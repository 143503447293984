.ant-card {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.ant-card-shadow {
    -webkit-box-shadow: 0 0.5px 1px -1px rgba(0,0,0,.16),0 1.5px 3px 0 rgba(0,0,0,.12),0 2px 6px 2px rgba(0,0,0,.09);
    box-shadow: 0 0.5px 1px -1px rgba(0,0,0,.16),0 1.5px 3px 0 rgba(0,0,0,.12),0 2px 6px 2px rgba(0,0,0,.09);
}

.ant-card-rtl {
    direction: rtl;
}

.ant-card-hoverable {
    cursor: default !important;
}

.ant-card-hoverable:hover {
    border-color: transparent;
    -webkit-box-shadow: 0 2px 5px -5px rgba(0,0,0,.16),0 5px 10px 0 rgba(0,0,0,.12),0 7px 14px 7px rgba(0,0,0,.12);
    box-shadow: 0 2px 5px -5px rgba(0,0,0,.16),0 5px 10px 0 rgba(0,0,0,.12),0 7px 14px 7px rgba(0,0,0,.12);
    .ant-image-mask {
        background-color: rgba(0,0,0,.25);
    }
}

.ant-card-bordered {
    border: 1px solid #f0f0f0;
}

.ant-card-head {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    background: 0 0;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0
}

.ant-card-head::before {
    display: table;
    content: ""
}

.ant-card-head::after {
    display: table;
    clear: both;
    content: ""
}

.ant-card-head-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ant-card-head-title {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-card-head .ant-tabs {
    clear: both;
    margin-bottom: -17px;
    color: rgba(0,0,0,.65);
    font-weight: 400;
    font-size: 14px;
}

.ant-card-head .ant-tabs-bar {
    border-bottom: 1px solid #f0f0f0;
}

.ant-card-extra {
    float: right;
    margin-left: auto;
    padding: 16px 0;
    color: rgba(0,0,0,.65);
    font-weight: 400;
    font-size: 14px;
}

.ant-card-rtl .ant-card-extra {
    margin-right: auto;
    margin-left: 0;
}

.ant-card-body {
    padding: 15px;
}

.ant-card-body::before {
    display: table;
    content: ""
}

.ant-card-body::after {
    display: table;
    clear: both;
    content: "";
}

.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
    margin: -1px 0 0 -1px;
    padding: 0;
}

.ant-card-grid {
    float: left;
    width: 33.33%;
    padding: 24px;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 1px 0 0 0 #f0f0f0,0 1px 0 0 #f0f0f0,1px 1px 0 0 #f0f0f0,1px 0 0 0 #f0f0f0 inset,0 1px 0 0 #f0f0f0 inset;
    box-shadow: 1px 0 0 0 #f0f0f0,0 1px 0 0 #f0f0f0,1px 1px 0 0 #f0f0f0,1px 0 0 0 #f0f0f0 inset,0 1px 0 0 #f0f0f0 inset;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.ant-card-rtl .ant-card-grid {
    float: right;
}

.ant-card-grid-hoverable:hover {
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0 1px 2px -2px rgba(0,0,0,.16),0 3px 6px 0 rgba(0,0,0,.12),0 5px 12px 4px rgba(0,0,0,.09);
    box-shadow: 0 1px 2px -2px rgba(0,0,0,.16),0 3px 6px 0 rgba(0,0,0,.12),0 5px 12px 4px rgba(0,0,0,.09);
}

.ant-card-contain-tabs>.ant-card-head .ant-card-head-title {
    min-height: 32px;
    padding-bottom: 0;
}

.ant-card-contain-tabs>.ant-card-head .ant-card-extra {
    padding-bottom: 0;
}

.ant-card-cover {
    margin-right: -1px;
    margin-left: -1px;
}

.ant-card-cover>* {
    display: block;
    width: 100%;
}

.ant-card-cover img {
    border-radius: 2px 2px 0 0;
}

.ant-card-actions {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fafafa;
    border-top: 1px solid #f0f0f0;
}

.ant-card-actions::before {
    display: table;
    content: "";
}

.ant-card-actions::after {
    display: table;
    clear: both;
    content: "";
}

.ant-card-actions>li {
    float: left;
    margin: 12px 0;
    color: rgba(0,0,0,.45);
    text-align: center;
}

.ant-card-rtl .ant-card-actions>li {
    float: right;
}

.ant-card-actions>li>span {
    position: relative;
    display: block;
    min-width: 32px;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
}

.ant-card-actions>li>span:hover {
    color: #1890ff;
    -webkit-transition: color .3s;
    transition: color .3s;
}

.ant-card-actions>li>span a:not(.ant-btn),.ant-card-actions>li>span>.anticon {
    display: inline-block;
    width: 100%;
    color: rgba(0,0,0,.45);
    line-height: 22px;
    -webkit-transition: color .3s;
    transition: color .3s;
}

.ant-card-actions>li>span a:not(.ant-btn):hover,.ant-card-actions>li>span>.anticon:hover {
    color: #1890ff;
}

.ant-card-actions>li>span>.anticon {
    font-size: 16px;
    line-height: 22px;
}

.ant-card-actions>li:not(:last-child) {
    border-right: 1px solid #f0f0f0
}

.ant-card-type-inner .ant-card-head {
    padding: 0 24px;
    background: #fafafa;
}

.ant-card-type-inner .ant-card-head-title {
    padding: 12px 0;
    font-size: 14px;
}

.ant-card-type-inner .ant-card-body {
    padding: 16px 24px;
}

.ant-card-type-inner .ant-card-extra {
    padding: 13.5px 0;
}

.ant-card-meta {
    margin: -4px 0;
}

.ant-card-meta::before {
    display: table;
    content: "";
}

.ant-card-meta::after {
    display: table;
    clear: both;
    content: "";
}

.ant-card-meta-avatar {
    float: left;
    padding-right: 16px;
}

.ant-card-rtl .ant-card-meta-avatar {
    float: right;
    padding-right: 0;
    padding-left: 16px;
}

.ant-card-meta-detail {
    overflow: hidden;
}

.ant-card-meta-detail>div:not(:last-child) {
    margin-bottom: 8px;
}

.ant-card-meta-title {
    overflow: hidden;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-card-meta-description {
    color: rgba(0,0,0,.45);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; 
    line-height: 20px; /* fallback */
    max-height: 40px; /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    height: 45px;
}

.ant-card-loading {
    overflow: hidden;
}

.ant-card-loading .ant-card-body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-card-loading-content p {
    margin: 0;
}

.ant-card-loading-block {
    height: 14px;
    margin: 4px 0;
    background: -webkit-gradient(linear,left top,right top,from(rgba(207,216,220,0.2)),color-stop(rgba(207,216,220,0.4)),to(rgba(207,216,220,0.2)));
    background: linear-gradient(90deg,rgba(207,216,220,0.2),rgba(207,216,220,0.4),rgba(207,216,220,0.2));
    background-size: 600% 600%;
    border-radius: 2px;
    -webkit-animation: card-loading 1.4s ease infinite;
    animation: card-loading 1.4s ease infinite;
}

@-webkit-keyframes card-loading {
    0%,100% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }
}

@keyframes card-loading {
    0%,100% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }
}

.ant-card-small>.ant-card-head {
    min-height: 36px;
    padding: 0 12px;
    font-size: 14px;
}

.ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
    padding: 8px 0;
}

.ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-extra {
    padding: 8px 0;
    font-size: 14px;
}

.ant-card-small>.ant-card-body {
    padding: 12px;
}

.ant-image-mask {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: all .25s ease;
}