.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    line-height: 22px;
    padding: 8px 0 8px 32px;
    color: rgba(0,0,0,.85);
    cursor: pointer;
    position: relative;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.border-coll > .ant-collapse-content{
    border : black solid !important;
}

.font-style > .ant-collapse-header{
    font-size: 16px !important;
    font-family: 'harmonia-sans-regular' !important;
    font-weight: bold !important;
}