.picker-custome > .ant-input{
  height:32px;
  border: 1px solid #b8c1cc;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin: 4px 0px 0px 0px
}
.picker-custome > div > .ant-calendar-picker-icon
{
  display: none
}
.picker-custome > div > input 
{
  height: 32px; 
  font-size: 14px;
  font-family: "harmony-sans-regular";
  padding-left: 10px;
  cursor: default;
  color: #000000;
  //line-height: 60px !important;
  font-weight: normal !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px #b8c1cc;
}

.picker-error > div > input{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23) !important;
  border: solid 0.5px #f02d37 !important;
}

.anticon anticon-cross-circle 
{
  display: none;
}
.ant-calendar-picker-clear 
{
  display: none;
}
.ant-calendar-picker-icon
{
  display: none;
}
.ant-calendar-picker-input ant-input:focus
{
  
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6);
    border: solid 0.5px #419aff;
}
.ant-calendar-picker-container
{
  
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}
.input-error-picker  > div > .ant-input
{
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23);
    border: solid 0.5px #f02d37;
}
.input-error-picker-datepicker-new
{
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23);
    border: solid 0.5px #f02d37;
}
.ant-input-disabled
{
    background-color: #e7ecf3 !important; 
    color:  #7e8893 !important;
    border: none !important;
}

.input-error-range > .ant-input {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23);
  border: solid 0.5px #f02d37;
}