.cn-purchase-add-row-1{
    tr > td:nth-child(5n-1),
    tr > td:nth-child(5n-3),
    tr > td:nth-child(5n-2),
    tr > td:nth-child(5n-4){
        width: 19%
    }
}

.cn-purchase-add-row-2{
    tr > td:nth-child(3n-1),
    tr > td:nth-child(3n-2){
        width: 18%
    }
}