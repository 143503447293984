.fileupload {
    position: absolute;
    visibility: hidden;
}
.fileuploadBank{
    padding-left: 20px;
    visibility: hidden;
}
.fileUploadName{
    position: absolute;
    width: 200px;
    left: 145px;
    top: 22px;
    font-family: harmony-sans-regular;
}
.fileUploadName2{
    position: absolute;
    width: auto;
    left: 177px;
    top: 22px;
    font-family: harmony-sans-regular;
}
.fileUploadName3{
    position: absolute;
    width: auto;
    left: 230px;
    top: 22px;
    font-family: harmony-sans-regular;
}
.btn-upload2 {
    bottom: 28px;
    position: relative;
    left: -10px;
}
.dis-none {
    display: none !important;
}
.btn-upload {
    height: 28px;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 0.75;
    text-align: center;
    padding: 10px 19px 11px 19px;
    margin: 6px 0px;
    font-family: harmony-sans-regular;
    background-color: #419aff;
    font-weight: normal;
    top: 10px;
    //left: 20px;
    left: 29px;
    box-shadow: 0 2px 4px 0 rgba(65, 154, 255, 0.5);
    &:focus {
        background-color: #1d7eec;
        box-shadow: 0 2px 4px 0 rgba(65, 154, 255, 0.62)
    }
    &:hover {
        background-color: #5aa6fe;
    }
}

.upload-name {
    width: 100%;
}

.col-upload-file {
    padding-left: 12px;
    padding-top: 15px;
}

.col-viewupload-file {
    padding-left: 12px;
}

.icon-clear-fille {
    width: 7px;
    height: 7px;
}

.footer-upload-attachment{
    
        display: flow-root;
        width: 100%;
    }

.cancel-button {
    color: #7e8893;
    // text-decoration: underline;
}
.checkbox-error {
    margin: 0px 10px;
    margin-bottom: -3px;
}
.cancel-button-inventory {
    position: relative;
    right: 92px;
}

.icon-attachment{
    margin-top:-10px;
    margin-bottom:-5px;
    margin-left:-3px;
}
.icon-attachment2 {
    width: 12px;
    margin-left: 5px;
    margin-top: -3px
}
.icon-attachment3{
    margin-right:6px;
    margin-top:-3px;
}


.btn-attachment {
    width: 202px !important;
    padding:0px
}

.btn-attachment-cashbank {
    width: 212px !important;
    padding:0px
}

.li-cashbank-import{
    overflow: unset !important;
    white-space: unset !important;
    height:auto !important;
}

.icon-state-file {
    width: 42px;
    margin: 30px 70px;
}

.div-state-file{
    padding:30px 73px;
    text-align:center;
}

.button-menu-file {
    display: inline;
    border-bottom: none !important;
    color: black !important;
}

.ant-tooltip-inner {
    max-width: unset !important;
}

.btn-wording {
    float:right;
}

.ant-menu-submenu-title{
    height:34px;
}