@import '../base/variables.scss';
.table-responsive {
    //overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff; // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px
    // border: solid 0.5px #b8c1cc;
}

.table-responsive-no-scroll {
    border-top: solid 0.5px #ddd;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
    overflow-x: inherit !important
}

.table-view {
    border-radius: 5px;
    background-color: #ffffff; //border: solid 0.5px #b8c1cc;
    margin: 10px 10px 25px 10px;
    overflow: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.min1100{
    min-width:1100px;
    width:100%;
}
.table-view-no-scroll{
    border-radius: 5px;
    background-color: #ffffff; //border: solid 0.5px #b8c1cc;
    margin: 10px 10px 25px 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.table-view-no-margin {
    border-radius: 5px;
    background-color: #ffffff; //border: solid 0.5px #b8c1cc;
    overflow: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.table-hover>tbody>tr:hover {
    background-color: #e7ecf3;
}

.content--table>thead>tr>th {
    border-bottom: none;
}

.display-flex {
    display: inline-flex;
}

.dynamic-header-table {
    background-color: #ffffff; //border: solid 0.5px #b8c1cc;
}

.table--title-header {
    //padding-left: 24px !important;
}

.table--header-right{
    text-align: right;
    padding-right: 16px !important;
    width: 252px;
}

.wdp-10 {
    width: 10%;
}

.wd-20 {
    width: 20%;
}
.wd-22 {
    width: 22%;
}
.wd-75 {
    width:75%;
}
.wd-25 {
    width: 25%;
}
.wd-17 {
    width: 17%;
}

.wd-15 {
    width: 15%;
}


.wd-30 {
    width: 30%;
}

.wd-40 {
    width: 40% !important;
}
.wd--50{
    width: 50px !important;
}
.wd--45 {
    width: 45% !important;
}

.wd-100 {
    width: 100% !important;
}

.wd-130-mx {
    width: 130px;
    max-width: 130px !important;
}
.table-fixed{
    table-layout: fixed;
}
.col--width--19prc {
    width: 15% !important;
}
.col--width--14prc {
    width: 13% !important;
}
.col--width--20prc {
    width: 20%;
}
.col--width--21prc {
    width: 21%;
}
.col--width--23prc {
    width: 23%;
}
.col--width--24prc {
    width: 24%!important;
}

.col--width--25prc {
    width: 28%;
}
.col--width--29prc {
    width: 29%;
}
.col--width--50prc {
    width: 50%;
}
.col--width--66prc {
    width: 66%;
}
.col--width--15prc {
    width: 15%;
}
.col--width--13prc {
    width: 13%;
}
.col--width--10prc {
    width: 10%;
}
.col--width--12prc {
    width: 12%;
}
.col--width--5prc {
    width: 5%;
}
.col--width--100prc {
    width: 100%;
}
.wd--20{
    width: 20px;
}
.wd--50{
    width: 50px !important;
}
.wd--75{
    width: 75px !important;
}
.wd--83{
    width: 83px !important;
}
.wd--90{
    width: 90px !important
}
.wd--97{
    width: 97px !important
}
.wd--132
{
    width: 132px !important;
}
.wd--140
{
    width: 140px !important;
}
.wd--145
{
    width: 145px !important;
}
.wd--150
{
    width: 150px !important;
}
.wd--170
{
    width: 170px !important;
}
.wd--175
{
    width: 175px !important;
}

.wd--185{
    width: 185px !important;
}
.wd--187{
    width: 187px !important;
}
.wd--196{
    width: 196px !important;
}
.wd--200
{
    width: 200px !important;
}
.wd--210
{
    width: 210px !important;
}
.wd--203
{
    width: 203px !important;
}
.wd--205
{
    width: 205px !important;
}
.wd--215
{
    width: 215px !important;
}

.wd--220
{
    width: 220px;
}
 
.wd--227
{
    width: 227px !important;
}
.wd--240
{
    width: 240px !important;
}
.wd--290{
    width: 290px !important;
}
.wd--300{
    width: 300px !important;
}
.wd--305{
    width: 305px !important;
}
.wd--310{
    width: 310px !important;
}
.wd--330{
    width: 330px !important;
}
.wd--335{
    width: 335px !important;
}
.wd--350{
    width: 350px !important;
}
.wd--390 {
    width: 390px !important;
  }
.wd--321{
    width: 321px !important;
}

.wd--400
{
    width: 400px !important;
}

.wd--423
{
    width: 423px !important;
}
.wd--500
{
    width: 500px !important;
}
.wd--427
{
    width: 427px !important;
}
.wd--800
{
    width: 800px !important;
}
.wd--850
{
    width: 850px !important;
}
.wd--259{
    width: 259px;
}

.wd--270{
    width: 270px;
}

.wd--1212{
    width: 1190px;
}

.wd--1300{
    max-width: 1260px;
}

.mx-250{
    max-width:250px;
}
.mx-130{
    max-width:130px;
}
.mx-200{
    max-width:200px;
}


.wd---220{
    min-width: 220px; 
}
.wd---240{
    min-width: 240px; 
}
.wd---259{
    min-width: 259px;
}
.wd---1000{
    min-width: 1000px;
}
.wd---1100{
    min-width: 1024px;
}
.wd---1200{
    min-width: 1300px;
}
.wd---1248{
    min-width: 1324px;
}
.wd---1400{
    min-width: 1440px;
}
.wd---1151
{
    min-width: 1151px;
}
.wd---1100{
    min-width: 1120px;
}

.wd-05,
.col--width--5prc {
    width: 5%;
}
.ellipsis--400{
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    max-width:400px;
    display:inline-block;
}
.ellipsis--50{
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    max-width:50%;
    display:inline-block;
}
.wd-10 {
    width: 10%;
}

.wd-300  {
    width: 300px !important;
}

.mx--350{
    max-width:350px;
}

.col--width--2prc {
    width: 2%;
}

.th-min-witdh-200 {
    min-width: 200px;
}

.col--width--35prc {
    width: 35%;
}
.col--width--30prc {
    width: 30%;
}
.col--35prc {
    width: 35% !important;
}
.col--width--38prc {
    width: 38%;
}
.wd-findmatch {
    width: 155px;
    max-width: 155px;
    min-width: 155px;
}
.wd-spentreceive {
    width: 165px;
    max-width: 165px;
    min-width: 165px;
}
.wd-checkbox {
    width: 35px;
    min-width: 35px;
}
.wd-datefindmatch {
    width: 100px;
    min-width: 100px;
}
.dynamic-header-table>tr>th {
    padding-top: 9px;
    padding-bottom: 8px;
}

.icon-forbidden
{
    width: 80px;
    height: 80px;
}

.content-container {
    background-color: $darkerWhite;
    color: black;
    overflow-y: auto;
    //overflow-x: hidden;
    overflow-x: auto;
    padding-left: 200px;
    height: -moz-calc(100% - 90px);
    /* WebKit */
    height: -webkit-calc(100% - 90px);
    /* Opera */
    height: -o-calc(100% - 90px);
    /* Standard */
    height: calc(100% - 90px);
    
    font-size: 16px;
    text-align: left;
    color: #121212;
    padding-bottom: 80px;
}

.content--header {
    display: block;
    background-color: $white;
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    box-shadow: inset 0 -1px 0 0 $darkerWhite;
    padding-bottom: 10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.content--header-breadcrumb {
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.content--header-breadcrumb>.active {
    color: $colorBlack;
}

.content--title {
    display: inline-block;
    margin: 0;
    margin-bottom: 4px;
    font: 700 20px $font-primary;
}

.content--title>a {
    font-weight: 400;
    color: $colorBlack;
    &:hover {
        color: $colorBlue;
    }
}

.content--table-action {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.content--table-action>li {
    display: inline-block;
    padding: 0;
    margin-right: 5px;
    font: 400 16px $font-primary;
    &:last-child {
        margin-right: 0;
    }
}

.content--table-action>li>a {
    color: $colorBlue;
}

.content--header-display {
    font: 400 14px $font-primary;
    color: $muteGrey;
}

.content--view{
    width:5px;
    height:100%;
}

.header--display {
    font-weight: 500;
    color: $colorBlack;
}

.header--display-opt {
    font: 400 12px $font-primary;
    color: $colorBlue;
    -webkit-appearance: none;
    background-color: transparent;
    margin-left: 5px;
    width: 105px;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
}

.content--filter-input {
    width: 230px;
    height: 42px;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 0 !important;
    &:nth-child(2) {
        margin-right: 0;
    }
}

.content--filter-date {
    display: inline-block;
    background-color: $white;
    box-shadow: 0 -1px 10px .25px $muteGrey;
    z-index: 5;
    padding: 10px;
}

.filter--calendar-container {
    position: absolute;
    right: 16px;
    width: 560px;
}

.content--nav {
    display: block;
    padding: 0 16px;
    background-color: $white;
    width: 100%;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
}

.content--nav-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font: 500 16px $font-primary;
}

.content--nav-list>li {
    display: inline-block;
    margin-right: 12px;
    text-align: center;
}

.content--nav-list>li>a {
    padding: 12px;
    display: block;
    color: $muteGrey;
    &:hover {
        color: $darkGrey;
        box-shadow: inset 0 -2px 0 0 $colorBlue;
    }
}

.content--nav-list>li>a.active {
    color: $darkGrey;
    box-shadow: inset 0 -2px 0 0 $colorBlue;
}

.content--search-bar {
    padding: 8px 16px;
    background-color: $white;
    width: 100%;
}

.search-bar {
    width: 50%;
    font: 400 16px $font-primary;
    background-image: url('/assets/images/search.png');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px !important;
}

.search-bar-static {
    padding: 12px 0 !important;
    padding-left: 30px !important;
}

.table-filter {
    font: 400 16px $font-primary;
    -webkit-appearance: none; //border: none; /* additional */
    background-color: transparent;
    padding: 6px; //  color: $muteGrey;/* additional */
    background-image: url('/assets/images/cheveron-down.png');
    background-repeat: no-repeat;
    background-position: right center;
    width: 300px; //additional
}

// TABLE
.content--table {
    width: 100%;
    background-color: $white; //border: 1px solid $lightGrey;
    // border-left: none;
    // border-right: none;
    // border: solid 0.5px #ddd;
    margin-bottom: 0;
    border-radius: 5px;
    margin-right: 50px; //border: solid 0.5px #b8c1cc;
}

.content--table-header {
    background-color: $darkerWhite;
}

.content--table-header>th {
    padding: 4px 24px !important;
    font: 500 16px $font-primary;
    color: $darkGrey;
    box-shadow: 0 -1px 0 0 #B8C1CC, 0 1px 0 0 #B8C1CC;
}

.content--nested-table>td {
    padding: 0 !important;
}

.content--table-static {
    background-color: $darkWhite;
}

.table--title-icon {
    //padding-left: 24px !important;
}

.tax--width {
    width: 100px;
}

.cek {
    margin-left: 1000px;
}

.cek {
    margin-right: 0;
}

.diable-toggle {
    background-color: #e7ecf3 !important;
    cursor: default;
}

.table--static-header {
    color: $colorBlack;
    background-color: $white;
}

.content--table-body:nth-child(2n) {
    background-color: $darkWhite;
}

.content--table-body>td {
    font-family: $font-primary;
    font-size: 14px;
    //   padding: 8px 24px !important;
    //  padding: 8px 4px  8px 2px!important;
}

.table--header-icon {
    padding-bottom: 7px !important;
}

.content--table-static>.content--table-body {
    background-color: $white;
}

.table--icon {
    width: 20px;
    height: 20px;
}

.cursor-default {
    cursor: default !important
}

.table--number-header {
    text-align: right;
}

.table--title {
    display: block;
    font: 500 16px $font-primary;
    color: $colorBlack;
}

.table--title-date {
    color: $muteGrey;
    font-weight: 500;
    margin-left: 5px;
}

.table--bold {
    font-weight: bold !important;
}

.table--number {
    font: 400 16px ;
    font-family: 'harmony-mono-regular' !important;
    text-align: right;
}

.font--weight--normal{
    font-weight: normal;
}

.font--family{
    font-family: 'harmony-sans-regular' !important;
}
.text--table{
    font-family: 'harmony-sans-regular' !important;
    font-weight: normal;
    font-size: 16px;
}

.table--right {
    text-align: right;
}

.table--blue {
    color: $colorBlue;
}

.table--mono {
    font: 400 16px $font-monospace !important;
}

.table--check {
    text-align: center;
    width: 5%;
}

.table--key {
    text-align: center;
    width: 5%;
    margin-left: 1px;

}
.table--code {
    color: $muteGrey;
    font-weight: 500;
    width: 15%;
}

.table--name {
    font-size: 16px;
    text-align: left;
    color: #419aff;
}

.table--nav {
    padding: 8px 16px;
}

.table--nav-jump {
    display: inline-block;
}

.table--nav-label {
    font: 400 14px $font-primary;
    margin-right: 8px;
}

.table--nav-input {
    border: 1px solid $lightGrey;
    border-radius: 3px;
    text-align: center;
    font: 400 14px $font-primary;
    margin-right: 8px;
    padding: 11px;
    width: 42px;
}

.table--nav-btn {
    border: 1px solid $lightGrey;
    border-radius: 3px;
    font: 700 14px $font-primary;
    color: $darkGrey;
    background-color: $darkWhite;
    padding: 11px;
}

.table--nav-pagination {
    display: inline-block;
    float: right;
}

.table--nav-pagination>.pagination {
    margin: 0;
}

.table--nav-pages>li>a {
    margin-right: 8px;
    border-radius: 3px;
    font: 400 14px $font-primary;
    border: 1px solid $lightGrey;
    color: $colorBlue;
    padding: 8px 16px;
    &:hover {
        color: $muteGrey;
    }
}

.table--nav-pages>li>a.active {
    color: $muteGrey;
    background-color: $darkWhite;
}

.table--nav-pages>li>.pagination--page-more {
    pointer-events: none;
    background-color: transparent;
    border: none;
    font: 400 14px $font-primary;
    color: $darkerGrey;
}

.table--nav-pages>li>.pagination--prevnext {
    font: 700 14px $font-primary;
    color: $white;
    background-color: $colorBlue;
}


/* FOOTER */

.content--footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 240px);
    background-color: $darkerGrey;
    padding: 16px 12px;
    z-index: 5;
    color: $white;
}

.content--table-footer {
    width: 100%;
    background-color: transparent;
}

.content--footer-title {
    font: 500 16px $font-primary;
    width: 60%;
}

.content--footer-number {
    font: 400 16px $font-monospace;
}

//NESTED TABLE
.table--title-inner {
    cursor: pointer;
    display: block;
    padding: 8px 24px;
    box-shadow: 0 1px 0 0 #B8C1CC;
}

.table--border {
    box-shadow: 0 0 0 1px #B8C1CC;
}

.table--title-inner-journal {
    cursor: pointer;
    display: block;
    padding: 8px 24px;
}

.table--inner-container {
    padding-left: 8px;
}

.table--inner {
    width: 100%;
}

.table--inner-row {
    box-shadow: 0 1px 0 0 #B8C1CC;
}

.table--inner-row>td {
    padding: 8px 24px;
}

.table--inner-row>.table--name {
    font: 400 16px $font-primary;
}

// FOR EMPTY TABLE
.empty--table-header {
    display: table;
    width: 100%;
}

.empty--table-container {
    display: table;
    position: relative;
    width: 100%;
}

.empty--table-container:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $darkWhite;
    z-index: 3;
    opacity: 0.85;
}

.empty--table-body {
    width: 20%;
}

.empty--state-container {
    position: absolute;
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-10%);
    -moz-transform: translateX(-50%) translateY(-10%);
    transform: translateX(-50%) translateY(-10%);
    z-index: 4;
    text-align: center;
    max-width: 480px;
}

.empty--state-illus {
    width: 160px;
    height: 160px;
    background-color: $white;
    text-align: center;
    border-radius: 3px;
    border: 2px solid $colorBlue;
    font: 400 14px $font-primary;
    color: $lightGrey;
    line-height: 160px;
    margin: 0 auto;
}

.empty--state-title {
    display: block;
    font: 500 24px $font-primary;
    color: $colorBlue;
    text-align: center;
    margin-top: 32px;
}

.empty--state-desc {
    display: block;
    margin-top: 16px;
    font: 500 16px $font-primary;
    color: $darkGrey;
    line-height: 22px;
    word-wrap: normal;
    margin-bottom: 32px;
}

.filter-date {
    float: right;
    width: 200px;
    border: none;
    padding: 1px;
}

.form--calendar-search {
    float: right;
    padding: 3px;
}

.action-button {
    background-color: #FFF;
}

.back-to-top--icon {
    width: 30px!important;
    height: 40px!important;
}

.search-by {
    color: #419aff;
    width: auto;
    height: 35px;
    // margin-right: 40px;
}

.col-input-search {
    height: 32px;
    width: 196px;
}

.side-top-search {
    float: left;
    margin-top: 4px;
    margin-left: 15px;
    display: -webkit-box;
}

.side-top-search-repeating {
    float: left;
    margin-top: 4px;
    margin-left: 10px;
    display: -webkit-box;
}

.ant-select {
    font-family: $font-primary;
}

.search-bar-table>.ant-select-selection--single {
    height: 32px;
}

.search-bar-table>.ant-select-selection--single>.ant-select-selection__rendered>.ant-select-selection__placeholder {
    //color: black;
    font-size: 14px;
    padding-top: 2px;
    padding-left: 3px;
}

.sss {
    width: 142px;
    margin-left: 128px;
}

.sss>li {
    // padding: 8px 15px 8px 15px;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    background-color: #ffffff;
    color: #000000;
    font-family: $font-primary;
    &:hover {
        background-color: #fcfeff;
    }
}

.import-inventory{
    font-size:12px;
    line-height: 27px;
    border: solid 0.5px #b8c1cc;
    padding: 15px 25px;
}

.color-red {
    color: red;
}
.coa-content {
    display: grid;
    display: -ms-grid;
}

.coa-desc {
    object-fit: contain;
    text-decoration: none;
    font-size: 16px;
    text-align: left;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #7e8893;
}

.padding-all-8 {
    padding: 8px;
}

.padding--top--20{
    padding-top: 20px;
}
.padding--top--19{
    padding-top: 19px !important;
}
.padding--top--17{
    padding-top: 17px !important;
}
.padding--top--8{
    padding-top: 8px !important;
}

.padding--top--10{
    padding-top: 10px !important;
}
.padding--top--4{
    padding-top: 4px !important;
}
.padding--top--3{
    padding-top: 3px !important;
}
.padding--top--0{
    padding-top: 0px !important;
}
.padding--left--40{
    padding-left: 40px !important;
}
.padding--left--45{
    padding-left: 45px !important;
}
.padding--left--85{
    padding-left: 85px !important;
}
.padding--left--88{
    padding-left: 88px !important;
}
.padding--left--15 {
    padding-left: 15px !important;
}
.padding--left--10 {
    padding-left: 10px !important;
}
.padding--left--6{
    padding-left: 6px !important;
}

.padding--left--5{
    padding-left: 0%;
}

.padding--left--10{
    padding-left: 10%;
}

.padding--left--20 {
    padding-left: 20px !important;
}

.padding--left--136 {
    padding-left: 136px !important;
}
.padding--left--140 {
    padding-left: 140px !important;
}
.padding--right--12 {
    padding-right: 12px !important;
}
.padding--right--20 {
    padding-right: 20px !important;
}
.padding--right--27 {
    padding-right: 27px !important;
}

.padding--right--30 {
    padding-right: 30px !important;
}

.padding--right--45 {
    padding-right: 45px !important;
}
.padding--right--50{
    padding-right: 50px !important;
}

.padding--right--84 {
    padding-right: 84px !important;
}

.padding--right--90 {
    padding-right: 90px !important;
}


.search-bar-table>.ant-select-selection--single>.ant-select-selection__rendered>.ant-select-selection__placeholder {
    padding-top: 1px !important;
    padding-left: 3px !important;
}

.content-full-form {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    border: solid 0.5px #ddd;
    margin: 12px;
}
.content-form-dashboard {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    border: solid 0.5px #ddd;
    margin: 10px;
}
.bank-accounts {
    border-radius: 0px !important;
    box-shadow: none !important;
    margin: 0px !important;
    width:100% !important
}
.btn-preview-list
{
    width: 170px !important;
    height: 32px !important;
}
.btn-priview
{
    width: 123px !important;
    height: 32px !important;   
    float: right;
}
.btn-priview-id
{
    width: 198px !important;
    height: 32px !important;   
    float: right;
}
.btn-share{
    width:130px !important;
    height: 32px !important;
    float: right;
    padding: 0px !important;
}

.header-full-form {
    padding: 13px 12px 13px 12px;
    border-bottom: solid 0.5px #b8c1cc;
}

.h-34 {
    height: 34px;
}

.h--34 {
    height: 34px !important;
}

.content---form {
    margin: 26px 24px 26px 24px;
}

.title-form {
    font-size: 20px;
    font-weight: normal;
    color: #7e8893;
}

.title-form-black {
    font-size: 20px;
    // font-weight: bold;
    color: #000;
    font-family: "harmony-sans-bold"
}

.title--desc-form {
    font-size: 16px;
    font-weight: normal;
    font-family: "harmony-sans-bold";
    color: #121212;
    padding-left: 28px
}

.content--form--input {
    background-color: #f5f8fa;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    //border-right: 1px solid #ddd;
    //border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 25px 20px;
    font-size: 14px;
    font-family: "harmony-sans-regular";
    color: #121212;
}

.content--form--input2 {
    background-color: #f5f8fa;
    border-radius: 5px;
    padding: 15px 10px;
    font-size: 14px;
    font-weight: bold;
    font-family: "harmony-sans-regular";
    color: #121212;
}

.font--form--input {
    font-size: 14px;
    font-weight: bold;
    font-family: "harmony-sans-regular";
    color: #121212;
}

.line--top {
    height: 1px;
    border-color: #b8c1cc;
}

.line--address {
    height: 0.5px;
    border-color: #E6ECF2;
    margin-bottom: 20px;
}

.desc-upload-file {
    font-family: "harmony-sans-regular";
    font-size: 12px;
    color: #7e8893;
}

.link-show-attched {
    // text-decoration: underline;
    padding-left: 18px;
}

.box--detail{
    min-width: 190px;
    max-width: 255px;
    height: 110px;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #e7ecf3;
    margin-left: 20px;
    margin-top: 14px;
}

.label--box--detail{
    width: 114px;
    height: 14px;
    font-family: 'harmony-sans-regular';
    font-size: 16px;
    text-align: left;
    color: #121212;
    margin: 18px 20px 11px 15px;
}

.text--box--detail{
    height: 14px;
    font-family: 'harmony-mono-regular' !important;
    text-align: left;
    margin: 18px 20px 21px 15px; 
    text-align: left;
    color: #419aff;
    font-weight: bold;
    font-size: 20px;
}

.delete--person{
    margin-top: 35px;
    text-align: center;
    margin-left: 30px;
}

.margin--top--15{
    margin-top: -10px;
}

.margin--top--min-20{
    margin-top: -20px;
}
.margin--top--min-3{
    margin-top: -3px !important;
}
.margin--top--min-4{
    margin-top: -4px !important;
}
.margin--top--min-8{
    margin-top: -8px !important;
}
.margin--top--min-1{
    margin-top: -1px !important;
}

.margin--top--22{
    margin-top: 22px;
}

.margin--top--30{
    margin-top: 30px;
}

.margin--top--40{
    margin-top: 40px !important;
}

.margin--top--45{
    margin-top: 45px !important;
}

.margin--top--60{
    margin-top: 60px !important;
}

.margin--top--80{
    margin-top: 80px !important;
}

.margin--top--3{
    margin-top: 3px !important;
}
.margin--top--4{
    margin-top: 4px;
}

.margin--bottom--min-32
{
    margin-bottom: -32px !important; 
}
.margin--bottom--min-10
{
    margin-bottom: -10px !important; 
}
.margin--bottom--0
{
    margin-bottom: 0px !important; 
}
.margin--bottom--4
{
    margin-bottom: 4px !important; 
}
.margin--bottom--20{
    margin-bottom: 20px !important;
}
.margin--right--min--7{
    margin-right: -7px !important;
}
.margin--right--0{
    margin-right: 0px !important;
}
.margin--right--5{
    margin-right: 5px !important;
}
.margin--right--10{
    margin-right: 10px;
}
.margin--right--15{
    margin-right: 15px !important;
}
.margin--right--18{
    margin-right: 18px !important;
}
.margin--right--20{
    margin-right: 20px !important;
}
.margin--right--23{
    margin-right: 23px !important;
}
.margin--right--40{
    margin-right: 40px;
}
.margin--right--50{
    margin-right: 50px !important;
}
.margin--right--32{
    margin-right: 32px !important;
}
.margin--right--34{
    margin-right: 34px !important;
}
.margin--right--99{
    margin-right: 99px !important;
}
.margin--right--105{
    margin-right: 105px !important;
}
.margin--right--147{
    margin-right: 147px !important;
}
.margin--right--430{
    margin-right: 430px;
}
.margin--right--490{
    margin-right: 490px;
}
.margin--left--531{
    margin-left: 531px !important;
}
.margin--left--575{
    margin-left: 575px !important;
}
.margin--left--665{
    margin-left: 665px;
}
.margin--left--760{
    margin-left: 760px !important;
}
.margin--left--259{
    margin-left: 354px !important;
}
.margin--left--162{
    margin-left: 162px;
}
.margin--left--117{
    margin-left: 150px;
}
.margin-auto{
    margin:auto !important
}
.margin-10{
    margin:10px;
}
.margin--left--112{
    margin-left: 112px;
}
.margin--left--100{
    margin-left: 100px;
}
.margin--left--85{
    margin-left: 85px;
}
.margin--left--80{
    margin-left: 80px;
}
.margin--left--70{
    margin-left: 70px;
}
.margin--left--60{
    margin-left: 60px
}
.margin--left--40{
    margin-left: 40px
}

.margin--left--30{
    margin-left: 30px
}

.margin--left--25{
    margin-left: 25px
}

.margin--left--20{
    margin-left: 20px;
}

.margin--left--15{
    margin-left: 15px;
}
.margin--left--8{
    margin-left: 8px !important;
}
.margin--left--7{
    margin-left: 7px !important;
}

.margin--left--min--34{
    margin-left: -34px !important;
}
.margin--left--min--46{
    margin-left: -24px !important;
}

.margin--left--0{
    margin-left: 0px !important;
}

.padding--bottom--5{
    padding-bottom: 5px !important;
}
.padding--bottom--3{
    padding-bottom: 3px !important;
}
.padding--bottom--8{
    padding-bottom: 8px !important;
}
.padding--bottom--10{
    padding-bottom: 10px;
}

.padding--bottom--14{
    padding-bottom: 14px;
}

.padding--bottom--45{
    padding-bottom: 45px;
}
.icon--prc{
    height: 33px;
    padding-left: 10px;
}

.float--right{
    float:right;
}
.height--15{
    height: 15px !important;
}

.height--70{
    height: 72px;
}

.height-100{
    height: 100% !important;
}
.min-witdh-1411
{
    min-width: 1411px;
}
.min-witdh-1435
{
    min-width: 1435px;
}
.last--depre{
    /*
    margin-bottom: -61px;
    padding-top: 10px;
    margin-right: 360px;*/
    padding-right: 15px;
    margin-left: 175px;
    // float: left;
    padding-top: 5px;
    font-size: 12px;
    color: #7e8893;
    font-family: harmony-sans-regular;
    font-weight: normal;
}

.last-depreciation-text{
    padding-top: 5px;
    font-size: 12px;
    color: #7e8893;
    font-family: harmony-sans-regular;
    font-weight: normal;
    text-align: right;
}

.msg--error{
    width: 100%;
    margin-left: 25px !important;
    padding-right: 45px !important;
}

.icon--empty--report{
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 729px;
}

.text--empty--report{
    font-size: 14px;
    color: #7e8893;
}

.box--report--page{
    width: 100%;
    height: 100px;
    border: none;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.link--report{
    text-decoration: underline;
    font-family: 'harmony-sans-bold';
    font-size: 16px;
}

.inventory-dynamic {
    width: 100%;
    overflow-y: auto;
    height: auto;
    max-height: 410px;
    overflow-x: auto;
    th, td {
        // min-width: 200px !important;
    }
}

.label-add-blue {
    font-size: 14px;
    font-family: "Harmony-sans-regular";
    color: #419aff;
    margin-bottom: 2px;
}

.label-add-grey  {
    font-size: 14px;
    font-family: "Harmony-sans-regular";
    color: #b8c1cc;
    margin-bottom: 2px;
}

.m--0 {
    margin: 0 !important;
}

.section-group > .section-group-title > h2 {
    font-size: 28px;
    font-weight: 400;
    line-height: 39px;
}