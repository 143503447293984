.pdf-container{
    width: 830px;
    margin: -16px -16px 16px -16px;
    background: #1f242a !important;
}

.pdf-modal-img{
    padding: 3px;
    width: 24px;
    height: 24px;
    vertical-align: center;
    margin-right: 10px;
    margin-left: 30px;
    margin-top: 7px;
    float: left;
}

.modal-pdf > .ant-modal-content > .ant-modal-header{
    background: #1f242a !important;
    border: none !important;
}

.modal-pdf > .ant-modal-content > .ant-modal-header > .ant-modal-title {
    color: white !important;
    font-size: 20px !important;
    text-align: center !important;
    padding: 5px !important;
}

.modal-pdf > .ant-modal-content > .ant-modal-body{
    background: #242b33;
    border: none !important;
}

.modal-pdf > .ant-modal-content > .ant-modal-close > .ant-modal-close-x{
    color: white;
}