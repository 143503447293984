.textArea {
    width: 100%;
    height: 64px;
    padding: 12px 9px 12px 9px;
    border-radius: 3px;
    resize:none;
    background-color: #ffffff;
    font-family: "harmony-sans-regular";
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    border: solid 0.5px #b8c1cc;
        &.focus{
            box-shadow: 0 1px 1px 0 rgba(65, 154, 255, 0.6);
            border: solid 0.5px #419aff
        }
}

.textArea:disabled {
    height: 64px;
    border-radius: 3px;
    background-color: #e7ecf3;
    color: #7e8893;
    border: solid 0.5px #b8c1cc;
}
.textArea:-moz-placeholder
{
    
    height: 14px;
    font-family: "harmony-sans-regular";
    font-size: 14px;
    text-align: left;
    color: #b8c1cc;
}
.textArea::-webkit-input-placeholder
{
    
    height: 14px;
    font-family: "harmony-sans-regular";
    font-size: 14px;
    text-align: left;
    color: #b8c1cc;
}
.textArea:-ms-input-placeholder
{
    
    height: 14px;
    font-family: "harmony-sans-regular";
    font-size: 14px;
    text-align: left;
    color: #b8c1cc;
}
.error {
    height: 64px;
    padding: 12px 9px 12px 9px;
    border-radius: 3px;
    resize:none;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23);
    border: solid 0.5px #f02d37;
        &.focus{
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6);
            border: solid 0.5px #419aff
        }
}
