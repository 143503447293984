.row-bank-transfer > div
{
    padding-top: 10px;
}
.cashbank-transfer-add-row-1{
    tr > td:nth-child(2n-1){
        width: 50%;
        min-width: 250px;
    }
    tr > td:nth-child(2n){
        width: 50%;
        min-width: 220px;
    }
    tr > td > tr > td{
        padding: 8px
    }
    tr > td > div > tr > td{
        padding: 8px
    }
}