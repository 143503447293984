.addcc-table{
    tr {
        height:40px; 
        td {
            text-transform: capitalize
        }
        td:first-child{
            width:30%
        }
    }
}

.addcc-ccno{
    width: 100%;
    > div {
        width: 20%;
        display: inline-block;
        text-align: center
    }

    > div:nth-child(2n){
        width: 6%;
    }
}

.addcc-exp-date{
    width: 100%;
    > div {
        width: 43px !important;
        display: inline-block;
        text-align: center
    }
}

.addcc-inline {
    float:left;
    width: 95%;
    .helper-text-modal{
        margin: 0px;
        padding: 0px;
        cursor: pointer
    }
    vertical-align: top;
    

}

.addcc-inline:first-child {
    width: 5%;
    margin-top: 2px
}