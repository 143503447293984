.conf-text{
    font-size: 16px;
    font-family: harmony-sans-regular;
    color: #000;
    margin: 0px 0px 50px 0px;
}
.conf-div{
    margin: 5px 15px
}
.conf-title{
    font-family: harmony-sans-bold;
    text-align: center;
}
.content-share-online {
    font-size: 14px;
    color:black;
    font-family: harmony-sans-bold;
    font-weight: normal;
    padding: 0px 20px;    
}

.pdf-share{
    margin: 20px 0px;
    td{
        width: 140px;
        text-align: center
    }
}

.radio-share{
    font-size:12px !important;
    font-family: harmony-sans-regular;
    margin-top:15px;
    label{
        padding-left:25px !important;
    }
    .oneradio--row{
        line-height: 18px !important;
    }
}

.input-share {
    width: 412px;
    font-size: 12px;
    float: left;
}
.btn-copy-link{
    width:22% !important;
    float: left;
    margin-left:10px;
}

.url-copied{
    color:#4ddb7c;
    font-size: 12px;
    font-weight: normal;
    font-family: harmony-sans-regular;
}

.label-url{
    float:right;
    margin:10px;
    margin-bottom:0px;
    padding-right:22px;
}

.label-share{
    font-family: harmony-sans-regular;
}