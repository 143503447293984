.tbl-detail {
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 0.5px #b8c1cc;
    border-radius: 5px;
}

.add-row {
    background-color: #ffffff;
    border-radius: 3px;
    border: solid 1px #b8c1cc;
}

.lbl-add--row {
    position: relative;
}

.icon--add--row {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 50px;
    top: 1px;
}

.btn-add {
    width: 138px;
    height: 34px;
}

.add-row {
    text-align: center;
    border: 1.5px dashed #b8c1cc;
    padding-top: 10px;
    margin-top: 20px;
    margin-right: 14px;
    margin-left: 14px;
    padding-bottom: 10px;
}

.add-row-smartrule {
    text-align: center;
    border: 1.5px dashed #b8c1cc;
    padding-top: 10px;
    margin-top: 20px;
    margin-right: 8px;
    margin-left: 8px;
    padding-bottom: 10px;
    background-color: #ffffff;
    border-radius: 3px;
}


.btn-add-row button {
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-family: 'harmony-sans-regular';
    font-weight: normal;
    border-color: #b8c1cc;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.btn-add-dropdown button
{
    width: 120px;
    height: 32px;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 0.5;
    text-align: center;
    padding: 10px 19px 11px 19px;
    font-family: "harmony-sans-bold";
    font-size:14px;
    background-color: #4ddc7c;
    box-shadow: 0 2px 4px 0 rgba(77, 219, 124, 0.54);

    &:focus{
        background-color: #3edf74;
    }
    &:hover
    {
        background-color: #5ff190;
        color: #ffffff;
        box-shadow: 0 3px 4px 0 rgba(77, 219, 124, 0.57);
    }
}

.btn-add-dropdown .ant-dropdown-trigger{
    background-color: #27c95d;
    width: 30px;
}

.btn-add-row-disabled button{
    border-color: #b8c1cc !important;
    color: #b8c1cc !important;
    cursor: not-allowed;
    background-color: #f7f7f7
}

.btn-dropdown button
{
    width: calc(100% - 30px);
    height: 32px;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 0.5;
    text-align: center;
    // padding: 10px 19px 11px 19px;
    padding: 10px 19px 11px 10px;
    font-family: "harmony-sans-bold";
    font-size:14px;
    background-color: #4ddc7c;
    box-shadow: 0 2px 4px 0 rgba(77, 219, 124, 0.54);
    &:focus{
        background-color: #3edf74;
    }
    &:hover
    {
        background-color: #5ff190;
        color: #ffffff;
        box-shadow: 0 3px 4px 0 rgba(77, 219, 124, 0.57);
    }
}

.btn-dropdown-white button
{
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-family: 'harmony-sans-regular';
    font-weight: normal;
    border-color: #b8c1cc;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    &:focus{
        background-color: #ffffff;
    }
    &:hover
    {
        background-color: #ffffff;
        color: #000000;
        box-shadow: 0 3px 4px 0 rgba(77, 219, 124, 0.57);
    }
}

.btn-dropdown .ant-dropdown-trigger{
    top: -1px;
    background-color: #27c95d;
    width: 30px;
}

.btn-dropdown-200 button {
    width: calc(100% - 30px);
}

.btn-dropdown-disabled button{
    border-color: #b8c1cc !important;
    color: #b8c1cc !important;
    cursor: not-allowed;
    background-color: #f7f7f7
}

.pointer-button-important {
    .ant-btn-default {
        cursor: default !important
    }
}

.detil-tbl {
    padding-bottom: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 0.5px #ddd;
    overflow: hidden;
}

.list-add-row {
    width: 150px;
}

.detil-tbl>.table-responsive {
    border-top-right-radius: 5px;
    border-bottom: solid 0.5px #ddd;
    border-right: solid 0.5px #ddd;
    box-shadow: none;
}

.footer-full-form {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #ffffff;
    //border-bottom: solid 0.5px #b8c1cc;
    border-top: solid 0.5px #b8c1cc;
    padding: 12px 20px 10px 20px;
    margin: 0;
    //padding-right: 0px !important;
    height: 60px;
}
.footer-full-form > .float-right > div
{
    display: -webkit-inline-box;
}

.footer-template-form {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: solid 0.5px #ddd;
    background-color: #f5f8fa;
    padding: 33px 29px;
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14)
}

.box-report{
    margin-top: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #f5f8fa;
    padding: 33px 29px;
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14)
}

.blank-form {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-left: solid 0.5px #ddd;
    border-right: solid 0.5px #ddd;
    border-top: solid 0.5px #ddd;
    background-color: #f5f8fa;
    padding: 17px 38px;
    margin: 26px 23px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14)
}

.no-background-form {
    background-color: #fff !important;
    font-size: 14px;
}

.header-template-form {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-left: solid 0.5px #ddd;
    border-right: solid 0.5px #ddd;
    border-top: solid 0.5px #ddd;
    background-color: #ffffff;
    padding: 9px 29px;
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14)
}

.subform-inventory {
    margin: 24px 24px 10px 24px
}

.subform {
    font-weight: bold;
    font-family: harmony-sans-regular;
}

.icon-excel-16 {
    width: 16px;
    height: 16px;
    position: absolute;
    margin-left: -60px;
    margin-top: -3px;
}

.icon-clear-preview {
    width: 30px;
    height: 30px;
    position: absolute;
    margin-left: -50px;
    margin-top: -9px;
}

.icon-clear-preview-id {
    width: 30px;
    height: 30px;
    position: absolute;
    margin-left: -87px;
    margin-top: -10px;
}

.payhist {
    width: 240px;
    margin-left: 3px;
}

.col-calculate {
    // padding: 22px 38px 22px 38px;
    display: grid;
}

.col-header-calculate {
    border: 1.5px dashed #b8c1cc;
    background-color: #f5faff;
    border-radius: 5px;
    display: grid;
    width: 423px;
    padding: 20px;
}

.font-withholding {
    font-size: 16px;
    font-family: "harmony-sans-regular";
    color: #b8c1cc;
    font-weight: normal !important;
}

.col-total {
    border-top: solid 1px #e7ecf3;
    border-bottom: solid 1px #e7ecf3;
}

.col-total-bulkpayment {
    border-bottom: solid 1px #d8e2f0;
}

.txt-color--blue {
    color: #419aff !important;
}

.txt-terms {
    overflow:auto; //hidden
    width: 360px !important;
    height: 125px !important;
}

.scrol-tbl {
    overflow: hidden;
    position: relative;
}

.column-price {
    font-family: "harmony-mono-regular" !important;
    text-align: right
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
    border: none;
}

.table-padding-2 > tbody > tr > td,
.table-padding-2 > tbody > tr > th,
.table-padding-2 > tfoot > tr > td,
.table-padding-2 > tfoot > tr > th,
.table-padding-2 > thead > tr > td,
.table-padding-2 > thead > tr > th {
    padding: 2px !important
}

.table-padding-4 > tbody > tr > td,
.table-padding-4 > tbody > tr > th,
.table-padding-4 > tfoot > tr > td,
.table-padding-4 > tfoot > tr > th,
.table-padding-4 > thead > tr > td,
.table-padding-4 > thead > tr > th {
    padding: 4px !important
}

.table-padding-0 > tbody > tr > td,
.table-padding-0 > tbody > tr > th,
.table-padding-0 > tfoot > tr > td,
.table-padding-0 > tfoot > tr > th,
.table-padding-0 > thead > tr > td,
.table-padding-0 > thead > tr > th {
    padding: 0px !important
}

.table-valign-top > tbody > tr > td,
.table-valign-top > tbody > tr > th,
.table-valign-top > tfoot > tr > td,
.table-valign-top > tfoot > tr > th,
.table-valign-top > thead > tr > td,
.table-valign-top > thead > tr > th {
    vertical-align: top !important
}

.tbody-dynamic{
    overflow-x: hidden;
    display: block;
    height: 325px;
}

.border-all {
    border: solid 0.5px #ddd;
}

.border-left {
    border-left: solid 0.5px #ddd;
}

.border-right {
    border-right: solid 0.5px #ddd;
}

.border-bottom {
    border-bottom: solid 0.5px #ddd;
}

.border-top {
    border-top: solid 0.5px #ddd;
}

.table-valign-bot > tr > td {
    vertical-align: bottom;
    padding: 5px !important;
}

.table-cell-border > tbody > tr > td,
.table-cell-border > tbody > tr > th,
.table-cell-border > tfoot > tr > td,
.table-cell-border > tfoot > tr > th,
.table-cell-border > thead > tr > td,
.table-cell-border > thead > tr > th {
    border: solid 0.5px #ddd;
}

.td-midle {
    vertical-align: middle !important;
}

.table-border {
    border: solid 0.5px #ddd !important;
}

.table-no-border {
    border: none !important;
}