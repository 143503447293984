.journ-div-1>div.left {
    float: left;
    margin-right: 15px
}

.journ-div-1>div.fileuploader {
    float: right;
    text-align: right
}

.journ-div-1{
    min-width: 700px
}

.journ-div-spacing{
    width: 100%;
    height: 50px
}

.journal-repeat-setup{
    margin: 26px 0px 0px 0px;
}

.journal-repeat-filled{
    margin: 32px 0px 0px 18px;
}

.btn-back-taggingdet{
    width: 100%;
    padding: 0 !important;
    margin-left: -10px;
    margin-top: 5px;
    line-height: 1.5;
}