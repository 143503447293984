.text-inputmodal{
    width: 100px
}
.text-inputmodalRevaluation{
    width: 140px
}
.icon-p-10
{
    padding: 8px  8px 8px 15px
}
.icon-pt-7
{
    padding-top: 7px
}
.text-adjusment{
    font-size: 12px
}
.lbl-size{
    font-size: 14px;
}
.mr-20
{
    margin-right: 20px;
}