.img-cust{
    margin-right: 5px;
    margin-top: -2px;
}

.content-full-cust{
    border-radius: 0px !important;
    background-color: #ffffff;
    box-shadow: none !important;
    border: none !important;
    margin: 0px !important;
}

.content-full-contact {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    border: solid 0.5px #ddd;
    margin: 12px;
}

.width-customer{
    min-width: 160px;
    max-width: 160px;
}

.icon-left-contact {
    width: 20px;
    height: 17px;
    position: absolute;
    margin-left: -60px;
    margin-top: -4px;
}

.icon-img-export{
    width: 16px;
    margin-top: -3px;
}

.padding-top-0{
    padding-top:0px !important;
}

.padding-label {
    padding : 40px 0px 15px 20px;
}

.label-field-cust
{
  width: 160px;
  height: 16px;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #121212;
  margin-bottom: 15px;
  font-family: 'harmony-sans-bold';
}

.contact-info-container {
    margin: 20px 10px 10px 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid #707070;
}

.container-contact-title {
    width: 45%;
    float: left;
    padding-top: 2px;
    padding-right: 50px
}

.container-contact-content{
    width: 55%;
    float: left;
}

.title-field-contact{
    font-size: 14px;
    font-family: 'harmony-sans-bold';
    color: #6e6e6e;
}

.snapshot-form {
    padding:20px 31px;
    margin:0px 20px;
    background-color: #f5f8fa;
}

.snapshot-container {
    width: 25%;
}

#link-view-statement:hover{
    color: #777777;
    text-decoration: none;
}

.contact-recent-link {
    // text-decoration: underline;
}

.contact-recent-link:hover {
    text-decoration: none;
}