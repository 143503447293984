.header-content
{
    width: 100%;
    height: 63px;
    background-color: #ffffff;
    border-bottom: solid 1px #b8c1cc;
}
.header-content >div{
    
    display: -webkit-inline-box;
}
.title-header-col
{
    padding-top: 16px;
    padding-left: 27px;
    padding-bottom: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-table;
    font-family: 'harmony-sans-bold' !important;
    height: 100%;
}
.title-right-header-col
{
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-table;
    text-align: right;
    height: 100%;
}
.list-tab
{
    display: inline-block !important;
    overflow: hidden;
    white-space: nowrap;
    //text-overflow: ellipsis;
    height: 100%;
}
.title-header
{    
    font-size: 20px;
    text-align: left;
    //width: 64px;
    width: 100%;
    height: 20px;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    color: #121212;
}
.tab-content
{
    list-style-type: none;
    display: inline-block;
    padding: 0;
    margin: 0;
}

.active-tab
{
    color:#419aff !important;
    border-bottom: solid 2.6px #419aff;
    padding-bottom: 16px;
}
.tab-content > li:hover > a
{
    color: #000;
}
.tab-content > li{
    display: inline-block;
    padding-right: 10px;
    font-size: 14px;
    text-align: left;
    color: #b8c1cc;
    font-weight: normal;
    padding:20px 8px 10px 10px;//padding: 16px 28px 10px 28px;
}
.tab-content > li > a{
    color: #b8c1cc;
}
.side-top-button
{
    float: right;
    display: -webkit-box;
    padding-top: 3px;
    padding-right: 14px;
}
.side-top-button-coa
{
    float: left;
    display: -webkit-box;
    padding-top: 0px;
    padding-right: 14px;
}
.download-col
{
    text-align: center;
    padding-top: 3px;
    padding-left: 2px;
    width: 40px;
  height: 34px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(126, 136, 147, 0.19);
  border: solid 0.5px #b8c1cc;
      margin-right: 22px;
}