.report-view{
    min-width: 712px;
    display: inline-block
}

.max-report {
    max-width: 1150px;
}

.max-report-aged {
    max-width: 1128px;
}

.max-report-asset {
    max-width: 1060px;
}

.invoice-trans-view{
    margin-left: calc(5% /2);
    min-width: 600px;
    display: inline-block
} 

.tagging-setting {
    width: 100%;
    display: inline-table;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
}

.tagging-setting-show {
    text-align: right;
    padding: 15px 35px;
    border-top: solid 0.5px #b8c1cc;
}

.report-view-800{
    min-width: 800px;
    display: inline-block
}

.report-view-1200 {
    min-width: 1200px;
    display: inline-block;
}