.ast-field {
    width: 150px;
    margin-right: 10px;
}

.ast-field-multiple {
    min-width: 180px;
    max-width: 350px;
    margin-right: 20px;
    top: -1px !important;
    @-moz-document url-prefix() {
        top: -0px;
    }
}