// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */

.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

#app
{
  overflow: hidden;
}
/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  margin-left: auto;
  /* 2 */
  margin-right: auto;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  padding-right: 20px;
  /* 3 */
  width: 100%;
  /* 1 */
}


/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */

.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}


/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.div--form
{
  display: flex;
  width: 500px;

}
.line-height-1 {
  line-height: 1.3 !important;
}

.line-height-unset {
  line-height: unset !important;
}

.pl--22
{
  padding-left: 22px;
}

.pl--19
{
  padding-left: 19px !important;
}
.cur-pointer
{
  cursor: pointer;
}
.text-left-title{
  font-family: "harmony-sans-bold" !important;
  font-size: 16px;
  padding-left: 12px;
  text-align: left;
}
.font-bold
{
  font-family: "harmony-sans-bold" !important;
}
.font-bold-12
{
  font-family: "harmony-sans-bold" !important;
  font-size: 12px !important;
}
.font-bold-14
{
  font-family: "harmony-sans-bold" !important;
  font-size: 14px !important;
}
.font-number
{
  font-family: "harmony-mono-regular" !important;
}
.float-unset {
  float: unset !important;
}
.float-left {
  float: left
}

.float-right {
  float: right !important;
}
.pr--40
{
  padding-right: 40px !important;
}
.pr--45
{
  padding-right: 45px !important;
}
.helper-text-modal>a {
  text-decoration: underline;
}
.wd-max-150
{
  max-width: 150px;
}
.wd---100{
  width:100% !important;
}
.wd-box-settings{
  width:calc(100% / 2);
}
.color-black
{
  color:#242b33 !important;
}
.wd-130
{
  width: 130px !important;
}
.wd-dashboard
{
  // width: calc(100% - 346px);
  width: calc(100% - 446px) !important;
  max-width: calc(100% - 446px) !important;
}
.wd-180
{
  width: 180px !important;
}
.wd-177
{
  width: 177px !important;
}
.pr--37
{
  padding-right: 37px;
}
.pr--18
{
  padding-right: 18px;
}
.pr--16
{
  padding-right: 16px !important;
}
.pl--34
{
  padding-left: 34px;
}
.wd-max-130
{
  max-width: 130px;
}
.wd-max-200
{
  max-width: 200px;
}
.wd-max-280
{
  max-width: 280px;
}
.wd--250
{
  width: 250px !important;
}
.wd--265
{
  width: 265px !important;
}
.wd--22{
  width: 22px !important
}
.md--250
{
  max-width: 250;
}
.pt--50
{
  padding-top: 50px;
}
.pt--5
{
  padding-top: 5px;
}
.pt--4
{
  padding-top: 4px;
}
.pt--55{
  padding-top:55px !important
}
.pt--40 {
  padding-top: 40px !important;
}
.wd--170 {
  width: 170px !important
}

.wd--135 {
  width: 135px !important
}
.pt--6
{
  padding-top: 6px;
}
.pb--5 {
  padding-bottom: 5px;
}
.pb--8 {
  padding-bottom: 8px;
}
.pb--30
{
  padding-bottom: 30px;
}
.pb-30
{
  padding-bottom: 30px !important;
}
.pb--80 {
  padding-bottom: 80px;
}
.pb--90 {
  padding-bottom: 90px;
}
.pb--100 {
  padding-bottom: 100px;
}
.pb--50 {
  padding-bottom: 50px;
}
.pb--51 {
  padding-bottom: 51px;
}
.pb--10 {
  padding-bottom: 10px;
}
.pb--16 {
  padding-bottom: 16px;
}

.pb--15 {
  padding-bottom: 15px;
}
.mt--15 {
  margin-top: 15px;
}
.mt--10 {
  margin-top: 10px;
}
.mt--13 {
  margin-top: 13px;
}
.mr--200 {
  margin-right: 200px;
}
.mr--155 {
  margin-right: 155px;
}
.mr--100 {
  margin-right: 100px;
}
.mr--60 {
  margin-right: 60px;
}
.mr--70 {
  margin-right: 70px;
}
.mr--50 {
  margin-right: 50px;
}
.mr--40 {
  margin-right: 40px;
}
.mr--47 {
  margin-right: 47px;
}
.mr--35 {
  margin-right: 35px;
}
.mr--30 {
  margin-right: 30px;
}
.mr--28 {
  margin-right: 28px;
}
.mr--20 {
  margin-right: 20px;
}
.mr--25 {
  margin-right: 25px;
}
.mr--5{
  margin-right: 5px;
}
.mr--8{
  margin-right: 8px;
}
.mr--14{
  margin-right: 14px;
}
.mr--15{
  margin-right: 15px !important;
}
.mr--3 {
  margin-right: 3px;
}
.mr--0{
  margin-right: 0px !important;
}
.pl--8
{
  padding-left: 8px;
}
.pl--7
{
  padding-left: 7px;
}
.pt--130
{
  padding-top: 130px;
}
.wd-140
{
  width: 140px!important;
}
 
.wd-52{
  width: 52px !important;
}
.mr--10 {
  margin-right: 10px;
}
.wd--40
{
  width: 40px !important
}
.mt---5{
  margin-top: -5px;
}
.mt---8{
  margin-top: -8px;
}
.mt---10
{
  margin-top: -10px
}
.pl--30 {
  padding-left: 30px;
}
.pl--35 {
  padding-left: 35px;
}
.wd--360{
  width: 360px !important
}
.wd--365{
  width: 365px !important
}
.mh--auto{
  margin-left: auto !important;
  margin-right: auto !important
} 
.mv--0{
  margin-top: 0 !important;
  margin-bottom: 0 !important
}
.wd--473 {
  width: 473px;
}
.wd--450 {
  width: 450px ;
}
.wd--470 {
  width: 470px !important;
}
.wd--230
{
  width: 230px !important;
}
.wd--280
{
  width: 280px !important;
}
.wd--272
{
  width: 272px !important;
}
.wd--275
{
  width: 275px !important;
}
.wd--260
{
  width: 260px;
}
.pt--11
{
  padding-top: 11px;
}
.wd-600
{
  width: 600px;
}
.wd--310
{
  width: 310px;
}
.wd--312
{
  width: 312px !important;
}
.wd--300, .wd-300 {
  width: 300px;
}
.wd--390 {
  width: 390px;
}
.right--37
{
  right: 37px;
}
.right--50
{
  right: 50px;
}
.right--10
{
  right: 10px;
}
.right--15
{
  right: 15px;
}
.right--0
{
  right: 0px;
}
.top-0
{
  top:0px;
}
.pos-relativ {
  position: relative;
}

.pt--12
{
  padding-top: 12px !important;
}
.font-14
{
 
  font-size: 14px;
  color: #242b33;
  font-family: "harmony-sans-regular"; 
}
.font-14-dis
{
 
  font-size: 14px;
  color:   #B8C1CC;
  font-family: "harmony-sans-regular"; 
}

.pl--9 {
  padding-left: 9px;
}

.pl--24
{
  padding-left: 24px;
}
.pr--27
{
  padding-right: 27px
}

.pr--28
{
  padding-right: 28px !important;
}
.top-87
{
  top:87px;
}
.top-10
{
  top: 10px;
}
.top-130
{
  top:130px;
}
.pt--1
{
  padding-top: 1px !important
}
.pt--6
{
  padding-top: 6px !important
}
.pt--7
{
  padding-top: 7px !important
}
.pt--10
{
  padding-top: 10px !important
}
.pt--75
{
  padding-top: 75px !important
}
.mb--1
{
  margin-bottom: 1px !important;
}
.mb--10
{
  margin-bottom: 10px !important;
}
.mb--20
{
  margin-bottom: 20px !important;
}
.mb--30
{
  margin-bottom: 30px !important;
}
.mb--25
{
  margin-bottom: 25px;
}
.mb--40
{
  margin-bottom: 40px;
}
.mb--50
{
  margin-bottom: 50px !important;
}
.mb--70
{
  margin-bottom: 70px !important;
}
.mw-170
{
  min-width: 170px;
}
.mw-165
{
  min-width: 165px;
}
.mw-185
{
  min-width: 185px;
}
.mw-187
{
  min-width: 187px;
}
.mw-150
{
  min-width: 150px;
}
.mw-155
{
  min-width: 155px;
}
.mw-145
{
  min-width: 145px;
}
.mw-50
{
  min-width: 50px;
}
.mw-140
{
  min-width: 140px;
}
.mw-180
{
  min-width: 180px;
}
.mw-175
{
  min-width: 175px;
}
.mw-177
{
  min-width: 177px;
}
.mw-190
{
  min-width: 190px;
}
.mw-120
{
  min-width: 120px;
}
.mw-210
{
  min-width: 210px;
}
.mw-205
{
  min-width: 205px;
}
.mw-220
{
  min-width: 220px;
}
.mw-225
{
  min-width: 225px;
}
.mw-235
{
  min-width: 235px;
}
.mw-245
{
  min-width: 245px;
}
.mw-238
{
  min-width: 238px;
}
.mw-350
{
  min-width: 350px !important;
}
.mx-360{
  min-width: 360px !important;
}
.mx-620{
  min-width: 620px !important;
  max-width: 1000px;
}
.mw-300
{
  min-width: 300px !important;
}
.mw-295
{
  min-width: 295px !important;
}
.mw-280
{
  min-width: 280px;
}
.mw-270
{
  min-width: 270px;
}
.mb--80
{
  margin-bottom: 80px !important;
}
.mb--15
{
  margin-bottom: 15px;
}
.mb--13
{
  margin-bottom: 13px;
}
.mb--0
{
  margin-bottom: 0px !important;
}
.mb---2
{
  margin-bottom: -2px !important;
}
.mb--5
{
  margin-bottom: 5px !important;
}

.wd-20-prc {
  width: 20%;
}

.wd-30-prc {
  width: 30%;
}

.mb--37{
  margin-bottom: 37px !important;
}
.pos-absolute
{
  position: absolute;
}
.wd-45
{
  width: 45px !important;
}
.wd-43-prc
{
  width: 43%;
}
.wd-60-prc
{
  width: 60%;
}
.wd-70-prc
{
  width: 70%;
}
.wd-72
{
  width: 72px;
}
.wd-120
{
  width: 120px;
}
.wd-125
{
  width: 125px;
}
.wd-145
{
  width: 145px;
}
.wd-144
{
  width: 144px;
}
.wd-191
{
  width: 191px !important;
}
.wd-90
{
  width: 90px
}
.pr--0 {
  padding-right: 0 !important;
}
.pr--5 {
  padding-right: 5px !important;
}

.pl--0 {
  padding-left: 0 !important
}
.font-20
{
  font-size: 20px;
}
.font-14-only
{
  font-size: 14px;
}
.pl--26
{
  padding-left: 26px;
}
.m--auto {
  margin: auto
}
.m--10{
  margin: 10px
}
.m--20{
  margin: 20px
}
.pb--0 {
  padding-bottom: 0 !important
}
.color-text-blue
{
  color:#419aff;
}
.color-text-black
{
  color:#000;
}
.color-text-red
{
  color: red !important;
}
.color-text-green
{
  color: #009900 !important;
}
.color-text-grey
{    
  color: #7e8893 !important;;
}
.color-text-reds
{
  color: #ff414a !important
}
.color-text-darkgrey {
  color: #36414d
}
.color-text-yellow{
  color: #f5b700 !important;
}
.wd--132 {
  width: 123px;
}
.ml--55
{
  margin-left: 55px !important;
}
.ml-533{
  margin-left: 533px;
}
.ml--40
{
  margin-left: 40px !important;
}
.ml--29
{
  margin-left: 29px;
}
.ml--22
{
  margin-left: 22px !important;
}
.ml--20
{
  margin-left: 20px;
}
.ml--30
{
  margin-left: 30px;
}
.ml-30
{
  margin-left: 30px !important;
}

.ml--35
{
  margin-left: 35px;
}
.ml--38
{
  margin-left: 38px;
}
.ml--17
{
  margin-left: 17px;
}
.ml--13
{
  margin-left: 13px;
}
.pb--20
{
  padding-bottom: 20px;
}
.ml--5
{
  margin-left: 5px;
}
.ml--6
{
  margin-left: 6px;
}
.ml--8
{
  margin-left: 8px;
}
.ml--10
{
  margin-left: 10px;
}
.ml--12
{
  margin-left: 12px;
}
.ml--15
{
  margin-left: 15px;
}
.ml--25
{
  margin-left: 25px;
}
.ml-min-100 {
  margin-left: -100px;
}
.mv--8 {
  margin-top: 8px;
  margin-bottom: 8px
}
.mv--15 {
  margin-top: 15px;
  margin-bottom: 15px
}
.mv--36{
  margin-top: 36px;
  margin-bottom: 36px
}
.mt--4{
  margin-top: 4px
}
.mt---4{
  margin-top: -4px
}
.mt--3{
  margin-top: 3px
}
.mt--2{
  margin-top: 2px
}
.mt--1{
  margin-top: 1px
}
.mt--0{
  margin-top: 0px !important;
}
.wd--48 {
  width: 48px
}
.pl--2
{
  padding-left: 2px !important;
}
.pl--5
{
  padding-left: 5px !important;
}
.pl--4
{
  padding-left: 4px;
}
.mt--20 {
  margin-top: 20px !important;
}
.mt--25 {
  margin-top: 25px !important;
}
.mt--28 {
  margin-top: 28px;
}
.mt--30 {
  margin-top: 30px !important;
}
.mb--50{
  margin-bottom:46px !important;
}
.top--10
{
  top:10px;
}
.mt--40 {
  margin-top: 40px;
}
.top--8
{
  top:8px;
}
.top--70{
  top: 70px !important;
}
.mt--50 {
  margin-top: 50px;
}
.mt--55 {
  margin-top: 55px;
}
.mt--42 {
  margin-top: 42px;
}
.mt--43 {
  margin-top: 43px;
}
.mt--72 {
  margin-top: 72px;
}
.mt--90 {
  margin-top: 90px !important;
}
.mt--105 {
  margin-top: 105px !important;
}
.mt--130 {
  margin-top: 130px;
}
.mt--270 {
  margin-top: 274px;
}
.mt--300 {
  margin-top: 320px;
}
.mt--360 {
  margin-top: 340px;
}
.mt--350 {
  margin-top: 340px;
}
.mt--390 {
  margin-top: 403px;
}
.mt--400 {
  margin-top: 427px;
}
.wd--155 {
  width: 155px !important;
}
.wd--153 {
  width: 153px !important;
}
.wd--160 {
  width: 160px !important;
}
.wd--168 {
  width: 168px !important;
}
.wd--165 {
  width: 165px !important;
}
.wd--161 {
  width: 161px !important;
}
.wd--145 {
  min-width: 150px !important;
}
.wd--240
{
  width: 207px;
  min-width: 207px;

}
.wd--190 {
  min-width: 190px !important;
  width: 190px !important;
}
.wd--235 {
  width: 235px !important;
}
.wd--207 {
  width: 207px !important;
}
.wd--223 {
  width: 223px;
}
.underline{
    text-decoration: underline;
}
.dis-inline
{
    display: -webkit-inline-box;
}
.wd--30
{
  width: 30px !important;
}
.wd--100 {
  width: 100px !important;
}
.wd--110 {
  width: 110px !important;
}
.wd--115 {
  width: 115px !important;
}

.wd--120 {
  width: 120px !important;
}

.wd--121 {
  width: 121px !important;
}

.wd--124 {
  width: 124px !important;
}

.wd--125 {
  width: 125px !important;
}

.wd--53 {
  width: 53px !important;
}
.break-word{
  word-wrap: break-word
}
.p--0 {
  padding: 0px !important
}
.p--5 {
  padding: 0px 5px !important
}
.p--8 {
  padding: 8px !important;
}
.p--10 {
  padding: 10px !important
}
.p--25 {
  padding: 25px
}
.p--20 {
  padding: 20px
}
.footer-spacing{
  height: 100px
}
.h--22 {
  height: 22px !important;
}
.h--52 {
  height: 52px !important;
}
.h--30 {
  height: 30px !important;
}
.h--60 {
  height: 60px !important;
}
.h--70 {
  height: 70px !important;
}
.h--95 {
  height: 95px !important;
}
.h--100 {
  height: 100px !important;
}
.mh--400 {
  min-height: 400px
}
.ph--25 {
  padding-left: 25px;
  padding-right: 25px
}
.pv--0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.pv--8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.pv--15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.pv--36 {
  padding-top: 36px;
  padding-bottom: 36px
}
.pt--10 {
  padding-top: 10px;
}
.pt--60
{
  padding-top: 60px;
}
.wd-max--300
{
  max-width: 300px;
}
.wd-max--250
{
  max-width: 250px;
}
.wd-max--230
{
  max-width: 230px;
}
.wd-max--200
{
  max-width: 200px;
}
.wd-max--680
{
  max-width: 680px;
}
.wd-max--140
{
  max-width: 140px;
}
.wd-max--155
{
  max-width: 155px;
}
.wd-max--70{
  max-width: 70px;
}
.min-width-70 {
  min-width: 70px !important;
}
.min-width-700 {
  min-width: 700px !important;
}

.min-width-1100 {
  min-width: 1100px !important;
}
.min-width-1120 {
  min-width: 1120px !important;
}
.min-width-1040 {
  min-width: 1040px !important;
}
.min-width-600 {
  min-width: 600px !important;
}
.min-width-160 {
  min-width: 160px !important;
}

.min-width-150 {
  min-width: 150px !important;
}
.min-width-140 {
  min-width: 140px !important;
}
.min-width-50 {
  min-width: 50px !important;
}
.min-width-200 {
  min-width: 200px !important;
}
.min-width-250 {
  min-width: 250px !important;
}
.min-width-120 {
  min-width: 120px !important;
}
.wd-max--80{
  max-width: 80px;
}
.wd-60
{
  width: 60px;
}
.wd--15{
  width: 15px !important;
}
.wd--12{
  width: 12px !important;
}

.wd--20
{
  width: 20px !important;
}
.wd--80
{
  width: 80px !important;
}
.wd--85
{
  width: 85px !important;
}
.wd--60
{
  width: 60px !important;
}
.wd--63
{
  width: 63px !important;
}
.wd--65
{
  width: 65px !important;
}
.wd--50
{
  width: 50px !important;
}
.wd--55
{
  width: 55px !important;
}
.wd-50
{
  width: 50px !important;
}
.wd-70
{
  width: 70px;
}
.wd-80
{
  width: 80px;
}
.wd-170
{
  width: 170px !important;
}
.wd-204
{
  width: 204px;
}

.wd-200
{
  width: 200px !important;
}
.wd-220
{
  width: 220px;
}
.wd-98 {
  width: 98px;
}
.wd-110 {
  width: 110px;
}
.wd-160 {
  width: 160px;
}

.clr-black {
  color: #242b33;
}

.dis-flex {
  display: inline-flex !important;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.dis-grid {
  display: grid;
}

.dis-table {
  display: table;
}

.left-cell {
  display: table-cell;
  vertical-align: bottom;
  text-align: left;
}

.right-cell {
  display: table-cell;
  vertical-align: bottom;
  text-align: right;
}

.pr--12 {
  padding-right: 12px !important
}

.pr--10 {
  padding-right: 10px !important
}

.pr--8 {
  padding-right: 8px !important
}

.pb-20 {
  padding-bottom: 20px;
}
.clear
{
  
    clear: both;
}
.pt--0 {
  padding-top: 0;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt--20 {
  padding-top: 20px !important;
}
.pt--30 {
  padding-top: 30px !important;
}
.pt--28 {
  padding-top: 28px !important;
}
.pt--35 {
  padding-top: 35px !important;
}
.col--width--40prc
{
  width: 40%;
}
.col--width--44prc
{
  width: 44%;
}
.col--width--49prc
{
  width: 49%;
}
.col--width--60prc
{
  width: 60% !important;
}
.color-yellow{
  background-color: #FFFCF2 !important;
}
.pb--40
{
  padding-bottom: 40px;
}
.pb--50
{
  padding-bottom: 50px;
}
.pb--60
{
  padding-bottom: 60px !important;
}
.pt--2 {
  padding-top: 2px;
}
.pt-2{
  padding-top: 2px !important;
}
.pt--3 {
  padding-top: 3px;
}
.break-word
{
  word-break: break-all;
}
.v-align--top
{
  
    vertical-align: top !important;
}
.v-align-mid
{
    vertical-align: middle !important;
}
.txt-underline
{ 
  text-decoration: underline;
}

.pb--2 {
  padding-bottom: 2px;
}

.wd--35 {
  width: 35px !important;
}
.pt--15
{
  padding-top: 15px;
}
.pt--15--imp
{
  padding-top: 15px !important;
}
.pr--20 {
  padding-right: 20px !important;
}
.pl--20 {
  padding-left: 20px !important;
}
.pl--30 {
  padding-left: 30px !important;
}
.pl--40 {
  padding-left: 40px !important;
}
.pl--55 {
  padding-left: 55px !important;
}
.pl--60 {
  padding-left: 60px !important;
}
.pl--45{
  padding-left: 45px;
}
.pl--80 {
  padding-left: 80px !important;
}
.pl--25 {
  padding-left: 25px !important;
}
.pl--22 {
  padding-left: 22px !important;
}
.pr--25
{
  padding-right: 25px !important;
}

.pr--50
{
  padding-right: 50px;
}
.pr--59 {
  padding-right: 60px !important;
}
.pr--60
{
  padding-right: 60px;
}
.pr--65
{
  padding-right: 65px;
}
.pr--80{
  padding-right: 80px !important;
}
.pr-60{
  padding-right: 60px !important;
}
.pl--38{
  padding-left: 38px;
}
.pl--36
{
  padding-left: 36px;
}
.pl--28{
  padding-left: 28px;
}
.pl--16
{
  padding-left: 16px;
}
.pl--15
{
  padding-left: 15px !important;
}
.pl--14
{
  padding-left: 14px !important;
}
.pr--15
{
  padding-right: 15px !important;
}
.pl--10 {
  padding-left: 10px;
}

.pl--13 {
  padding-left: 13px;
}

.pl--12 {
  padding-left: 11px !important;
}

.font {
  font-size: 16px;
  color: #242b33;
  font-family: "harmony-sans-regular";
}
.wd--500
{
  width: 200px;
}
.wd--543
{
  width: 543px;
}
.wd--700
{
  width: 700px !important;
}
.font-18-bold {
  font-size: 18px;
  color: #242b33;
  font-family: "harmony-sans-regular";
  font-weight: bold
}
.font-24{
  font-size: 24px
}
.font-18{
  font-size: 18px
}
.font-16{
  font-size: 16px
}
.fw-500
{
  font-weight: 500;
}
.wd--130
{
  width: 130px !important;
}
.wd--123
{
  width: 123px !important;
}
.wd--150
{
  width: 150px;
}
.wd--180{
  width: 180px !important;
}
.dis-color{
  color:#b8c1cc;
}
.wd-auto{
  width: auto !important;
}

.mw--1430{
  min-width: 1430px;
}
.mw--1400{
  min-width: 1410px;
}
.mw--1220{
  min-width: 1220px !important;
}
.mw--1200{
  min-width: 1200px;
}
.mw--1250-imp{
  min-width: 1250px !important;
}
.mw--1367-imp{
  min-width: 1367px !important;
}
.mw--1120{
  min-width: 1120px !important;
}
.mw--1150{
  min-width: 1150px !important;
}
.mw--1126{
  min-width: 1126px;
}
.mw--1132{
  min-width: 1132px;
}
.mw--1170{
  min-width: 1170px;
}
.mw--1100{
  min-width: 1100px;
}
.mw--1105{
  min-width: 1105px;
}
.mw--1020{
  min-width: 1020px;
}
.mw--1025{
  min-width: 1025px;
}
.mw--1080{
  min-width: 1080px;
}
.mw--1185{
  min-width: 1185px;
}
.mw--1000{
  min-width: 1000px;
}
.mw--1055{
  min-width: 1055px;
}
.mw--920{
  min-width: 920px !important;
}
.mw--900{
  min-width: 900px;
}
.mw--980{
  min-width: 980px;
}
.mw--865{
  min-width: 865px !important;
}
.mw--775{
  min-width: 775px;
}
.mw--700{
  min-width: 700px;
}
.mw--680{
  min-width: 680px;
}
.mw--570{
  min-width: 570px;
}
.mw--205
{
  min-width: 200px;
}
.mw--210
{
  min-width: 210px;
}
.mw--215
{
  min-width: 215px;
}
.mw--220
{
  min-width: 220px;
}
.mw--235
{
  min-width: 235px;
}
.mw--265
{
  min-width: 265px !important;
}
.mw--345
{
  min-width: 345px !important;
}
.mw--110
{
  min-width: 110px;
}
.mw--150
{
  min-width: 150px;
}
.mw--170
{
  min-width: 170px;
}
.mw--100
{
  min-width: 100px;
}
.mw--120
{
  min-width: 120px;
}
.mw--130
{
  min-width: 130px;
}
.mw--135
{
  min-width: 135px;
}
.mw--185
{
  min-width: 185px;
}
.mw--190
{
  min-width: 190px;
}
.mw--80
{
  min-width: 80px;
}
.mw--85
{
  min-width: 85px;
}
.mw--95
{
  min-width: 95px;
}
.mw--250
{
  min-width: 250px;
}
.mw--200
{
  min-width: 200px;
}
.mw--70
{
  min-width: 70px;
}
.mw--50
{
  min-width: 50px;
}
.mw--57
{
  min-width: 57px;
}
.mw--40
{
  min-width: 40px;
  max-width: 40px;
}
.mw--30 {
  min-width: 30px !important;
}
.maxw-120 {
  max-width: 120px !important;
}
.pr--30
{
  padding-right: 30px;
}
.pr--35
{
  padding-right: 35px;
}
.lbl-title {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.lbl-title-coa {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
}
.lbl-ellipsis-300 {
  max-width: 300px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lbl-ellipsis-custom-width {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.font-size--12
{
  font-size: 12px !important;
}
.dis-event {
  pointer-events: none;
}

.red {
  color: #ff0000
}

.mt--6 {
  margin-top: 6px
}

.font-weight-normal {
  font-weight: normal !important;
}

.bold {
  font-weight: bold
}

.inline {
  display: -webkit-inline-box
}
.content-quick-message-error{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}

.mh-207{
  min-height: 207px;
}

.none-display {
  display: none
}

.inline-table {
  display: inline-table;
  width: 100%
}

.text-decoration-none {
  text-decoration: none !important;
}

.none-border {
  border: none !important;
}

.h---600 {
  height: 600px !important;
}

.mx-510 {
  max-width: 510px !important;
}
.mx-700 {
  max-width: 700px !important;
}

.pt--25 {
  padding-top: 36px !important;
  padding-bottom: 36px !important
}

.pr--14 {
  padding-right: 14px
}

.border-warning {
  border-color: #f0ad4e !important;
}

.border-blinking {
  animation: border-blinking .5s step-end infinite alternate;
}

@keyframes border-blinking { 
  50% { border-color: transparent; } 
}

.blinking {
  animation:blinking 0.8s infinite;
}

@keyframes blinking{
  0%{opacity: 0;}
  50%{opacity: .5;}
  100%{opacity: 1;}
}
.foot-harmony{
  height:50px;
  line-height:50px;
  position:absolute;
  bottom:50px;
  width:100%; /*biar memenuhi layar*/
}
.padding-button{
  padding: 10px 1px 11px 1px !important; 
}
.bottom--0{
  bottom: 0px !important;
  height:65px;
  padding: 50px 10px 10px 15px !important; 
}
.pos-bottom--0{
  bottom: 0px !important;
}
.pos-bottom--15{
  bottom: 25px !important;
}
.pos-bottom--25{
  bottom: 25px !important;
}
.mb--10{
  margin-bottom: 10px !important;
}
.word-break-out {
  word-break: break-all;
  table-layout:fixed;
 }
 .outlet-select-type{
  width: 4500px !important;
  margin-right: 10px;
  margin-top: 4px
}
.outlet-settings{
  width: 4500px !important;
  margin-right: 10px;
  margin-top: 4px;
  margin: 0 auto;
  position: relative;
}
.flat-box {
  border-radius: 0px !important;
}
.no-border {
  border: none !important;
}
.bg-white {
  background-color: #FFF;
}
.overflow-hidden{
  overflow: hidden;
}
.text-underline {
  text-decoration: underline;
}
.bg-transparent {
  background: transparent;
}
.flex-xstart-ycenter {
  justify-content: flex-start;
  margin-top: auto;
  margin-bottom: auto;
}
.flex-xend-ycenter {
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}