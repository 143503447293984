.wd--167 {
    width:167px !important;
}
.wd--148 {
    width:148px
}
.curr--right {
    padding-right:"10px";
    font-size:"14px";
    padding-top:"4px";
}
.curr-left {
    padding-left:"10px";
    font-size:"14px";
    padding-top:"4px"
}
.label-radio-curr{
    width: 270px;
    padding-left: 37px;
    font-size: 14px;
    color: #36414d;
}
.curr-right-field{
    font-size: 14px;
    text-align: left;
    color: #121212;
    margin-bottom: 8px;
    font-family: harmony-sans-regular;
    word-wrap: break-word;
    font-weight: normal;
    width:"auto";
    padding-right:"10px";
    padding-top:"4px";
}
.curr-left-field{
    font-size: 14px;
    text-align: left;
    color: #121212;
    margin-bottom: 8px;
    font-family: harmony-sans-regular;
    word-wrap: break-word;
    font-weight: normal;
    width:"auto";
    padding-left:"10px";
    padding-top:"4px";
}
.radio-btn-curr{
    padding-left: 5px;
    float: left;
    .oneradio--row{
        line-height: 1.2;
        margin-bottom:0px;
    }
}

.footer-btn{
    padding-bottom: 32px;
}

.delete-dis{
    width:18px;
    height:18px;
    margin-right:3px;
}

.delete-curr{
    font-size: 16px;
    font-weight: normal;
    font-family: harmony-sans-bold;
    text-align: center;
}

.error-curr{
    padding: 0px 18px;
    margin-top: 15px
}

.date-currency {
    font-size:12px;
    color:#7e8893;
    margin-left:10px;
}