.lbl-title-form-3
{
    
  font-family: harmony-sans-bold;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #242b33;
  position: relative;

}
.content-form3
{
    padding: 20px 30px;
}
