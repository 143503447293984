// .div-step {
//     position:fixed;
//     top: 10px;
//     left: 10%;
//     width:80%;
// }

// .signup--container {
//     width: 100%;
//     height: 100%;
//     font-family: harmony-sans-regular;
//     font-size: 16px;
//     //min-height: 100vh;
//     background-image: url("/assets/images/base.svg") 
// }

// .main-content-signup {
//     height: 100%;
//     overflow: auto;
//     text-align: center;
// }

// .content-signup {
//     width: 400px;
//     height: auto;
//     border-radius: 2px;
//     background-color: #ffffff;
//     box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
//     margin: 0 auto;
//     position: relative;
//     padding: 10px;
//     top: 70px;
// }

// .lbl-info-signup {
    
//     position: sticky;
//     margin-top: 90px;
//     font-family: harmony-sans-regular;
//     font-size: 14px;
//     text-align: left;
//     // padding-bottom: 20px;
//     color: #242b33;
// }

// .icon-signup-back {
//     width: 15px;
//     height: 15px;
// }

// .lbl-signup {
//     font-family: harmony-sans-regular;
//     font-size: 20px;
//     font-weight: bold;
//     line-height: 1;
//     text-align: center;
//     color: #242b33;
// }

// .passsword-show {
//     padding-right: 38px;
// }

.icon-logo-referral {
    width: 70px;
    height: 70px;
    opacity: 0.5;
}

// .font-bold-16-gray {
//   font-family: "harmony-sans-bold" !important;
//   font-size: 16px !important;
//   color: #67708e;
// }

.font-bold-50-blue {
    font-family: "harmony-sans-bold" !important;
    font-size: 50px !important;
    color: #419aff;
}

.color-wait-payment {
    color: #ffcb5e;
}

.color-canceled {
    color: #f3641b;
}

.color-used {
    color: #00cf7b;
}

.color-pending {
    color: #b4c0bd;
}

.card-referral {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid rgba(50,70,90,.3);
    border-radius: 10px;
    box-sizing: border-box;
    width: 200px;
    height: 130px;
}

.color-text-yellow {
  color: #d8db31 !important;
}