:root {
    --height: 400px;
  }

.info-find-match-header
{
    font-family: harmony-sans-bold;
    font-size: 14px;
    text-align: left;
    color: #242b33;
}
.info-find-match
{
    font-family: harmony-sans-regular;
    font-size: 14px;
    text-align: left;
    word-break: break-all;
    word-wrap: break-word;
    min-width: 100px;
    color: #242b33;
}
.padding-info-find-match
{
    padding-left: 25px;
}
.info-number
{
    font-family: harmony-mono-regular;
}
.tbl-info-find-match
{
    padding-top: 30px;
    margin-bottom: 30px;

}
.title-info-find-match
{
    font-family: harmony-sans-regular;
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
    //color: #242b33;
}
.div-find-match-tbl
{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;

    overflow: hidden;
    height: 186px;
}
.tbl-find-match
{
    padding-left: 2px;
    border-radius: 5px;
  background-color: #ffffff;
  border: solid 0.7px #b8c1cc;
}
.tbl2-find-match
{
    padding-left: 2px;
    //padding-bottom: 10px;
    border-radius: 5px;
  background-color: #ffffff;
  border: solid 0.7px #b8c1cc;
}
.tbl-checked-find-match
{
    padding-top: 20px
}
.info-subtotal-tbl
{
    font-family: harmony-mono-regular;
    font-size: 16px;
    color: #242b33;
    padding: 10px 28px;
}
.info-tbl-list
{
    color: #7e8893;
    font-family: harmony-sans-regular;
    font-size: 14px;
    padding: 10px 12px;
    
}
.div-float-right
{
    float: right;
margin-top: 20px;
width: 50%;
  border-radius: 4px;
  background-color: #e7ecf3;
  padding: 23px 27px;
}
.div-float-left{
    float: left;
}
.div-float-right > div{
    display: inline-block;
    
}

.div-float-right > div > div{
    padding-bottom: 30px;
    height: 14px;
    font-size: 14px;
    color: #242b33;
}

.lbl-non-adjustment
{
    color:#4ddc7c;
}

.lbl-adjustment
{
    color:#ff7a7a;
}
.info-sub-div-total
{

    position: absolute;
    right: 0;
}
.div-coa-reconcile
{
    padding-top: 10px;
}
.find-matchtitle
{
    line-height: 2;
}
.icon-helper{
    width: 20px;
    position: absolute;
    top:0;
    height: 20px;
}
.icon-helper2{
    width: 14px;
    height: 14px;
    margin-top:-3px;
    margin-left:5px;
}

.div-find-match-tbl-new
{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;

    overflow: hidden;
    height: var(--height);
}