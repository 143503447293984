.title-one-feed{
    padding-top: 13px;
    text-align: center;
    font-family: "harmony-sans-bold";
    color: #121212;
    font-size: 20px;

}
.content-oneFeed{
    padding-left: 100px;
    padding-right: 100px;
    min-width: 980px;
}
.content-oneFeed-div{
    padding: 30px;
    padding-top: 10px;
}
.content-oneFeed-form{
    float: left;
    width: 50%;
}
.info-tos{
    width: 220px;
    color: #7e8893;
    font-size: 12px;
}
.oneFeed-info-loading-text{
    text-align: center;
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 30px;
    color: #7e8893;
}
.content-oneFeed-form-text{
    width: 200px;
}
.content-oneFeed-form-number{
    width: 200px;
    text-align: left;
}
.content-oneFeed-form-lbl{
    font-family: "harmony-sans-bold";
    color: #121212;
    font-size: 14px;
    margin-top: 20px;
}
.title-lbl{
    font-family: "harmony-sans-bold";
    color: #121212;
    padding-left: 20px;
    font-size: 16px;
}
.onefeed-info{
    font-size: 12px;
}
.content-oneFeed-form-input{
    width: 200px;
}
.content-oneFeed-line{
    border-color: #e6ecf2;
    width: 100%;
    height: 10px;
    margin-top: 20px;
}
.content-oneFeed-form-table > tbody > tr > td{
    padding-top: 8px;
}
.content-oneFeed-form-2
{

}
#tbl-one-feed  >thead > tr > th{
    border-top:1px solid;
    border-color: #b8c1cc;
}

.div-one-feed-step2{
    border:1px solid;
    border-color: #b8c1cc;
}
.popover-cashbank{
    width: 30px;
}
.text-color-red-one-feed{
    color: #ff414a
}
.icon-notbalance{
    height: 20px;
    width: 20px;
}
.selected-onefeed{
    width: 140px;
}
.one-feed-cancel-link
{
    background-color: #ff414a;
    box-shadow: 0 1px 3px 0 #ffa2a6;
    width: 175px;
    font-size: 14px;
}
.one-feed-keepdata-link{
    width: 160px;
    color:#242b33 !important;
    font-size: 14px;
    font-family:"harmony-sans-bold";
    float: left !important;
}
.one-feed-title-popup
{
    font-family:"harmony-sans-bold";
    text-align: left;
    padding-left: 16px;
}
.content-oneFeed-div-summary{
    width: 600px;
    margin-left: 24%;
}
.div-one-feed-step3{
    padding: 30px;
}
#div-one-feed-step3{
    border:1px solid;
    border-color: #b8c1cc;
}
.cancel-step2-onefeed{
    color:#7e8893 !important;
    text-decoration:underline !important;
    &:hover {
    text-decoration: unset !important;
    }
}
.solution-onefeed{
    color:#419aff;
    text-decoration: underline !important;
    &:hover {
        text-decoration: unset !important;
        }
}
.icon-success-step3{
    width: 40px;
    height: 40px;
    float: left;
}

#onefeed-transfer-table > div > table > tbody > tr > td {
    padding: 5px 10px;
}

.datepicker-custom {
    border: solid 0.5px #b8c1cc !important;
    height: 32px !important;
}

.alert-onefeed {
    display: none;
    transition-delay: 2s;
}

.button-visit-mandiri {
    width: 100%;
    font-family: "harmony-sans-bold";
    height: 32px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(126, 136, 147, 0.19);
    border: solid 0.5px #b8c1cc;
    font-size: 14px;
    line-height: 0.75;
    text-align: center;
    padding: 10px 18px 13px 18px;
    color: #242b33;
}

.button-visit-mandiri:visited {
    background-color: #ffffff !important;
}

.button-visit-mandiri:hover {
    box-shadow: 0 3px 2px 0 rgba(126, 136, 147, 0.2);
    border: solid 0.5px #b8c1cc;
    color: #747b84;
}