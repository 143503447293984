.borderbox-title {
    border: 1.5px dashed #b8c1cc;
    // background-color: #f5faff;
    // height: 100px;
    // margin-top: 20px;
    border-radius: 5px;
    position: relative;
    border-style: dashed;
    background: transparent;
}

.borderbox-title .box-title {
    border: 1.5px dashed #b8c1cc;
    border-radius: 5px;
    margin: -18px 0 0 10px;
    // background: #f5faff;
    background: white;
    padding: 5px 10px 5px 10px;
    display: inline-block;
    font-weight: bold;
    position: absolute;
}

.borderbox-title .box-content {
    padding: 25px 10px 10px 10px;
}