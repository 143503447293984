.checkbox {
    width: 14px;
    height: 14px;
    background-color: #b8c3cc;
        &.focus{
            background-color: #419aff;
        }  
}

.checked {
  width: 14px;
  height: 14px;
  background-color: #419aff;
}

.disable-uncheck {
  width: 14px;
  height: 14px;
  background-color: #e7ecf3;
}

.disable-check {
  width: 14px;
  height: 14px;
  background-color: #e7ecf3;
}