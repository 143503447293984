.input-type {
    width: 100%;
    height: 32px;
    font-weight: normal;
    font-family: harmony-sans-regular;
    border: 1px solid #d9d9d9; //solid 1px #000000;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 1px 13px 1px 14px;
    //10px 13px 10px 14px;
    color: #000000;
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    // border: solid 0.5px #b8c1cc;
    // border-radius: 3px;
    // background-color: #ffffff;
    font-size: 14px;
    text-align: left;
        &:focus{
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6);
            border: solid 0.5px #419aff;
        }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color:#b8c1cc;
    }
    &::-moz-placeholder { /* Firefox 19+ */
    color:#b8c1cc;
    }
    &:-ms-input-placeholder { /* IE 10+ */
    color:#b8c1cc;
    }
    &:-moz-placeholder { /* Firefox 18- */
    color:#b8c1cc;
    }
}
.input-type[disabled]{
    background-color: #e7ecf3; 
    color:  #7e8893;
    border: none;
}
.input-type-number
{
    width: 100%;
    height: 32px;
    font-weight: normal;
    font-family: harmony-mono-regular;
    border: 1px solid #d9d9d9; //solid 1px #000000;
    text-align: right;
    padding: 1px 13px 1px 14px; //10px 13px 10px 14px;
    color: #000000;
    border-radius: 3px;
    background-color: #ffffff;
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    font-size: 14px;
        &:focus{
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6);
            border: solid 0.5px #419aff;
        }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color:#b8c1cc;
    }
    &::-moz-placeholder { /* Firefox 19+ */
    color:#b8c1cc;
    }
    &:-ms-input-placeholder { /* IE 10+ */
    color:#b8c1cc;
    }
    &:-moz-placeholder { /* Firefox 18- */
    color:#b8c1cc;
    }

}

.input-type-number[disabled]{
    background-color: #e7ecf3; 
    color:  #7e8893;
    border: none;
}
.col-input-type
{
    position: relative;
}
.btn-find{
    position:absolute;
    width: 20px !important;
    height: 32px;
    right: 0;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}
.icon-find-text{
    width: 18px;
    height: 24px;
    position: absolute;
    top: 3px;
    right: 10px;
}
.icon-clear-findtext{
    width: 7px;
    height: 8px;
    position: absolute;
    bottom: 11px;
    right: 50px; 
}
.icon-clear-findtext-nobutton {
    @extend .icon-clear-findtext;
    right: 14px;
}
.icon-clear-text{
    width: 7px;
    height: 8px;
    position: absolute;
    top: 11px;
    right: 10px;
}
.input-error{
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(240, 46, 55, 0.23) !important;
    border: solid 0.5px #f02d37 !important;
}

.input-type-search
{
    width: 100%;
    height: 32px;
    font-weight: normal;
    font-family: harmony-mono-regular;
    border: 1px solid #d9d9d9; //solid 1px #000000;
    border-radius: 3px;
    text-align: left;
    color: #000000;
    background-color: #ffffff;
    // border: solid 0.5px #b8c1cc;
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    padding-left:14px;
    padding-right:14px;
    font-size: 14px;
        &:focus{
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(65, 154, 255, 0.6);
            border: solid 0.5px #419aff;
        }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color:#b8c1cc;
    }
    &::-moz-placeholder { /* Firefox 19+ */
    color:#b8c1cc;
    }
    &:-ms-input-placeholder { /* IE 10+ */
    color:#b8c1cc;
    }
    &:-moz-placeholder { /* Firefox 18- */
    color:#b8c1cc;
    }

}
.input-type-search-withIcon{
    padding: 1px 60px 1px 14px;
}
.input-type-search[disabled]{
    background-color: #e7ecf3; 
    color:  #7e8893;
    border: none;
}

.col-input-date-new {
    height: 32px !important;
    // width:205px;
    position: relative;
}