.coa-trx-multi{
   // margin: 4px 10px 0px 10px;
   
   // float: left;
    > div {
        min-width: 200px;
        max-width: 500px
    }
}
.coa-trx-multi > .ant-select-selection > .ant-select-selection__rendered > ul > .ant-select-search >.ant-select-search__field__wrap > .ant-select-search__field{
    height: 18px;    
}
.coa-trx-picker{
    float: left;
    margin-left: 10px;
    .ant-input{
        height:34px !important
    }
}

.coa-trx-submit{
    height:34px;
    width: 100px;
    margin-top: 4px;
    float: left;
}

.percent-16 {
    width: 16%;
    vertical-align: top;
}
.percent-10 {
    width: 14%;
    vertical-align: top;
}
.percent-18 {
    width: 18%;
    vertical-align: top;
}
.percent-30 {
    width: 30%;
    vertical-align: top;
}

.coa-tagging-journal{
    // margin: 4px 10px 0px 10px;
    
    // float: left;
     > div {
         min-width: 170px;
         max-width: 170px
     }
 }
 .coa-tagging-journal > .ant-select-selection > .ant-select-selection__rendered > ul > .ant-select-search >.ant-select-search__field__wrap > .ant-select-search__field{
     height: 18px;    
 }