.activate--container
{
     width: 734px !important;
}
.icon-logo-activate {
    width: 25px;
    height: 25px;
}
    .icon-logo-invitation-accept{
        width: 120px;
        height: 120px;
        margin-top: -20px;
        margin-bottom: -40px;
        margin-left: -30px;
    }
    .icon-logo-activate-suggest
    {
        width: 160px; 
        height: 160px;
        margin-bottom: -60px;
        margin-left: -3%;
    }

    .lbl-title-activate
    {
        font-family: harmony-sans-regular;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #242b33;
    }

    .lbl-title-content
    {
         font-family: harmony-sans-regular;
  font-size: 16px;
  text-align: center;
  color: #242b33;
    }
.lbl-title-invitation{
    font-family: harmony-sans-regular;
    font-size: 14px;
    text-align: center;
    color: #242b33;
    margin-top: 40px;
}
.input-resend, .input-resend:focus
{
    
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
}
.icon-logo-activate-content
{
    width: 100px;
    height: 100px;
}
.line-activate
{
    
    width: 250px;
    border-color: #e7ecf3;
    text-align: center;
    margin-left: 200px;
    margin-top: 20px;
}

.footer-activate{
    font-size: 14px;
    position: relative;
    margin-top: 160px;
    align-content: center;
}

.footer-activate-email{
    font-size: 14px;
    position: relative;
    margin-top: 20px;
    align-content: center;
}

.link-reset-password{
    font-family: harmony-sans-bold;
    text-decoration: underline;
}

.link-reset-password:hover{
    font-family: harmony-sans-bold;
    text-decoration: none;
}

.link-invitation-reject{
    text-decoration: underline;
    color: #242b33;
}

.link-invitation-reject:hover{
    text-decoration: none;
    color: #49a9ee;
}

.invitation-box-content{
    width: 440px;
    height: 90px;
    border-radius: 5px;
    color: #419aff !important;
    margin-top: 20px;
    margin-left: 105px;
    padding: 25px;
}

.content-maintenance {
    width: 667px;
    margin:auto;
    text-align: center;
    // overflow: auto;
}

.img-maintenance {
    width: 500px;
    margin-top:70px;
}
.title-maintenance {
    padding-top:29px;
    padding-bottom:27px;
    font-family: harmony-sans-bold;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    color: #242b33;
}

.text-maintenance {
    font-family: harmony-sans-regular;
    font-weight: normal;
    font-size: 18px;
    color: #7e8893;
}