.more-options {
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
}

.more-options:hover {
    background-color: #dddddd
}

.more-icon {
    background-color: #555555;
    cursor: pointer
}