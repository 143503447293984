.col-payment-info
{
  background-color: #f5f8fa;
//height: 405px;
position: relative;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
   padding: 5px 5px;
}
.modal-payment > .ant-modal-content > .ant-modal-body 
{
    padding:  0 !important;
}
.btn-cancel-payment
{
    background-color: #f5f8fa;
}
.col-payment
{
    //width: 40%;
    padding: 5px 20px;
}
.tbl-info-payment
{
    width: 100%;
}
.line-payment
{
border-color: #e7ecf3;
}
.mt--40
{
    margin-top: 40px;
}
.mt--3 {
    margin-top: 3px;
}
.mt--5 {
    margin-top: 5px;
}
.mt--8
{
    margin-top: 8px;
}
.mt--15
{
    margin-top: 15px;
}

.mt---10{
    margin-top: -20px;
}
.mt---25{
    margin-top: -25px;
}
.mt--60
{
    margin-top: 60px;
}
.mt--80
{
    margin-top: 80px;
}
.mt--100
{
    margin-top: 100px;
}
.tbl-info-payment >tr >td
{
    padding: 10px 10px 10px 10px;
}
.btm-modal-payment
{
    padding-top: 50px;
    padding-bottom: 10px;
    padding-right: 30px;
    //position: absolute;
    bottom: 0;
    right: 0;
}
.loss{
    color:#ff7a7a;
}
.gain{
    color:#4ddc7c;
}
.change-rate-payment
{
    background-color: #419aff;
    margin-right: -20px;
    margin-left: -20px;
    margin-top: -6px;
    height: 60px;
}
.loader-rate-payment{
    text-align: center;
}
.loader-rate{
    padding-top: 20px;
}
.font-payment-rate
{
    font-size: 12px;
    float: left;
    padding-left: 20px;
    min-width: 250px;
    font-family: "harmony-sans-regular";
    text-align: left;
    color: #ffffff;
}

.label-rate{
    font-size: 14px;
    font-family: "Harmony-sans-regular";
    text-align: left;
}

.label-modal-rate{
    font-size: 14px;
    text-align: left;
    font-family: "harmony-sans-bold";
    color: #121212;
    margin-top: 13px;
}

.radio-rate{
    padding: 0px 0px 5px 5px;
    float: left;
}

.rate-fix-amount{
    font-family: "Harmony-sans-regular";
    font-size: 14px;
}

.rate-fix-date{
    font-family: "Harmony-sans-regular";
    font-size: 12px;
    color: #7e8893;
    height: 21px;
}