.ant-upload-select-picture-card i 
{
    font-size: 28px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text 
{
    margin-top: 8px;
    font-size: 12px;
    color: #666;
}

.button-upload
{
    width: 140px;
    margin-left: 165px;
    border-radius: 3px;
}

.picture
{
    width: 2px;
    height: 190px;
}
