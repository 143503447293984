$color-dark-ebony-clay: #192B46;

.boarding-overlay {
    position: absolute;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    z-index: 999999;
    // opacity: 0;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.boarding-showElement {
    z-index: 9999999!important;
}

tr.boarding-showElement>td {
    z-index: 9999999!important;
    position: relative;
}

tr.boarding-showElement>th {
    z-index: 9999999!important;
    position: relative;
}

.boarding-disableInteraction {
    z-index: 99999999!important;
    position: absolute;
    background-color: #fff;
    opacity: 0;
}

.boarding-relativePosition {
    position: relative;
}

.boarding-helperLayer {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    z-index: 9999998;
    border-radius: 4px;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.boarding-helperLayer * {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.boarding-helperLayer :before {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.boarding-helperLayer :after {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.boarding-tooltipReferenceLayer {
    font-family: "Helvetica Neue",Inter,ui-sans-serif,"Apple Color Emoji",Helvetica,Arial,sans-serif;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    visibility: hidden;
    z-index: 100000000;
    background-color: transparent;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.boarding-tooltipReferenceLayer * {
    font-family: "Helvetica Neue",Inter,ui-sans-serif,"Apple Color Emoji",Helvetica,Arial,sans-serif;
}

.boarding-helperNumberLayer {
    font-family: "Helvetica Neue",Inter,ui-sans-serif,"Apple Color Emoji",Helvetica,Arial,sans-serif;
    color: #9e9e9e;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.boarding-arrow {
    border: 8px solid transparent;
    content: "";
    position: absolute;
    border-bottom-color: #fff;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.boarding-arrow.top-left {
    top: -15px;
    left: 10px;
}

.boarding-arrow.top-right {
    top: -15px;
    right: 10px;
}

.boarding-arrow.top-middle {
    top: -15px;
    left: 50%;
    margin-left: -7.5px;
}

.boarding-arrow.right-top {
    right: -15px;
    top: 10px;
    transform: rotate(90deg);
}

.boarding-arrow.right-bottom {
    bottom: 10px;
    right: -15px;
    transform: rotate(90deg);
}

.boarding-arrow.right-middle {
    bottom: 50%;
    margin-bottom: -7.5px;
    right: -15px;
    transform: rotate(90deg);
}

.boarding-arrow.bottom-left {
    bottom: -15px;
    left: 10px;
    transform: rotate(180deg);
}

.boarding-arrow.bottom-right {
    bottom: -15px;
    right: 10px;
    transform: rotate(180deg);
}

.boarding-arrow.bottom-middle {
    bottom: -15px;
    left: 50%;
    margin-left: -7.5px;
    transform: rotate(180deg);
}

.boarding-arrow.left-top {
    left: -15px;
    top: 10px;
    transform: rotate(-90deg);
}

.boarding-arrow.left-bottom {
    left: -15px;
    bottom: 10px;
    transform: rotate(-90deg);
}

.boarding-arrow.left-middle {
    left: -15px;
    bottom: 50%;
    margin-bottom: -7.5px;
    transform: rotate(-90deg);
}

.boarding-tooltip {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    background-color: #fff;
    width: fit-content;
    min-width: 300px;
    // max-width: 300px;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 30px rgba(33,33,33,.3);
    box-shadow: 0 3px 30px rgba(33,33,33,.3);
    -webkit-transition: opacity .1s ease-out;
    -o-transition: opacity .1s ease-out;
    transition: opacity .1s ease-out;
}

.boarding-content {
    // padding: 20px;
    padding: 6px;
}

.boarding-tooltip-title {
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-weight: 700;
    float: left;
    line-height: 32px;
}

.boarding-tooltip-header {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
}

.boarding-tooltip-header:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.boarding-tooltipbuttons {
    border-top: 1px solid #e0e0e0;
    padding: 10px;
    text-align: right;
    white-space: nowrap;
}

.boarding-tooltipbuttons:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.boarding-skipbutton {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    color: #616161;
    float: right;
    font-size: 20px;
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    padding: 3.5px 5px;
}

.boarding-skipbutton:focus,.boarding-skipbutton:hover {
    color: #212121;
    outline: 0;
    text-decoration: none;
}

.boarding-disabled {
    color: #9e9e9e;
    border-color: #bdbdbd;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none;
}

.boarding-disabled:focus,.boarding-disabled:hover {
    color: #9e9e9e;
    border-color: #bdbdbd;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: default;
    background-color: #f4f4f4;
    background-image: none;
    text-decoration: none;
}

.boarding-hidden {
    display: none;
}

.boarding-bullets {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.boarding-bullets ul {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    clear: both;
    margin: 0 auto 0;
    padding: 0;
    display: inline-block;
}

.boarding-bullets ul li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    list-style: none;
    float: left;
    margin: 0 2px;
}

.boarding-bullets ul li a {
    -webkit-transition: width .1s ease-in;
    -o-transition: width .1s ease-in;
    transition: width .1s ease-in;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    width: 6px;
    height: 6px;
    background: #ccc;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
}

.boarding-bullets ul li a:focus,.boarding-bullets ul li a:hover {
    width: 15px;
    background: #999;
    text-decoration: none;
    outline: 0;
}

.boarding-bullets ul li a.active {
    width: 15px;
    background: #999;
}

.boarding-progress {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    overflow: hidden;
    height: 10px;
    margin: 10px;
    border-radius: 4px;
    background-color: #e0e0e0;
}

.boarding-progressbar {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    float: left;
    width: 0%;
    height: 100%;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    background-color: #08c;
}

.boardingFloatingElement {
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%;
}

.boarding-fixedTooltip {
    position: fixed;
}

.boarding-hint {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    background: 0 0;
    width: 20px;
    height: 15px;
    cursor: pointer;
}

.boarding-hint:focus {
    border: 0;
    outline: 0;
}

.boarding-hint:hover>.boarding-hint-pulse {
    background-color: rgba(60,60,60,.57);
}

.boarding-hidehint {
    display: none;
}

.boarding-fixedhint {
    position: fixed;
}

@-webkit-keyframes boardingpulse {
    0% {
        -webkit-transform: scale(.95);
        transform: scale(.95);
        -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,.7);
        box-shadow: 0 0 0 0 rgba(0,0,0,.7);
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-box-shadow: 0 0 0 10px transparent;
        box-shadow: 0 0 0 10px transparent;
    }

    100% {
        -webkit-transform: scale(.95);
        transform: scale(.95);
        -webkit-box-shadow: 0 0 0 0 transparent;
        box-shadow: 0 0 0 0 transparent;
    }
}

@keyframes boardingpulse {
    0% {
        -webkit-transform: scale(.95);
        transform: scale(.95);
        -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,.7);
        box-shadow: 0 0 0 0 rgba(0,0,0,.7);
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-box-shadow: 0 0 0 10px transparent;
        box-shadow: 0 0 0 10px transparent;
    }

    100% {
        -webkit-transform: scale(.95);
        transform: scale(.95);
        -webkit-box-shadow: 0 0 0 0 transparent;
        box-shadow: 0 0 0 0 transparent;
    }
}

.boarding-hint-pulse {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 15px;
    height: 15px;
    border-radius: 30px;
    background-color: rgba(136,136,136,.24);
    z-index: 10;
    position: absolute;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
    -webkit-animation: boardingpulse 2s infinite;
    animation: boardingpulse 2s infinite;
}

.boarding-hint-no-anim .boarding-hint-pulse {
    -webkit-animation: none;
    animation: none;
}

.boarding-hint-dot {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background: 0 0;
    border-radius: 60px;
    height: 50px;
    width: 50px;
    position: absolute;
    top: -18px;
    left: -18px;
    z-index: 1;
    opacity: 0;
}
























.divider {
    height: 140px;
}

$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
$blue: #419aff;
$red: hsl(352, 100%, 63%);

.hotspot {
    position: relative;
}

.hotspot--active {
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        right: 0;
        bottom: 0;
        width: 2rem;
        height: 2rem;
        margin: -1rem auto auto -1rem;
        transform-origin: 50% 50%;
        opacity: 0;
        border-radius: 50%;
        background-color: $blue;
        pointer-events: none;
        animation: pulsate 3s $easeOutQuart infinite;
        animation-delay: inherit;
    }
}

.hotspot--active.hotspot--bordered {
    &::after {
        border: 1px solid $blue;
        background-color: transparent;
    }
}

.danger::after {
    background-color: $red;
}

@keyframes pulsate {
    0% {
        transform: scale(0.7);
        opacity: 0.7;
    }

    45% {
        transform: scale(4.5);
        opacity: 0;
    }
}

.h-36 {
    height: 36px;
}

.h-60 {
    height: 60px;
}

.notification-wrap.no-hover-item {
    .notif-dropdown {
        .nd-body {
            ul {
                li {
                    &:hover {
                        cursor: unset;
                        background: unset;
                    }
                }
            }
        }
    }
}

.hover-color {
    &:hover {
        background-color: lighten($color-dark-ebony-clay, 5%);
    }
}