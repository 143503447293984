#account-transaction{
    overflow:unset;
}
.account-transaction-table-view
{
    overflow: unset;
}
.accoun-transaction-add-row-1
{
   
    tr > td:nth-child(4n-3){
        width: 200px;
    }
    tr > td:nth-child(4n-1),
    tr > td:nth-child(4n-2){
        width: 15%
    }
}

.accoun-transaction-transfer-add-row-2{
    tr > td:nth-child(3n-1),
    tr > td:nth-child(3n-2){
        width: 200px;
    }
}
.accoun-transaction-transfer-add-row-1{
    tr > td:nth-child(6n-4),
    tr > td:nth-child(6n-5),
    tr > td:nth-child(6n-3)
    {
        width: 190px;
        min-width: 190px;
    }
    tr > td:nth-child(6n-2){
        width: 190px;
        min-width: 190px;
    }
    tr > td:nth-child(6n-1){
        width: 17%
    }
}