.display-dasboard-type
{  
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 0.7px;
  padding: 15px 12px;
  padding-bottom: 9px;
  display: inline-flex;
  //width: 100%;
  position: relative;
  margin-bottom: 13px;
}
.link-watchlist{
    text-decoration: underline;
    :hover{
        text-decoration: none;
    }
}
.tbl-account-watch
{
    font-size: 14px;
    font-family: "harmony-sans-bold";
    font-weight: normal;
    color: #121212;
}
.border-none{
    border-bottom: none !important;
}
.icon-display-dasboard-type
{
    width: 25px;
    height: 25px;
}
.lbl-title-dashboard-display-content
{
    height: 16px;
    font-family: harmony-sans-regular;
    font-size: 14px;
    font-weight: normal;
    color: #242b33;
    margin-left: 5px;
    width: 37%;
}
.link-number-dashboard
{ 
    font-family: "harmony-mono-regular";
   text-decoration: underline;
   position: absolute;
   right: 10px;
   top: 16px;
    text-align: right;

    height: 16px;
    font-size: 14px;
    font-weight: normal;
    color: #242b33;
    margin-left: 20px;
}
.font-harmony-mono{
    font-family: "harmony-mono-regular"
}
.draft
{
    border-color: #b8c1cc;
}
.duedate
{
    border-color: #ff7a7a;
}

.approved
{
    border-color: #419aff;
}

.paid
{
    border-color: #50e3c2;
}
.list-bank-dashboard
{
    display: inline-block;
}
.tbl-profit-loss
{
    width: 100%;
}
.tbl-profit-loss > tbody > tr >td
{
    padding: 15px 30px;
    padding-bottom: 1px;
}
.tbl-cash-bank
{
    width: 100%;
}
.tbl-cash-bank > tbody > tr >td
{
    padding: 15px 7px;
    padding-bottom: 1px;
}
.lbl-date-profit-loss
{

    font-family: "harmony-sans-bold";
    font-size: 14px;
    font-weight: normal;
    padding-right: 0 !important;
    text-align: right;
    color: #121212;
}
.num-profit-loss
{
   
    font-family: "harmony-mono-regular";
    text-decoration: underline;
    text-decoration-color: #419AFF;
    font-size: 14px;
    text-align: right;
    color: #121212;
}

.num-profit-loss:hover
{
    text-decoration: none;
}
.lbl-profit-loss
{
    font-family: "harmony-mono-regular";
    font-size: 14px;
    text-align: left;
    color: #242b33;
}
.content-profit-loss > td
{ 
  border-bottom: solid 1px #e7ecf3;

  &:first-child
    {
        padding-left: 0;
    }
    &:last-child
    {
        padding-right: 0;
    }
}
.content-acc-watch > td
{ 
  
  &:first-child
    {
        padding-left: 0;
        border-bottom: solid 1px #e7ecf3;
    }
    &:last-child
    {
        padding-right: 0;
    }
}
.content-bank-accounts > td
{ 
  border:none !important;

  &:first-child
    {
        padding-left: 0;
    }
    &:last-child
    {
        padding-right: 0;
    }
}
.text-bank-accounts{
    font-size: 13px;
    color: #7e8893;
    font-family: harmony-sans-regular;
    font-weight: normal;
    max-width: 190px;
}
.text-profitloss-chart{
    font-size: 16px;
    color: #7e8893;
    font-family: harmony-sans-regular;
    font-weight: normal !important;
}
.total-profit-loss{
    font-size: 20px !important;
    font-family: harmony-mono-regular;
    font-weight: normal !important;
}
.text-proloss-legend{
    font-size: 14px;
    color: #7e8893;
    font-family: harmony-sans-regular;
    font-weight: normal !important;
}
.content-watchlist > tr > td
{
    border-bottom: solid 1px #e7ecf3;
    padding: 9px;
    padding-left: 1px;
    padding-right: 1px;
}

.num-watchlist-balance
{
   
    font-family: "harmony-mono-regular";
   // text-decoration: underline;
    text-decoration-color:#419aff; 
    font-size: 14px;
    text-align: right;
    color: #121212;;
}

.num-watchlist-balance:hover
{
    text-decoration: none;
}
.num-proloss-balance
{
   
    font-family: "harmony-mono-regular";
   // text-decoration: underline;
    text-decoration-color:#419aff; 
    // font-size: 14px;
    text-align: right;
    color: #121212;;
}

.num-proloss-balance:hover
{
    text-decoration: none;
}


.num-watchlist-ytd
{
   
    font-family: "harmony-mono-regular";
    text-decoration: underline;
    font-size: 14px;
    text-align: right;
    color: #419aff;;
}

.padding--top--0{
    padding-top: 0px !important;
}
.padding-lr{
    padding-left:12px;
    padding-right:0px;
}
.rectangle-unreconcile{
    width: 31px;
    height: 20px;
    font-size:12px;
    border-radius: 10px;
    border: solid 2px #ff414a;
    color:red;
    background-color: white
}
.rectangle-reconcile{
    width: 31px;
    height: 20px;
    border-radius: 10px;
    font-size:12px;
    border: none;
    background-color: #419aff;
    color: white;
    font-family: "harmony-sans-bold";

    &:hover{
        background-color:#192b46;
    }
}
.center-list{
    margin:auto !important;
    align-content: center;
    display: table;
    min-width: 765px;
    max-width: 765px;
    overflow: auto;
}
.tooltip_bkg{
    background-color: rgba(0, 0, 0, 0.8) !important;
    border-radius: 0px !important;
    padding: '10px';
    border: '0';
}
.connect-account:hover{
    color:#192b46;
}
.width-card{
    width: 400px;
    min-width:380px !important;
}
.width-card2{
    width: 800px;
    min-width:760px !important;
}
.dash-line{
    stroke-dasharray: 2.5;
}
.legend-left{
    text-align: left !important;
}
.legend-expense{
    z-index: 99;
    position: relative;
    right: -59px;
    top: 6px;
    width: 224px !important;
    height: auto !important;
    background-color: transparent !important;
    padding: 0px !important;
}
.legend-expense > div:nth-child(2){
    float: unset !important;
}
.legend-expense > div > div:nth-child(1){
    height: 10px !important;
    width: 10px !important;
    margin-top: 9px
}
.svg-expense{
    position: relative;
    left: -95px;
    // bottom: 237px;
}
// .x {
//     transform: translate(-13px, 0px);
// }
.svg-invoice{
    position: relative;
    left: -2px;
    top: -223px;
}
.svg-proloss{
    position: relative;
    top: -45px;
}
.legend-cashflow{
    font-weight: normal;
    font-size: 14px;
    width: auto !important;
    text-align: left !important;
    position: relative;
    bottom:-141px;
    padding-left: 70px;
}
.legend-cashflow > div > div:nth-child(1){
    height: 10px !important;
    width: 10px !important;
    margin-top: 5px
}
.legend-invoice{
    z-index: 99;
    position: relative;
    right: -30px;
    top: -4px;
    width: 290px !important;
    height: auto !important;
    background-color: transparent !important;
    padding: 0px !important;
}
.legend-invoice > div > div:nth-child(1){
    height: 10px !important;
    width: 10px !important;
    margin-top: 9px
}
.svg-cashflow1{
    position: relative;
    top: -15px;
    fill: #9b9b9b;
    .x{
        transform: translate(-13px, 0px);
    }
}
.svg-cashflow2{
    // position: relative;
    // top: -45px;
    // fill: #9b9b9b;
    .x{
        transform: translate(-7px, 0px);
    }
}
.svg-cashflow3{
    // position: relative;
    // top: -45px;
    // fill: #9b9b9b;
    .x{
        transform: translate(-4px, 0px);
    }
}
.width-bar {
    width: 17px;
}
.svg-paid{
    width: 24.5px;
    height: 77px;
    position: relative;
    bottom: 226px;
    margin-left: 1px;
}
.svg-invoice-empty{
    width: 25.5px;
    height: 185px;
    position: relative;
    bottom: 492px;
    margin-left: 0px;
}
.bottom-95{
    bottom:95px;
}
.bottom-100{
    bottom:100px;
}
.bottom-142{
    bottom:142px;
}
.bottom-193{
    bottom:193px;
}
.bottom-237{
    bottom:237px;
}
.bottom-0{
    bottom:0px;
}
.bottom-15{
    bottom:15px;
}
.bottom-16{
    bottom:16px;
}
.grey-paid{
    background-color:#e6ecf2;
}
.green-paid{
    background-color:rgb(80, 227, 194);
}
.padding--bottom--0{
    padding-bottom: 0px !important;
}
.text-font-10{
    font-size: 10.3px;
}
.react-d3-basics__tooltip_utils{
    z-index: 99;
}
.legend-proloss{
    z-index: 99;
    position: relative;
    width: 290px !important;
    height: auto !important;
    background-color: transparent !important;
    padding: 0px !important;
}
.legend-proloss > div:nth-child(2) > div:nth-child(1){
    height: 10px !important;
    width: 10px !important;
    margin-top: 7px;
    float: right !important;
}
.legend-proloss > div:nth-child(1) > div:nth-child(1){
    height: 10px !important;
    width: 10px !important;
    margin-top: 7px;
}
.legend-proloss > div:nth-child(2){
    top: 55px;
    right: -9px;
    float: right !important;
}
.legend-proloss > div:nth-child(1){
    top: 12px;
}
.legend-proloss > div:nth-child(1) > div:nth-child(2){
    float: left !important;
}
.svg-proloss-empty {
    width: 296px;
    height: 33px;
    position: relative;
    bottom: 88px;
    margin-left: 1px;
}
.cash-bank-card{
    max-width: 346px;
    height: 120vh;
    overflow:auto;
}
.wd-346
{
  width: 346px;
  min-width: 346px !important;
  height: 240vh;
  overflow-y: auto;
  display: flex;
}
.right-sidemenu{
    width: 346px;
    float:right;
    height:250vh;
    background-color: white;
}
.Expenses{
    float:right;
}
@media (min-width:1744px){
    .center-list{
        margin:auto !important;
        align-content: center;
        display: table;
        min-width: 765px;
        max-width: 1140px;
        overflow: auto;
    }
    .right-sidemenu{
        height: 176vh;
    }
    .wd-346{
        height: 110vh;
    }
}
@media (min-width:2144px){
    .center-list{
        margin:auto !important;
        align-content: center;
        display: table;
        min-width: 765px;
        max-width: 1525px;
        overflow: auto;
    }
    .right-sidemenu{
        height: 96vh;
    }
    .wd-346{
        height: 80vh;
    }
}
@media (min-width: 1292px) and (max-width: 1294px){
    .right-sidemenu{
        height:310vh
    }
    .wd-346{
        height: 290vh;
    }
}
@media (min-width: 1296px) and (max-width: 1334px){
    .right-sidemenu{
        height:240vh
    }
    .wd-346{
        height: 220vh;
    }
}
@media screen and (min-width: 1036px) and (max-width: 1291px){
    .right-sidemenu{
        height:190vh
    }
    .wd-346{
        height: 190vh;
    }
}
@media (min-width: 1335px) and (max-width: 1374px){
    .right-sidemenu{
        height:180vh
    }
    .wd-346{
        height: 180vh;
    }
}
@media (min-width: 1375px) and (max-width: 1534px){
    .right-sidemenu{
        height:210vh
    }
    .wd-346{
        height: 210vh;
    }
}
@media (min-width: 1535px) and (max-width: 1727px){
    .right-sidemenu{
        height:195vh
    }
    .wd-346{
        height: 180vh;
    }
}