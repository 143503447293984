.col-calculate-holding
{
     border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #b8c1cc;
  padding: 20px 30px;
  width: 300px;
}
.icon-witholding
{
  height: 15px;
  width: 15px;
  margin-top: 5px;
}