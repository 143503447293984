.billing-container{
    padding: 25px 30px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px #b8c1cc;
}

.billing-inner-container {
    width: 741px;
    border-radius: 5px;
    background-color: #f5f8fa;
    margin-top: 30px;
    padding: 25px 20px
  }

.billing-table {
    width:40%;
    margin-bottom: 55px;
    td{
        width: 50%
    }
    font-size: 14px;
    tr:last-child{
        font-size: 12px
    }
}

.billing-cancel{
    font-size: 14px;
    color: #36404c;
    text-decoration: underline;
    cursor: pointer
}

.billing-history-table{
    font-size: 14px;
    width: 90%;
    margin: 20px !important;
    border-radius: 5px;
    border: 1px solid #ddd;
    tr td:last-child{
        text-transform: capitalize
    }

    td, th {
        width: 16%
    }
}