.fileuploadBank
{
    padding-left: 20px;
}
.btn-uploadbank
{
    position: absolute;
    top: 5px;
}
.btn-upload-relative
{
    position: relative;
}
.lbl-uploadbank-important
{

    color: firebrick;
}
.font-uploadbank
{
    font-size: 14px;
    font-family: "harmony-sans-regular";
}
.list-info-uploadbank > li:before
{
    content: "*  ";
}
.link-download
{
    font-size: 14px;
    font-family: "harmony-sans-regular";    
}

.modal-import-csv > .ant-modal-content > .ant-modal-close {
    display: none;
}