@import './ant-autocomplete.scss';
@import './referral-rewards.scss';
@import './snack-bar.scss';
@import './onboarding.scss';

.ant-btn-group {
    display: inline-flex !important;
}

.ant-dropdown-trigger {
    top: auto !important;
}

.ant-select-selection-item {
    // text-align: center;
    padding-right: 8px !important;
}

.ant-select-selector {
    padding-left: 8px !important;
}