.purchase-plan-container{
    background-color: #f5f7fa;
    overflow: auto;
    height: 100%;
}

//Header Page
.purchase-plan-header{
    width: 100%;
    height: 50px;
    text-align: center;
    font-family: "harmony-sans-regular";
    font-size: 16px;
    padding: 10px;
    background-color: #ffffff;
}

.purchase-plan-title{
    margin-top: 3px;
    color: #121212;
    font-size: 16px;
}

.purchase-plan-left{
    padding: 5px;
    margin-top: 1px;
    margin-left: 10px;
}

.purchase-plan-left{
    float: left;
}

.purchase-plan-close{
    width:  15px;
    height: 15px;
}

//End of Header page

//Content Page
//Form Purchase
.container-content{
    padding: 100px 0px 20px 0px;
}
.purchase-plan-content{
    position: relative;
    margin: 0px auto;
    width: 1160px;
}

.purchase-content-left{
    float: left;
    padding-left: 30px;
}
.form-plan-detail{
    width: 782px;
    min-width: 782px;
    margin: 0px auto 20px auto;
}

.form-plan-selected{
    width: 140px !important;
    margin-bottom: 20px;
}

.form-purchase-link{
    // text-decoration: underline;
    font-size: 14px;
    margin-bottom: 40px
}

.form-purchase-link2{
    // text-decoration: underline;
    font-size: 12px;
    margin-bottom: 40px
}
.form-purchase-link3{
    // text-decoration: underline;
    font-size: 14px;
    margin-bottom: 10px
}

.form-purchase-price{
    font-family: "harmony-sans-bold";
    font-size: 20px;
    color: #121212;
}

.subs-oval {
    width: 10px;
    height: 10px;
    border: solid 1px white;
    border-radius: 5px;
    background-color: red;
}

.new-payment-icon{
    width: 30px;
    height: 30px;
    float: left;
    margin-top: 11px;
    margin-left: 2px;
}

.new-purchase-plan-content{
    position: relative;
    margin: 0px auto;
    width: 600px;
}

.new-purchase-plan-billing {
    border: solid 0.5px #ddd;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    background-color: #fff;
}

.new-container-step{
    position: relative;
    top: 35px;
    width: 31%;
    margin: 0 auto;
}

.new-container-step > .ant-steps > .ant-steps-item > .ant-steps-step > .ant-steps-main > .ant-steps-title {
    background-color: #f5f7fa;
}

.new-container-step > .ant-steps > .ant-steps-item > .ant-steps-step > .ant-steps-head{
    background-color: #f5f7fa;
    font-size: 14px;
}

.new-container-step > .ant-steps > .ant-steps-item > .ant-steps-step > .ant-steps-main > .ant-steps-title{
    font-size: 14px;
}

.new-purchase-billing-detail {
    padding: 20px 20px;
    border-bottom: 1px solid #b8c1cc;
}


.new-payment-step2-detail{
    margin: -10px -30px -20px -30px;
    padding: 20px 30px; 
}


.new-payment-step2-detail > p {
    margin-bottom: 20px;
    font-family: "harmony-sans-regular";
    font-size: 14px;
}

.new-payment-step2-detail > p > span{
    margin-bottom: 20px;
    font-family: "harmony-sans-bold";
    font-size: 14px;
    color: #121212;
    float: right;
}

.new-form-payment-method{
    width: 660px;
    min-width: 660px;
    margin: 0px auto;
}

.new-step2-content-body {
    width: 1031px;
    margin: 0px auto;
}

.new-form-summary{
    width: 336px;
    min-width: 316px !important;
    margin: 0px auto;
}

.new-payment-card-content{
    //background-color: #f5f7fa;
    margin: 20px -10px -20px -20px;
    padding: 20px 50px 20px 25px;
    border-top: 1px solid #b8c1cc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
//End of Form Purchase

//Form Payment method
.form-payment-method{
    width: 782px;
    min-width: 782px;
    padding-top: 5px;
    margin: 0px auto;
}

.payment-transfer{
    border: 1px solid #b8c1cc;
    border-radius: 5px;
    padding: 10px 10px 20px 20px;
    margin-bottom: 20px;
}

.payment-card{
    border: 1px solid #b8c1cc;
    border-radius: 5px;
    padding: 10px 10px 20px 20px;
}

.payment-card-cc {
    border: 1px solid #b8c1cc;
    border-radius: 5px;
    padding: 10px 10px 0px 20px;
}

.payment-card-content{
    background-color: #f5f7fa;
    margin: 20px -10px -20px -20px;
    padding: 20px 50px 20px 50px;
    border-top: 1px solid #b8c1cc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.payment-card-info > #card-detail {
    float: left;
    width: 50%;
}

.payment-card-info > #card-detail-button {
    float: left;
    width: 50%;
    font-size: 14px;
    margin-top: 19px;
}

.payment-card-info > #card-detail-button > #card-detail-edit {
    margin-right: 10px
}

.payment-card-info {
    width: 300px;
    padding: 10px 20px 10px 0px; 
}

.payment-card-button {
    border-top: 1px solid #b8c1cc;
    margin: 20px -10px 0px -20px;
    padding: 15px;
}

.payment-card-remove-cancel {
    width: 80px !important;
    font-family: "harmony-sans-bold";
    color: #242b33;
    float: right !important;
    padding: 10px 0px 13px 0px;
    margin-right: 10px;
    text-align: center;
    border: 1px solid #e6ecf2;
}

.payment-card-container-button {
    background: #f5f7fa;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 15px;
    margin: 0px -31px -21px;
}

.payment-card-remove > .ant-modal-content > .ant-modal-header {
    border-bottom: none !important;
}

.payment-card-text-delete {
    font-family: "harmony-sans-regular";;
    font-size: 14px;
    padding-left: 27px;
    margin-top: -20px;
    margin-bottom: 35px;
    color: #121212 !important;
}

.payment-card-remove-title{
    font-family: "harmony-sans-bold";
    font-size: 16px;
    text-align: left;
    color: #121212;
}

.payment-radio{
    padding-left: 5px;
    float: left;
}

.payment-icon{
    width: 25px;
    height: 25px;
    float: left;
    margin-top: 11px;
    margin-left: 5px;
}

.payment-icon-label{
    font-family: "harmony-sans-bold";
    font-size: 14px;
    margin-top: 14px;
    margin-left: 15px;
    color: #000000;
}

.payment-icon-label-dis{
    font-family: "harmony-sans-bold";
    font-size: 14px;
    margin-top: 14px;
    margin-left: 15px;
    color: #7e8893;
}

.logo-master-visa{
    width: 60px;
    height: 30px;
    float: right;
    margin-top: 10px;
}

.saas-button-continue {
    width: 210px;
    margin: 15px 189px 50px auto;
    float: right;
}
//End of Payment method

//Form Summary
.form-summary{
    width: 336px;
    min-width: 316px !important;
}

.form-summary-package{
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e6ecf2;
    font-family: "harmony-sans-regular";
    font-size: 14px;
}

.form-summary-amount {
    float: right;
    color: #36414d;
    font-family: "harmony-sans-bold";

}

.form-summary-total{
    padding-top: 10px;
    font-family: "harmony-sans-bold";
    font-size: 16px;
    color: #36414d;
}

.form-summary-agreement{
    margin-top: 45px;
}

.form-summary-agreement > .agreement-checkbox {
    float: left;
}

.form-summary-agreement > .agreement-text {
    width: 220px;
    padding-left: 15px;
    float: left;
}

.prorate-info{
    padding: 10px;
    font-size: 12px;
    margin-top: 20px;
    border: 1px solid #419aff;
    background-color: #f5f7fa;
    color: #419aff;
}
//End of Form Summary
//End of Content

//Modal Compare Package
.package-compare{
    padding: 20px 80px 70px 80px;
}

.compare-pack-title{
    text-align: left;
    font-family: "harmony-sans-bold";
    font-size: 16px;
}

.package-compare-basic{
    width: 30%;
    float: left;
    padding-top: 25px;
}

.package-compare-essen{
    width: 37%;
    float: left;
    margin-left: 17px;
}

.package-compare-pro{
    width: 30%; 
    float: right;
    padding-top: 25px;
}

.package-compare-button{
    margin-top: 55px;
    margin-left: -12px;
    width: 201px;
}

.package-compare-button-center{
    margin-top: 26px;
    margin-left: 2px;
    width: 201px;
}

//End of Modal Compare Package

//Payment Step 2
.step2-content-head {
    margin: 0px auto;
}
.step2-content-body {
    width: 870px;
    margin: 0px auto;
}
.purchase-container-step2{
    width: 1160px;
    margin: 0px auto 20px auto;
    position: relative;
}
.payment-step2{
    width: 500px;
}
.payment-step2-detail{
    margin: -10px -30px -20px -30px;
    padding: 20px 30px; 
    border-bottom: 1px solid #b8c1cc;
}

.payment-step2-price{
    padding-top: 30px;
}

.step2-price-package{
    font-family: "harmony-sans-regular";
    font-size: 14px;
    margin-top: 30px;
}

.step2-price-total{
    font-family: "harmony-sans-bold";
    font-size: 16px;
    color: #121212;
    margin-top: 18px;
}

.payment-step2-promo {
    margin-bottom: 30px;
    margin-top: 20px;
    width: 100%;
    text-align: right;
}

.payment-step2-promo > a {
    font-size: 16px;
    font-family: "harmony-sans-regular";
}

.payment-content-promo {
    margin-top: 10px;
}

.payment-content-promo > .message {
    float: right;
    margin-right: 100px;
    font-size: 12px;
    font-family: "harmony-sans-regular";
    color: #ff7a7a;
}

.payment-content-promo > .message-success {
    margin-top: 5px;
    font-size: 12px;
    font-family: "harmony-sans-regular";
    color: #4ddb7c;
}

.step2-button-back{
    border: none;
    background-color: #f5f7fa;
    font-family: "harmony-sans-bold";
    font-size: 16px !important;
    margin-left: 156px;
    padding: 10px 20px 0px 14px;
    font-size: 14px;
    line-height: 0.75;
    text-align: center;
    color: #121212;
}

.step2-lock-ico{
    margin-right: 10px;
    margin-top: -2px;
}

.payment-step2-detail > p {
    margin-bottom: 20px;
    font-family: "harmony-sans-regular";
    font-size: 14px;
}

.payment-step2-detail > p > span{
    margin-bottom: 20px;
    font-family: "harmony-sans-bold";
    font-size: 14px;
    color: #121212;
    float: right;
}

//End of Payment Step 2

//Payment Confirmation
.payment-confirmation{
    width: 500px;
    position: relative;
    margin: 25px auto 25px auto;
    font-family: "harmony-sans-regular"
}

.payment-confirmation-title{
    font-family: "harmony-sans-bold" !important;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.75);
}

.payment-confirmation-detail > p {
    font-family: "harmony-sans-regular";
    font-size: 14px;
    text-align: center;
}

.payment-confirmation-detail > p > span{
    font-family: "harmony-sans-bold";
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
}

.payment-confirmation-container{
    background-color: #f5f7fa;
    padding: 22px 10px 22px 10px;
    text-align: center;
    font-family: "harmony-sans-regular";
}

.payment-confirmation-price{
    font-size: 20px;
    font-family: "harmony-sans-bold";
    color: #419aff;
    margin-top: 10px;
    margin-bottom: 20px;
}

.payment-confirmation-info{
    font-size: 12px;
    color: #7e8893;
    font-style: italic;
}

.payment-confimation-amount{
    font-family: "harmony-sans-regular";
    font-family: 14px;
}

.payment-confirmation-harmoni{
    padding: 18px 0px;
    margin-top: 20px;
    border-top: 1px solid #e6ecf2;
    border-bottom: 1px solid #e6ecf2;

}

.harmoni-rek-box{
    border: 1px solid #e6ecf2;
    border-radius: 5px;
    padding: 28px 25px;
}

.payment-confirmation-harmoni > p{
    font-family: "harmony-sans-bold";
    font-size: 14px;
    color:#7e8893;
    text-align: center;
    margin-bottom: 10px;
}

.harmoni-rek-box > .float-left > span{
    font-family: "harmony-sans-bold";
    color: #000000;
}

.payment-confirmation-upload > p{
    font-family: "harmony-sans-bold";
    font-size: 14px;
    color:#7e8893;
    text-align: center;
    margin-top: 23px;
}

.upload-text{
    font-family: "harmony-sans-regular";
    font-size: 12px;
    color: #7e8893;
    text-align: center;
}

.upload-button{
    width: 256px;
    margin-top: 24px;
    margin-left: 93px;
}

.payment-confirmation-link{
    text-decoration: underline;
    margin: 89px auto 0px auto;
    width: 155px;
}

.payment-confirmation-link:hover {
    text-decoration: none;
}

.payment-modal-proof{
    font-size: 20px;
    font-family: "harmony-sans-bold";
    color: #7e8893;
    text-align: center;
}
//End of payment confirmation

//Modal Upload Proof
.row-button{
    margin-top: 20px;
}

.button-upload {
    position: absolute;
    opacity: 0;
    overflow-x: hidden;
    padding: 8px 0;
    z-index: -1;
    border-radius: 100%;
    margin-bottom: 20px;
}

.button-upload + label {
    display: inline-block;
    width: 131px;
    font-family: "harmony-sans-bold";
    height: 34px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(126, 136, 147, 0.19);
    border: solid 0.5px #b8c1cc;
    font-size: 16px;
    line-height: 0.75;
    text-align: center;
    padding: 11px 18px 13px 18px;
    cursor: pointer;
    color: #242b33;
        &:focus{
            
            background-color: #e7ecf3;
            border: solid 0.5px #b8c1cc;
        }
        &:hover{
        box-shadow: 0 3px 2px 0 rgba(126, 136, 147, 0.2);
        border: solid 0.5px #b8c1cc;
        color: #747b84;
        }
}

.upload-img-name{
    font-family: "harmony-sans-regular";
    font-size: 14px;
}

.upload-img-del{
    font-family: "harmony-sans-regular";
    font-size: 12px;
    color: #ff414a;
    margin-left: 15px;
}

.upload-img-del:hover{
    font-family: "harmony-sans-regular";
    font-size: 12px;
    color: #ff414a;
}
.upload-img-del > img{
    width: 7px;
    height: 7px;
    margin-bottom: 1px;
}
//
.logo-bank{
    width: 64px;
    height: 18px;
    margin-top: 15px;
    margin-right: 30px;
}

.container-step{
    position: relative;
    top: 35px;
    width: 40%;
    margin: 0 auto;
}

.container-step > .ant-steps > .ant-steps-item > .ant-steps-step > .ant-steps-main > .ant-steps-title {
    background-color: #f5f7fa;
}

.container-step > .ant-steps > .ant-steps-item > .ant-steps-step > .ant-steps-head{
    background-color: #f5f7fa;
}

.container-content-confirm{
    padding: 20px 0px 20px 0px;
}

.ml-min-50{
    margin-left: -50px;
}

.icon-order-success{
    width: 100px;
    height: 100px;
}