.topbar{
    position: static;
    //position: fixed;
    //z-index: 1;
    top:0px;
    //left:240px;
    padding-left:200px; 
    height: 52px;
    background-color: #ffffff;
    width: 100%;
    
    border-bottom: 1px #e7ecf3 solid;
}
.toggel-icon
{
    width: 24px;
    height: 24px;
}
.col-topbar
{
    border-right:  1px #e7ecf3 solid ;
    width: 60px;
    height: 52px;
    padding: 15px;
    float: left;
}
.trial-day
{
  height: 16px;
  font-family: harmony-sans-semibold;
  font-size: 16px;
  text-align: left;
  color: #121212;
  
    padding-left: 35px;
    padding-top: 13px;
}
.div-icon-language {
    margin-top: -7px;
}

.icon-dropdown-profile {
    width: 10px;
    height: 10px;
    //position: absolute;
    right: 10px;
    margin-bottom: -3px;
    margin-left: 13px;
}

.icon-menu-language-en{
    width: 12px;
    height: 12px;
    margin-left: 63px;
    margin-right: 0px;
}

.icon-menu-language-id{
    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-right: 0px;
}

.icon-dropdown-language {
    width: 8px;
    height: 8px;
    //position: absolute;
    right: 8px;
    margin-bottom: 5px;
    margin-left: 7px;
}
.col-profile{
     // width: 182px;
    height: 34px;
    float: right;
    position: inherit;
    padding-top: 15px;
    padding: 15px 12px 15px;
    right: 12px;
//    text-align: right;
}
.col-notiff
{
    width: 35px;
  height: 34px;
  position: relative;
  float: left;
  margin-right: 11px;
}
.oval
{width: 11px;
    height: 11px;
  background-color: #d0011b;
  border: solid 1.2px #ffffff;
      border-radius: 10px;
          position: absolute;
    right: 11px;
}
.profile-div{
    //width: 100%
    float: right;
}
.activate
{
    text-decoration: underline;
}
.profile{
    text-decoration: none;
    color: black;
}
.lblusername
{
    //width: 100%;
    //height: 14px;
    font-family: harmony-sans-semibold;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    max-width: 137px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #121212;
    font-weight: normal;
}
.box-profile{
    width: 142px;
  height: 128px;
  position: absolute;
  border-radius: 4px;
   width: 142px;
   right: 11px;     
 height: 96px;
 border-radius: 3px;
 background-color: #ffffff;
 box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

}
.box-profile >div{
  width: 142px;
  height: 32px;
  border-radius: 4px;
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 3px;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #242b33;
}
.content-topbar
{
    display: flex;
    width: 100%;
    // display: -moz-grid;
    min-height: 52px;
    margin:0;
}
.subscrip
{
    // padding-left: 20px;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.div-profile-bar
{
    border-left: 1px solid #e6ecf2;
    width: 310px;
    // overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
}
.div-language-bar
{
    border-left: 1px solid #e6ecf2;
    width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    // &:hover{
    //     background-color: #419aff;
    //     color:#ffffff;
    // }
}
.ant-dropdown-link
{
    display: inline-flex;
}

.div-language-bar2 {
    background-color: #419aff;
    border-left: 1px solid #e6ecf2;
    width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    // &:hover{
    //     background-color: #419aff;
    //     color:#ffffff;
    // }
}