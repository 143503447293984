@import '../base/variables.scss';

.loading--container {
    height: 80vh;
}

.loading--button {
    border-radius: 5px;
    width: 100px;
    height: 48px;
    background-color: $colorGreen;
}

.loading--placeholder {
    background-color: $blueDarken;
    height: 24px;
}

.loading--title {
    position: absolute;
    width: 320px;
    left: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.loading--title-right {
    width: 350px;
    height: 25px;
    margin-bottom: 16px;
}

.loading--content
{
    background-color:rgba(0, 0, 0, 0.3);
   // opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 99999;
}
.loading-content
{
    width: 374px;
    height: auto;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 50%;
    margin: -150px 0 0 -150px;
    left: 48%;
}
.lbl-info-loading
{
    font-size: 20px;
    color: #419aff;
    text-align: center;
}
.loading--content > img
{
    top: 35%;
    position: absolute;
    right: 50%;
    height: 100px;
    width: 150px;
}