.barline-chart-container {
    font: 11px harmony-sans-regular;
    font-weight: 100;
}

.axis path, .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}

// .bar {
//     fill: steelblue;
// }

.x.axis path {
    display: none;
}
