.col-header-note
{
  
  height: 43px;
  font-family: 'harmony-sans-regular';
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #242b33;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #ffffff;
  border-bottom: solid 0.5px #b8c1cc;
  padding: 8px 12px 13px 12px;
}
.content-note
{
    border-radius: 5px;
  background-color: #f5f8fa;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  border: solid 0.5px #b8c1cc;
}
.txt-right
{
    float: right
}
.txt-left{
    float: left
}
.lbl-titlenote
{
    
    font-size: 16px;
    font-weight: bold;
    color: #121212;
    padding-left: 20px;
    //padding:13px 12px 13px 12px;
}
.icon-history
{
    width: 20px;
    height: 20px;
    margin: 5px 10px 15px 10px;
}
.history-icon-lock{
    width: 14px;
    height: 14px;
    margin: 5px 10px 15px 10px;
}
.content--note-table
{
    padding: 24px 50px 24px 50px;
}
.content--note-contact
{
    padding: 24px 20px 24px 20px;
}
.content--inventory-trans-table
{
    padding: 50px 20px 50px 20px;
}
.btn-add-note
{
     width: 142px;
  height: 34px;
  margin-bottom: 25px;
}
.txt-bold
{
    font-weight: bold;
    font-size: 14px;
    color: #121212;
}
.add-note-text
{
    width: 449px;
}