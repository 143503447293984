.cn-sales-add-row-1{
    tr > td:nth-child(5n-2),
    tr > td:nth-child(5n-4),
    tr > td:nth-child(5n-3),
    tr > td:nth-child(5n-1){
        width: 19%
    }
}

.cn-sales-add-row-2{
    tr > td:nth-child(5n-3),
    tr > td:nth-child(5n-5),
    tr > td:nth-child(5n-4){
        width: 19%
    }
    tr > td:nth-child(5n-1){
        width: 19%
    }
}