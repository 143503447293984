.report-header
{
    background-color: white;
    text-align: center;
    padding: 30px;
    margin-top: 10px;
}
.report-header > div
{
    padding: 5px;
}
.report-subtotal-value
{
    margin-left: 20%;
}
.subtotal-padding50
{
        padding-left: 50px;
}
.report-subheader-view{padding-left: 0px;}
.report-left-display
{
    text-align: left;
}
.btn-submit{
    padding: 15px;
}
.display-margin-top
{
 margin-top: 50px;   
}
.display-margin-top-dasboard
{
 margin-top: 150px;   
}
.background-color-white
{
    background-color: white;
}
.points{
    widows: 95%;
    padding-top: 30px;
}
.points >input
{
    width: 95%;
    margin: 20px;
}
.repot-selected-content
{
    padding-left: 20px;
    padding-right: 20px;
}
output { 
  position: absolute;
  background-image: linear-gradient(top, #444444, #999999);
  width: 40px; 
  height: 30px; 
  text-align: center; 
  color:black; 
  border-radius: 10px; 
  display: inline-block; 
  font: bold 15px/30px Georgia;
  bottom: 175%;
  left: 0;
  margin-left: -1%;
}
output:after { 
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #999999;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}
.raphael-group-103-creditgroup> text
{
    display:none;
}
.content--table-header >th{
    
    padding-bottom: 6px;
}

.setting-tags {
    background: white;
    position:fixed;
    // width:calc(100% - 235px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    z-index: 10;
}

.top-setting{
    font-size: 16px
}