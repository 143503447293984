.rpttrx-table{
    tr  > th:nth-child(8n){
        width: 16%
    }
    tr  > th:nth-child(8n-1){
        width: 16%
    }
    tr  > th:nth-child(8n-2){
        width: 10%
    }
    tr  > th:nth-child(8n-3){
        width: 12%
    }
    tr  > th:nth-child(8n-4){
        width: 10%
    }
    tr  > th:nth-child(8n-5){
        width: 12%
    }
    tr  > th:nth-child(8n-6){
        width: 12%
    }
    tr  > th:nth-child(8n-7){
        width: 12%
    }

    tr  > td:nth-child(8n){
        width: 16%
    }
    tr  > td:nth-child(8n-1){
        width: 16%
    }
    tr  > td:nth-child(8n-2){
        width: 10%
    }
    tr  > td:nth-child(8n-3){
        width: 12%
    }
    tr  > td:nth-child(8n-4){
        width: 10%
    }
    tr  > td:nth-child(8n-5){
        width: 12%
    }
    tr  > td:nth-child(8n-6){
        width: 12%
    }
    tr  > td:nth-child(8n-7){
        width: 12%
    }
}