.top-info
{
    border-top: 1px solid;
    height: 100px;
    border-bottom: 1px solid;
}
.tbl-preview
{
    padding-bottom: 20px;
}
.icon-preview
{   
    width: 220px;
    height: 50px;
}
.font
{
    font-size: 16px;
    font-family: "harmony-sans-regular";
    padding-right: 0px;
}
.frame-display
{
    
    width: 100%;
    height: 500px;
}

.pdf-frame-display
{
    width: 800px;
    height: 800px;
}
.pdf-frame-full
{
    width: 100%;
    height: 100%;
}
.pdf-frame-expense
{
    width: 800px;
    height: 620px;
}
.header-value
{
    float: right;
    text-align: right;
    width: 150px;
}
.top-header
{
    padding-top: 40px;
}
.header-field
{
    width: 50px;
    text-align: left;
}
.header-col
{
    display: inline-flex;
    text-align: right;
    width: 200px;
}