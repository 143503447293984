@import '../base/variables.scss';

.sidebar--overlay {
    z-index: 0;
    float: left;
    width: 240px;
    height: 100vh;
    background-color: $darkWhite;
    overflow: hidden;
    box-shadow: inset -1px 0 0 0 $darkerWhite;
}

.sidebar--navbar-icon {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 48px;
}

.sidebar--navbar-icon > img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-filter: blur(7px);
    filter: blur(7px);
}

.sidebar-harmony--logo {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-filter: blur(3px);
    filter: blur(3px);
}

.sidebar--navbar-notif:before {
    content: '';
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    background-color: $colorGreen;
    width: 8px;
    height: 8px;
}

.main--container {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(87,155,232,0.90);
    padding: 50px 0;
    z-index: 1;
    overflow: scroll;
}

.inner--container {
    position: absolute;
    left: 50%;
    max-width: 1600px;
    width: 100%;
    padding: 0 32px;
    padding-bottom: 50px;
    margin: 0 auto;
    z-index: 3;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
}

.warning--container {
    padding-top: 100px;
}

.main--header {
    display: block;
    margin-bottom: 24px;
}

.main--header-btm {
    margin-top: 48px;
}

.main--header-inner {
    width: 70%;
}

.main--header-right {
    position: relative;
    height: 48px;
    display: block;
}

.crud--title {
    color: $white;
    display: inline-block;
    margin: 10px 0;
}

.crud--status {
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    font: 500 14px $font-primary;
    letter-spacing: 1px;
    color: $white;
    text-transform: uppercase;
    background-color: $blueDarken;
    padding: 6px 9px;
    border-radius: 3px;
}

.form--options-link {
    font: 400 20px $font-primary;
    color: $white;
    margin-right: 32px;
}

.button--header-left {
    margin-right: 32px;
}

.main--container-left {
    background-color: $white;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12), 0 1px 0 0 rgba(0,0,0,0.08);
    border-radius: 5px;
    padding: 35px 32px;
}

.main--container-right {
    padding: 16px 32px;
    color: $white;
    font-family: $font-primary;
}

.main--container-right > h5 {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 16px;
}

/* CRUD FORMS */
.form--container > .row {
    margin-bottom: 15px;
}

.form--title-container {
    position: relative;
    display: block;
    padding: 10px 0;
    box-shadow: inset 0 -1px 0 0 $lightGrey;
    margin-bottom: 16px;
}

.form--title {
    font: 500 20px $font-primary;
    color: $colorBlack;
    vertical-align: middle;
}

.form--title-checkbox {
    margin-right: 8px !important;
}

.form--help {
    float: right;
    font: 500 16px $font-primary;
    color: $muteGrey;
    text-decoration: underline;

    &:hover {
        color: $muteGrey;
    }
}

.form--link-action {
    color: $colorBlue;
    font: 500 16px $font-primary;
    margin-left: 15px;

    &:hover {
        text-decoration: underline;
        color: $colorBlue;
    }
}

.form--label {
    display: block;
    font: 400 16px $font-primary;
    color: $colorBlack;
    margin-bottom: 8px;
}

.form--label-req {
    color: $colorRed;
}

.form--label-med {
    display: inline-block;
    font-size: 20px;
}

.tracked {
    font-weight: 500;
}

.form--desc {
    display: block;
    font: 400 14px $font-primary;
    color: $muteGrey;
    margin-bottom: 7px;
}

.inline--desc {
    display: inline-block;
}

.inline--left {
    margin-right: 8px;
}

.inline--right {
    margin-left: 8px;
}


.form--select {
    border: 1px solid $lightGrey;
    border-radius: 3px;
    background-color: $white;
    //width: 100%;
    height: 42px;
    font: 400 14px $font-primary;
}
.width-100
{
    width: 100%;
}
.form--select-add {
    width: 85%;
    height: 42px;
    font: 400 14px $font-primary;
    border: 1px solid $lightGrey;
    border-radius: 3px;
    margin-left: 28px;
    background-color: $white;
}

.form--input {
    border: 1px solid $lightGrey !important;
    height: 42px;
    margin-bottom: 5px;
    font: 400 14px $font-primary;
}

.form--input-sml {
    width: 30%;
}

.form--input-med {
    width: 60%;
}

.form--input-xl {
    width: 80%;
}

.form--input-full {
    width: 100%;
}

.form--input-long {
    height: 90px;
}

.form--radio {
    display: block;
    padding: 5px 0;
}

.form--input-file {
    position: absolute;
    opacity: 0;
    overflow-x: hidden;
    padding: 8px 0;
    z-index: -1;
    border-radius: 100%;
}

.form--input-file2 {
    position: absolute;
    opacity: 0;
    overflow-x: hidden;
    padding: 8px 0;
    z-index: -1;
    border-radius: 100%;
    margin-bottom: 20px;
}

.form--input-file2-disabled {
    position: absolute;
    opacity: 0;
    overflow-x: hidden;
    padding: 8px 0;
    z-index: -1;
    border-radius: 100%;
    margin-bottom: 20px;
    cursor: not-allowed;
}

.form--input-file + label {
    display: inline-block;
    margin:auto;
    width: 160px;
    font-family: harmony-sans-regular;
    height: 34px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(126, 136, 147, 0.19);
    border: solid 0.5px #b8c1cc;
    font-size: 16px;
    font-weight: normal;
    line-height: 0.75;
    text-align: center;
    padding: 11px 18px 13px 18px;
    color: #242b33;
        &:focus{
            
            background-color: #e7ecf3;
            border: solid 0.5px #b8c1cc;
        }
        &:hover{
        box-shadow: 0 3px 2px 0 rgba(126, 136, 147, 0.2);
        border: solid 0.5px #b8c1cc;
        color: #747b84;
        }
}
.form--input-file2 + label {
    display: inline-block;
    width: 131px;
    font-family: harmony-sans-regular;
    height: 34px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(126, 136, 147, 0.19);
    border: solid 0.5px #b8c1cc;
    font-size: 16px;
    line-height: 0.75;
    text-align: center;
    padding: 11px 18px 13px 18px;
    cursor: pointer;
    color: #242b33;
        &:focus{
            
            background-color: #e7ecf3;
            border: solid 0.5px #b8c1cc;
        }
        &:hover{
        box-shadow: 0 3px 2px 0 rgba(126, 136, 147, 0.2);
        border: solid 0.5px #b8c1cc;
        color: #747b84;
        }
}

.form--input-file2-disabled + label {
    display: inline-block;
    width: 131px;
    font-family: harmony-sans-regular;
    height: 34px;
    border-radius: 4px;
    background-color: #e7ecf3;
    box-shadow: 0 2px 2px 0 rgba(126, 136, 147, 0.19);
    font-size: 16px;
    line-height: 0.75;
    text-align: center;
    padding: 11px 18px 13px 18px;
    cursor: not-allowed;
    color: #7e8893;
        &:focus{
            
            background-color: #e7ecf3;
            border: solid 0.5px #b8c1cc;
        }
        &:hover{
        box-shadow: 0 1px 1px 0 rgba(126, 136, 147, 0.2);
        color: #747b84;
        }
}

.form--option-label {
    font: 400 14px $font-primary;
    margin-left: 10px;
}

.form--calendar {
    position: relative;
    display: block;
}

.form--calendar-container {
    position: absolute;
    top: 50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 5;
}
.form--table {
    width: calc(100% + 64px);
    table-layout:fixed;
    margin-left: -32px;
    margin-bottom: 20px;
}
.dis-block-line-h-25
{
    display: block;
    line-height: 30px;
}
.profile-picture {
    border-radius: 100%;
}

.file--icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    border-radius: 100%;
}


.file--placeholder {
    background-color: $darkWhite;
    height: 24px;
}

.file--placeholder-name {
    width: 65px;
    margin-bottom: 8px;
    margin-top: -2px;
}

.file--placeholder-desc {
    width: 240px;
}

.file--upload-bar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: block;
    height: 5px;
}

.file--upload-bar > span {
    display: block;
    height: 100%;
    background-color: $colorBlue;
    overflow: hidden;
    position: relative;
}

.file--options {
    padding: 8px 10px;
}

.file--download {
    float: right;
}

.form--file-upload {
    float: left;
    font: 400 14px $font-primary;
    color: $muteGrey;
    padding: 8px 0;
}

.form--upload {
    width: calc(100% + 32px);
    list-style-type: none;
    margin-top: 20px;
}
/* hidden border at bot when upload
.form--upload > li {
    box-shadow: inset 0 -1px 0 0 $lightGrey;
}
*/
.file--list {
    position: relative;
    padding: 7px 0;
}

.form--upload-footer {
    background-color: $darkWhite;
    padding: 15px;
}

.form--delete, .form--edit {
    width: 20px;
}

.form--table-placeholder {
    font: 400 14px $font-primary;
    color: $colorBlack;
    background-color: $lightGrey;
}

.form--add-container {
    margin-top: 20px;
    margin-left: -32px;
    margin-bottom: 20px;
    width: calc(100% + 64px);
    background-color: $darkerWhite;
    padding: 8px 32px;
    box-shadow: inset 0 -1px 0 0 $lightGrey;
}

.form--add-desc {
    font: 400 14px $font-primary;
    margin-left: 16px;
    color: $muteGrey;
}

.form--title-sub {
    font: 500 16px $font-primary;
    margin-top: 10px;
}

.form--title-desc {
    display: block;
    font: 400 14px $font-primary;
    margin-top: 5px;
    color: $muteGrey;
}

.form--input-add {
    position: relative;
    margin-bottom: 5px;
}

.form--input-note {
    width: 80%;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0px;
    padding: 8px;
}

.form--input-min {
    width: 10%;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0px;
    padding: 8px;
}

.form--note {
    position: absolute;
    height: 100%;
    padding: 0 16px;
    background-color: $lightGrey;
    color: $white;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font: 400 14px $font-primary;
    line-height: 43px;
}

.form--note-double {
    border-radius: 0px;
}

.form--row-container {
    padding: 8px 0;
}

.crud--sidebar-menu {
    margin-left: -32px;
    margin-bottom: 30px;
    width: calc(100% + 64px);
    background-color: $darkerWhite;
    padding: 16px 32px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.crud--menu {
    font: 400 20px $font-primary;
    color: $darkerGrey;
    padding-left: 16px;
    margin-bottom: 0;
}

.crud--menu > li > a {
    display: block;
    color: $darkerGrey;
    padding: 8px 0;

    &:hover {
        color: $colorBlue;
    }
}


// SIDEBAR HELPER TOGGLE
.sidebar--helper {
    padding: 14px 0;
}

.sidebar--helper > h4 {
    font: 500 20px $font-primary;
    margin: 0;
    margin-bottom: 8px;
}

.sidebar--helper > p {
    font: 400 16px $font-primary;
    line-height: 1.5;
}

.sidebar--helper-toggle {
    color: $white;
    font: 500 16px $font-primary;
    border-bottom: 1px solid $white;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
        color: $white;
    }
}


// SIDEBAR SWITCH
.sidebar--switch-container {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 30px;
    margin-bottom: 0;
}

.sidebar--switch-container > input {
    display: none;
}

.sidebar--switch-container > input:checked + .sidebar--switch {
    background-color: $colorGreen;
}

.sidebar--switch-container > input:focus + .sidebar--switch {
    box-shadow: 0 0 1px $colorGreen;
}

.sidebar--switch-container > input:checked + .sidebar--switch:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    box-shadow: 0 3px 1px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.1), 0 3px 3px 0 rgba(0,0,0,0.05);
}

.sidebar--switch {
    position: absolute;
    width: 48px;
    height: 22px;
    cursor: pointer;
    top: 0;
    left: 2px;
    right: 0;
    bottom: 0;
    background-color: #b8c1cc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 15px;
}

.sidebar--switch:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: #FFF;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 100%;
    top: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}


// SIDEBAR OPTIONS
.sidebar--options-opt {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
}

.sidebar--options-opt > li {
    position: relative;
    color: $white;
    font: 500 20px $font-primary;
}

.sidebar--options-icon {
   // position: absolute;
   // top: 50%;
   // right: 16px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.options--conf {
    position: relative;
    display: block;
    color: $white;
    padding: 12px 0;
    border-bottom: 1px solid $blueDarken;

    &:hover {
        cursor: pointer;
        color: $blueDarken;
    }

    &:visited {
        color: $white;
    }
}
th{
    font-size: 14px;
}
.crud--options {
    display: block;
    color: $white;
   // padding: 12px 0;
   height: 22px;
    //border-bottom: 1px solid $blueDarken;
}

.options--conf-desc {
    display: block;
    font: 400 16px $font-primary;
    font-style: italic;
    margin-top: 5px;
    cursor: pointer;
}

.options--conf-container {
    position: relative;
    display: block;
    border-bottom: 1px solid $blueDarken;
}

.options--conf-label {
    display: inline-block;
    padding: 12px 0;
}

.options--conf-btn {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 100%;
}

.options--conf-btn > .button--delete {
    float: left;
}

.options--conf-btn > .button--cancel {
    float: right;
}

.options--conf-right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: inline-block;
}

.options--conf-link {
    display: block;
    float: left;
    font: 400 16px $font-primary;
    padding: 16px 8px;
    color: $white;
    text-decoration: underline;

    &:hover {
        color: $white;
        text-decoration: underline;
        opacity: 0.9;
    }
}

.options--conf-icon {
    float: right;
    padding: 12px 16px;
}

// CRUD TOP WARNING
.crud--warning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: $colorYellow;
    padding: 7px;
}

.crud--warning-label {
    font: 400 14px $font-primary;
    color: $darkGrey;
    padding: 8px 16px;
}

.crud--warning-label > a {
    color: $darkGrey;
    text-decoration: underline;
    font-weight: 500;
}

.crud--warning-btn {
    cursor: pointer;
    display: block;
    background-color: $darkWhite;
    font: 500 16px $font-primary;
    padding: 8px 13px;
    border-radius: 3px;
    border: 1px solid $lightGrey;
    color: $darkGrey;

    &:hover {
        color: $darkGrey;
        background-color: darken($darkWhite, 5%);
    }
}

// FORM SWITCH
.form--switch-container {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 30px;
    margin-bottom: 0;
    margin-left: 16px;
    vertical-align: middle;
}

.form--switch-container > input {
    display: none;
}

.form--switch-container > input:checked + .form--switch {
    background-color: $colorGreen;
}

.form--switch-container > input:focus + .form--switch {
    box-shadow: 0 0 1px $colorGreen;
}

.form--switch-container > input:checked + .form--switch:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    box-shadow: 0 3px 1px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.1), 0 3px 3px 0 rgba(0,0,0,0.05);
}

.form--switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #CCC;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 15px;
}

.form--switch:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: $white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

// CRUD SUMMARY
.crud--summary-container {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    z-index: 0;
}

.crud--summary-container > ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
}

.crud--summary {
    position: relative;
    background-color: $blueDarken;
    width: calc(100% + 32px);
    margin-left: -32px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &:hover {
        background-color: lighten($blueDarken,5%);
    }
}

.crud--summary-flat {
    position: relative;
    background-color: $blueDarken;
    width: calc(100% + 32px);
    margin-left: -32px;

    &:hover {
        background-color: lighten($blueDarken,5%);
    }

    &:first-child {
        border-top-right-radius: 5px;
    }

    &:nth-child(2) {
        border-bottom-right-radius: 5px;
    }
}

.crud--summary-flat:first-child > a > .crud--summary-inner {
    box-shadow: inset 0 -1px 0 0 #3670B3;
}

.crud--summary-inner {
    position: relative;
    display: block;
    color: $white;
    padding: 16px 0;
    background-color: transparent;

    &:hover {
        background-color: lighten($blueDarken, 5%);
    }
}

.crud--summary > a {
    display: block;
    padding: 14px 32px;
}

.crud--summary-flat > a {
    padding: 0 32px;
    padding-right: 0;
    display: block;
}

.crud--summary-title {
    font: 500 20px $font-primary;
    display: block;
    margin: 0;
    margin-bottom: 4px;
    color: $white;
}

.crud--summary-amount {
    font: 500 20px $font-monospace;
    color: $white;
}

.crud--summary-qty {
    font: 500 32px $font-primary;
    color: $white;
}

.crud--summary-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.crud--summary-left {
    text-align: left;
}

.crud--summary-right {
    text-align: right;
}

.crud--summary-right > .crud--summary-inner {
    padding-right: 48px;
}

//CRUD SIDEBAR BUTTONS
.crud--buttons {
    display: block;
    margin-bottom: 16px;
}

//CRUD SELECT WITH AVATAR
.select--container > .Select > .Select-control 
{
    border: 1px solid $lightGrey;
    border-radius: 3px;
    background-color: $white;
    width: 100%;
    height: 42px;
    font: 400 14px $font-primary;
}

.Select-control
.Select-placeholder, .Select-value > .Select-value-label, .Select-input {
    line-height: 42px;  
}
.back-to-top
{
    position: fixed;
    right: 10%;
    /* width: 200px; */
    /* height: 200px; */
    z-index: 999999999999999;
    bottom:15%;
}
#childbody{
    overflow-y: scroll;
}
.image-profile2{
    padding-top: 130px;
    position: relative;
    text-align: center;
}
.upload--picture
{
    width: 100%;
    font-family: harmony-sans-regular;
    height: 34px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(126, 136, 147, 0.19);
    border: solid 0.5px #b8c1cc;
    font-size: 16px;
    font-weight: bold;
    line-height: 0.75;
    text-align: center;
    padding: 9px 18px 13px 18px;
    color: #242b33;
        &:focus{
            
            background-color: #e7ecf3;
            border: solid 0.5px #b8c1cc;
        }
        &:hover{
        box-shadow: 0 3px 2px 0 rgba(126, 136, 147, 0.2);
        border: solid 0.5px #b8c1cc;
        color: #747b84;
        }
}
.profile-alert-container {
    padding: 8px 27px 8px 38px;
    margin-left: -14px;
}
.image-picture{
    width:104px;
    height:104px;
    top:21%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.icon-image
{
    width: 50px!important;
    height: 50px!important;
}
.tes{
    background-color: red;
    text-align: center;
}
.max-width-45{
    max-width:45%;
}
.font-size-xl
{
    font-size: 100%;
}
.conversion-balance
{
    font-size: 12px;
    padding: 15px;
}
.text-color-red
{
    color:red;
}
/*
.Select-menu-outer
{
    left: 0px;
    //height: 70px;
    background-repeat: no-repeat;
    margin-top: 1px !important;
}*/