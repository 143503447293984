.rptinv-table{
    tr  > th:nth-child(6n){
        width: 16%
    }
    tr  > th:nth-child(6n-1){
        width: 16%
    }
    tr  > th:nth-child(6n-2){
        width: 16%
    }
    tr  > th:nth-child(6n-3){
        width: 16%
    }
    tr  > th:nth-child(6n-4){
        width: 18%
    }
    tr  > th:nth-child(6n-5){
        width: 18%
    }

    tr  > td:nth-child(6n){
        width: 16%
    }
    tr  > td:nth-child(6n-1){
        width: 16%
    }
    tr  > td:nth-child(6n-2){
        width: 16%
    }
    tr  > td:nth-child(6n-3){
        width: 16%
    }
    tr  > td:nth-child(6n-4){
        width: 18%
    }
    tr  > td:nth-child(6n-5){
        width: 18%
    }
}