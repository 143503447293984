.mony-video {
    height: auto;
    width: auto;
    display: table;
    position: relative;
}

.mony-video > .play-button {
    width:50%;
    height:50%;
    position:absolute;
    left:0%;
    right:0%;
    top:0%;
    bottom:0%;
    margin:auto;
    display: inline-table;
    font-size: 50px;
    color: #FFF;
}

.mony-video > .play-button:hover {
    font-size: 55px;
}