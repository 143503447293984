
.popup-setting-date {
    // text-decoration: underline;
    float: left;
    margin: 4px 0px 0px 10px;
}

.modal-date-container {
    padding: 20px 20px 0px 20px;
}

.modal-date-content {
    color: #7e8893;
    font-family: "Harmony-sans-regular";
    font-size: 14px;
    margin-bottom: 25px;
}

.modal-date-label {
    font-size: 14px;
    color: #242b33;
    font-family: "harmony-sans-regular"; 
    margin-bottom: 5px;
}

//sorting
.button-container {
    text-align: left;
}

.run-depre-button {
    float: right;
    width: 210px; 
    margin-right: 25px;
    margin-top: 22px;
}

.font-red-14 {
    font-size: 14px;
    font-family: "harmony-sans-regular";
    color: #ff7a7a;
}