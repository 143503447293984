#pkg-4 {
    background: url("/assets/images/packages/gold-normal.png") no-repeat;
    background-size: cover
}
    
#pkg-4.pkg-current {
    background: url("/assets/images/packages/gold-selected.png") no-repeat;
    background-size: cover
}

#pkg-3 {
    background: url("/assets/images/packages/silver-normal.png") no-repeat;
    background-size: cover
}
    
#pkg-3.pkg-current {
    background: url("/assets/images/packages/silver-selected.png") no-repeat;
    background-size: cover
}

#pkg-2 {
    background: url("/assets/images/packages/bronze-normal.png") no-repeat;
    background-size: cover
}
    
#pkg-2.pkg-current {
    background: url("/assets/images/packages/bronze-selected.png") no-repeat;
    background-size: cover;
}

#pkg-1 {
    background: url("/assets/images/packages/trial-selected.png") no-repeat;
    background-size: cover;
}

.pkg-choice {
    cursor: pointer;
    margin: 20px 17px 10px 17px;
    width: 228px;
    height: 371px;
}

.pkg-choice:hover {
    box-shadow: 0 2px 4px 0 #e7ecf3;
}

.pkg-choice.pkg-current {
    cursor: default;
}

.pkg-choice.pkg-current:hover {
    box-shadow: 0 0 0 0 #fff;
}

.pkg-choice.disabled {
    cursor: not-allowed !important
}