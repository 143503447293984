.inline-block{
  display: inline-block;
  width: 14px;
  height: 14px;
}

.inline-block + span {
  cursor: pointer
}

.inline-block:disabled + span {
  cursor: default
}

.onecheckbox--row{
  position: relative;
}

input[type="checkbox"] {
  display: none
}

input:checked + #buffer {
  &:before {
    background-color: #419aff;
    border: 2px solid #419aff;
  }
}

input:checked:disabled + #buffer {
  cursor: default;
  &:before {
    cursor: default;
    background-color: #E6ECF2;
    border: 2px solid #E6ECF2;
  }
}

input:disabled + #buffer {
  cursor: default;
  &:before {
    cursor: default;
    border: 2px solid #E6ECF2;
  }
}

#buffer {
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top:0;
    left:0;
    border: 2px solid #b8c3cc;
    background-color: white;
    transition: 0.3s ease;
  }
  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 5px;
    width: 4px;
    height: 8px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
  }
  &:hover{
      &:before{
        border: 2px solid #419aff;
      }
    }
}