.icon-success
{
    width: 100;
    height: 100px
}
.lbl-line-success-invite
{
    font-size: 16px;
    color:#242b33;
    padding-top: 15px;
    padding-bottom: 70px;
    
}
.btn-success-email
{
    margin-bottom: 20px;
}
.icon-edit-user
{
    width: 20px;
    height: 20px;
    position: absolute;
    top:5px;
    left: 13px;
}
.btn-edit
{
    width: 47px;
    position: relative;
}
.action-user
{
    max-width: 90px;
}
.role-selected > div{
    min-height: 32px !important;
}